import { Formik, Field } from "formik";
import { validateBr } from "js-brasil";
import React from "react";
import MaskedInput from "react-text-mask";
import * as yup from "yup";
import { getByCep, getAlternativeCep } from "../../services/api-sebrae-address";
import getCnae from "../../services/api-sebrae-cnae";
import { getByCnpj } from "../../services/api-sebrae-pj";
import ModalConfirm from "../Modal/ModalConfirm";

class FromEnterprise extends React.Component {
  state = {
    collapsed: true,
    isOpen: false,
    newEnterprise: false,
    cnae: [],
    formFields: {},
    showModal: false,
    typeCompany: 0,
  };

  // eslint-disable-next-line
  constructor(props) {
    super(props);
  }

  schema = yup.object().shape({
    extra: yup.object().shape({
      empresa: yup.object().shape({
        cnpj: yup
          .string()
          .test("is-valid", `CNPJ inválido!`, (value) => {
            if (value) {
              return validateBr.cnpj(value);
            } else if (
              this.state.typeCompany === "3" ||
              this.state.typeCompany === "2"
            ) {
              return true;
            }
            return false;
          })
          .test("is-valid", "CNPJ é obrigatório", (value) => {
            if (this.state.typeCompany === "1" && !value) {
              return false;
            }
            return true;
          }),
        sicab: yup
          .string()
          .test("is-valid", "SICAB é obrigatório", (value) => {
            if (this.state.typeCompany === "3" && !value) {
              return false;
            }
            return true;
          })
          .test(
            "is-valid",
            "Formato inválido(ex: DF.0123.0004567.01)",
            (value) => {
              if (value) {
                const upper = value.toLocaleUpperCase();
                if (
                  !/s*AC|AL|AP|AM|BA|CE|DF|ES|GO|MA|MT|MS|MG|PA|PB|PR|PE|PI|RJ|RN|RS|RO|RR|SC|SP|SE|TO/.test(
                    upper.slice(0, 2)
                  )
                ) {
                  return false;
                }
                if (upper.length !== 18) {
                  return false;
                }
              }
              return true;
            }
          ),
        rgp: yup
          .string()
          .test("is-valid", "Formato inválido(ex: UF-P1234567-1)", (value) => {
            if (value) {
              const upper = value.toLocaleUpperCase();
              if (
                !/s*AC|AL|AP|AM|BA|CE|DF|ES|GO|MA|MT|MS|MG|PA|PB|PR|PE|PI|RJ|RN|RS|RO|RR|SC|SP|SE|TO/.test(
                  upper.slice(0, 2)
                )
              ) {
                return false;
              }
              if (upper.length !== 13) {
                return false;
              }
            }
            return true;
          }),
        dap: yup.string(),
        nirf: yup.string(),
        inscricaoEstadual: yup.string(),
        dataAbertura: yup
          .string()
          .required(`Data de abertura é obrigatório`)
          .test("is-valid", `Data de abertura inválida!`, (value) => {
            if (value) {
              return (
                new Date(value) >= new Date("1900-01-01") &&
                new Date(value) < new Date()
              );
            }
            return false;
          }),
        mobile: yup
          .string()
          .required(`Celular é obrigatório`)
          .test("is-valid", `Número inválido.`, (value) => {
            if (value && value[4] && value[4] === "9" && value.length === 14) {
              return true;
            }

            return false;
          }),
        tipoLogradouro: yup
          .string()
          .required(`Tipo de logradouro é obrigatório`),
        nomeFantasia: yup.string().required(`Nome fantasia é obrigatório`),
        cnae: yup.string().required(`CNAE é obrigatório`),
        razaoSocial: yup.string().required(`Razão social é obrigatório`),
        cep: yup.string().required(`Cep é obrigatório`),
        numero: yup.string().required(`Número é obrigatório`),
        cargo: yup.string().required(`Cargo é obrigatório`),
        email: yup
          .string()
          .email(`E-mail inválido!`)
          .required(`E-mail é obrigatório!`),
      }),
    }),
  });

  schema2 = yup.object().shape({
    extra: yup.object().shape({
      empresa: yup.object().shape({
        cnpj: yup
          .string()
          .test("is-valid", `CNPJ inválido!`, (value) => {
            if (value) {
              return validateBr.cnpj(value);
            } else if (
              this.state.typeCompany === "3" ||
              this.state.typeCompany === "2"
            ) {
              return true;
            }
            return false;
          })
          .test("is-valid", "CNPJ é obrigatório", (value) => {
            if (this.state.typeCompany === "1" && !value) {
              return false;
            }
            return true;
          }),
        sicab: yup.string().test("is-valid", "SICAB é obrigatório", (value) => {
          if (this.state.typeCompany === "3" && !value) {
            return false;
          }
          return true;
        }),
        rgp: yup
          .string()
          .test("is-valid", "Formato inválido(ex: UF-P1234567-1)", (value) => {
            if (value) {
              const upper = value.toLocaleUpperCase();
              if (
                !/s*AC|AL|AP|AM|BA|CE|DF|ES|GO|MA|MT|MS|MG|PA|PB|PR|PE|PI|RJ|RN|RS|RO|RR|SC|SP|SE|TO/.test(
                  upper.slice(0, 2)
                )
              ) {
                return false;
              }
              if (upper.length !== 13) {
                return false;
              }
            }
            return true;
          }),
        dap: yup.string(),
        nirf: yup.string(),
        inscricaoEstadual: yup.string(),
        dataAbertura: yup
          .string()
          .required(`Data de abertura é obrigatório`)
          .test("is-valid", `Data de abertura inválida!`, (value) => {
            if (value) {
              return (
                new Date(value) >= new Date("1900-01-01") &&
                new Date(value) < new Date()
              );
            }
            return false;
          }),
        mobile: yup
          .string()
          .required(`Celular é obrigatório`)
          .test("is-valid", `Número inválido.`, (value) => {
            if (value && value[4] && value[4] === "9" && value.length === 14) {
              return true;
            }

            return false;
          }),
        nomeFantasia: yup.string().required(`Nome fantasia é obrigatório`),
        razaoSocial: yup.string().required(`Razão social é obrigatório`),
        cep: yup.string().required(`Cep é obrigatório`),
        numero: yup.string().required(`Número é obrigatório`),
        cargo: yup.string().required(`Cargo é obrigatório`),
        email: yup
          .string()
          .email(`E-mail inválido!`)
          .required(`E-mail é obrigatório!`),
      }),
    }),
  });

  handleData = (data) => {
    this.setState({
      formFields: {
        ...this.state.formFields,
        extra: {
          ...this.state.formFields.extra,
          empresa: {
            tipoEmpreendimento: 1,
            ...this.state.formFields.extra.empresa,
            ...data?.extra?.empresa,
          },
        },
      },
    });

    setTimeout(() => {
      this.props.sendForm(this.state.formFields);
    }, 100);
  };

  selectedTypeCompany = async (type) => {
    this.setState({
      typeCompany: type,
    });
  };

  searchCNPJ = async (cnpj, setFieldValue) => {
    if (cnpj) {
      try {
        const res = await getByCnpj(cnpj.replace(/[^\d]+/g, ""));

        if (res) {
          this.setState({
            formFields: {
              ...this.state.formFields,
              extra: {
                ...this.state.formFields.extra,
                empresa: {
                  ...this.state.formFields.empresa,
                  dataAbertura: res.DataAbertura,
                  razaoSocial: res.RazaoSocial,
                  nomeFantasia: res.NomeFantasia,
                  porte: res.IdPorte,
                  cnae: res.Cnaes[0],
                  cep: res.Endereco.CEP,
                  idCidade: res.Endereco.IdCidade,
                  idTipoLogradouro: res.Endereco.IdTipoLogradouro,
                  IdPais: res.Endereco.IdCidade,
                  complement: res.Endereco.Complemento,
                  logradouro: res.Endereco.Logradouro,
                  uf: res.Endereco.UF,
                  email: res.Email,
                  mobile: res.Celular,
                  phone: res.Telefone,
                  cnpj: cnpj,
                  idPessoaJuridica: res.IdPessoa,
                  cargo: res.IdPessoaJuridicaTipo,
                },
              },
            },
          });

          setFieldValue("extra.empresa.dataAbertura", res.DataAbertura);
          setFieldValue("extra.empresa.razaoSocial", res.RazaoSocial);
          setFieldValue("extra.empresa.nomeFantasia", res.NomeFantasia);
          setFieldValue("extra.empresa.porte", res.IdPorte);
          setFieldValue("extra.empresa.cnae", res.Cnaes[0]);
          setFieldValue("extra.empresa.email", res.Email);
          setFieldValue("extra.empresa.mobile", res.Celular);
          setFieldValue("extra.empresa.phone", res.Telefone);

          this.setState({ showModal: true });

          this.searchAddress(res.Endereco.CEP, setFieldValue);
        } else if (this.state.formFields && this.state.formFields.extra) {
          this.setState({
            formFields: {},
          });

          setFieldValue("extra.empresa.dataAbertura", "");
          setFieldValue("extra.empresa.razaoSocial", "");
          setFieldValue("extra.empresa.nomeFantasia", "");
          setFieldValue("extra.empresa.porte", "");
          setFieldValue("extra.empresa.cnae", "");
          setFieldValue("extra.empresa.email", "");
          setFieldValue("extra.empresa.mobile", "");
          setFieldValue("extra.empresa.phone", "");
          setFieldValue("extra.empresa.bairro", "");
          setFieldValue("extra.empresa.cidade", "");

          this.setState({
            formFields: {},
          });
        }
      } catch (error) {
        console.log("erro ao buscar cnpj", error);
      }
    }
  };

  searchAddress = async (cep, setFieldValue) => {
    if (cep) {
      try {
        let hasCep;
        if (this.props.isApiOn) hasCep = await getByCep(cep);
        else hasCep = await getAlternativeCep(cep);

        console.log(hasCep);

        if (hasCep) {
          this.setState({
            formFields: {
              ...this.state.formFields,
              extra: {
                ...this.state.formFields.extra,
                empresa: {
                  ...this.state.formFields?.extra?.empresa,
                  bairro: hasCep.Bairro,
                  cep: hasCep.CEP,
                  cidade: hasCep.Cidade,
                  idCidade: hasCep.IdCidade,
                  idTipoLogradouro: hasCep.IdTipoLogradouro,
                  logradouro: hasCep.Logradouro,
                  tipoLogradouro: hasCep.TipoLogradouro,
                  uf: hasCep.UF,
                },
              },
            },
          });

          console.log(setFieldValue);

          setFieldValue("extra.empresa.bairro", hasCep.Bairro);
          setFieldValue("extra.empresa.cep", hasCep.CEP);
          setFieldValue("extra.empresa.cidade", hasCep.Cidade);
          setFieldValue("extra.empresa.logradouro", hasCep.Logradouro);
          setFieldValue("extra.empresa.tipoLogradouro", hasCep.TipoLogradouro);
          setFieldValue("extra.empresa.uf", hasCep.UF);
        }
      } catch (error) {
        console.log("erro ao buscar cep", error);
      }
    }
  };

  getCnae = async () => {
    try {
      const res = await getCnae();

      this.setState({ cnae: res });
    } catch (error) {
      console.log("erro ao buscar cnae", error);
    }
  };

  handleConfirm() {
    this.props.onConfirm({
      pessoaJuridica: {
        Departamento: "",
        IdCargo: this.state.formFields.extra.empresa.cargo,
        IdPessoaJuridica: this.state.formFields.extra.empresa.idPessoaJuridica,
        InconsistenciaDepartamento: false,
        Rastreabilidade: null,
      },
      cnpj: this.state.formFields.extra.empresa.cnpj,
    });
  }

  render() {
    return (
      <>
        {this.state.showModal && (
          <ModalConfirm
            title={`Encontramos a empresa <b>${this.state?.formFields?.extra?.empresa?.nomeFantasia}</b> cadastrada no Sebrae!`}
            body={`Deseja realizar a inscrição usando a empresa <b>${this.state?.formFields?.extra?.empresa?.nomeFantasia}</b> de cnpj <b>${this.state?.formFields?.extra?.empresa?.cnpj}</b>?`}
            onConfirm={() => {
              this.handleConfirm();
              this.setState({ showModal: false });
            }}
            onClose={() => {
              this.setState({
                formFields: {},
                showModal: false,
              });
              document
                .getElementsByClassName("contactFormEnterprise")[0]
                .reset();
            }}
          />
        )}
        <div className="row" id="contactForm">
          <div className="col-lg-12 col-md-12">
            <h2>Como prefere se inscrever?</h2>
            <b>{this.state?.formFields?.extra?.empresa?.nomeFantasia}</b>
            {(!this.props.enterprises || !this.props.enterprises[0]) && (
              <label className="mb-2">
                Identificamos que você não possui uma empresa relacionada em
                nosso cadastro. Selecione abaixo como deseja realizar a sua
                inscrição:
              </label>
            )}

            {this.props.enterprises && this.props.enterprises[0] && (
              <div className="row">
                <div className="col-lg-6 col-md-6">
                  <label htmlFor="">Selecione sua empresa</label>
                  <select
                    name="extra.sexo"
                    className={`form-control`}
                    onChange={(e) => {
                      this.setState({
                        empresa: e.target.value,
                      });
                    }}
                  >
                    <option value="">Selecione</option>
                    {this.props.enterprises.map((e, index) => (
                      <option key={index} value={index}>
                        {e.RazaoSocial}
                      </option>
                    ))}
                  </select>
                </div>
                {this.state.empresa && (
                  <div className="col-lg-6 col-md-6 d-flex align-items-end">
                    <button
                      className="btn btn-primary custom-button"
                      onClick={() =>
                        this.props.changeStep(
                          this.props.enterprises[this.state.empresa]
                        )
                      }
                    >
                      Cadastrar com essa empresa
                    </button>
                  </div>
                )}
                <div className="col-lg-12 col-md-12 text-center">
                  <p className="mt-3 mb-3">Ou</p>
                </div>
              </div>
            )}
          </div>

          <div className="col-lg-12 col-md-12 text-center mb-3">
            <button
              className="btn btn-primary"
              onClick={() => {
                this.setState({ newEnterprise: true });
                if (this.props.isApiOn) this.getCnae();
              }}
            >
              Cadastrar nova empresa
            </button>
          </div>
          <div className="col-lg-12 col-md-12 text-center">
            <button
              onClick={() => this.props.changeStep()}
              className="btn btn-primary"
            >
              Inscreva-se como Pessoa Física
            </button>
          </div>
        </div>

        <div className="row">
          {this.state.newEnterprise && !this.state.empresa && (
            <Formik
              validationSchema={this.props.isApiOn ? this.schema : this.schema2}
              onSubmit={(values) => {
                console.log(values);
                this.handleData(values);
              }}
              initialValues={{
                extra: {
                  empresa: {
                    nomeSetores: [],
                    cnpj: "",
                    dataAbertura: "",
                    nomeFantasia: "",
                    razaoSocial: "",
                    cidade: "Vai tomar no olho do cul",
                    uf: "DF",
                  },
                },
              }}
            >
              {({
                handleSubmit,
                handleChange,
                handleBlur,
                setFieldTouched,
                setFieldValue,
                values,
                touched,
                isValid,
                isValidating,
                errors,
              }) => (
                <form
                  id="contactForm"
                  className="contactFormEnterprise"
                  onSubmit={handleSubmit}
                >
                  <div className="row">
                    <>
                      <div className="col-lg-12 col-md-12">
                        <label htmlFor="">Tipo de empreendimento*</label>
                        <select
                          name="extra.empresa.typeCompany"
                          id=""
                          className={`form-control ${
                            errors.extra && errors.extra?.empresa?.porte
                              ? "invalid-form"
                              : ""
                          }`}
                          // onChange={handleChange}
                          onChange={(e) =>
                            this.selectedTypeCompany(e.target.value)
                          }
                        >
                          <option value="">Selecione</option>
                          <option value="1">Empresa</option>
                          <option value="2">Produtor Rural</option>
                          <option value="3">Artesão</option>
                        </select>
                        <span className="invalid-form">
                          {errors.extra && errors.extra?.empresa?.porte}
                        </span>
                      </div>
                      {this.state.typeCompany !== "3" && (
                        <div className="col-lg-12 col-md-12">
                          <label htmlFor="">CNPJ</label>
                          <MaskedInput
                            guide={false}
                            name="extra.empresa.cnpj"
                            keepCharPositions={false}
                            showMask={false}
                            mask={[
                              /\d/,
                              /\d/,
                              ".",
                              /\d/,
                              /\d/,
                              /\d/,
                              ".",
                              /\d/,
                              /\d/,
                              /\d/,
                              "/",
                              /\d/,
                              /\d/,
                              /\d/,
                              /\d/,
                              "-",
                              /\d/,
                              /\d/,
                            ]}
                            disabled={
                              this.state.typeCompany === "2" &&
                              (values?.extra?.empresa?.dap ||
                                values?.extra?.empresa?.nirf ||
                                values?.extra?.empresa?.inscricaoEstadual)
                            }
                            type="text"
                            className={`form-control ${
                              errors?.extra?.empresa?.cnpj ? "invalid-form" : ""
                            }`}
                            onChange={handleChange}
                            onBlur={(e) => {
                              if (this.props.isApiOn)
                                this.searchCNPJ(e.target.value, setFieldValue);
                            }}
                          />
                          <span className="invalid-form">
                            {errors?.extra?.empresa?.cnpj}
                          </span>
                        </div>
                      )}
                      {this.state.typeCompany === "3" && (
                        <div className="col-lg-12 col-md-12">
                          <label htmlFor="">SICAB*</label>
                          <MaskedInput
                            guide={false}
                            name="extra.empresa.sicab"
                            keepCharPositions={false}
                            showMask={false}
                            mask={[
                              /[a-zA-Z]/,
                              /[a-zA-Z]/,
                              ".",
                              /\d/,
                              /\d/,
                              /\d/,
                              /\d/,
                              ".",
                              /\d/,
                              /\d/,
                              /\d/,
                              /\d/,
                              /\d/,
                              /\d/,
                              /\d/,
                              ".",
                              /\d/,
                              /\d/,
                            ]}
                            type="text"
                            className={`form-control ${
                              errors?.extra?.empresa?.sicab
                                ? "invalid-form"
                                : ""
                            }`}
                            onChange={handleChange}
                          />
                          <span className="invalid-form">
                            {errors?.extra?.empresa?.sicab}
                          </span>
                        </div>
                      )}

                      {this.state.typeCompany === "2" && (
                        <>
                          <div className="border col-lg-11 col-md-11 mt-2 pb-2 mx-auto">
                            <label htmlFor="">
                              Preencha <b>somente um</b> dos campos abaixo*
                            </label>
                            <div className="col-lg-12 col-md-12 ">
                              <label htmlFor="">RGP</label>
                              <MaskedInput
                                guide={false}
                                name="extra.empresa.rgp"
                                keepCharPositions={false}
                                showMask={false}
                                mask={[
                                  /[a-zA-Z]/,
                                  /[a-zA-Z]/,
                                  "-",
                                  /[PRpr]/,
                                  /\d/,
                                  /\d/,
                                  /\d/,
                                  /\d/,
                                  /\d/,
                                  /\d/,
                                  /\d/,
                                  "-",
                                  /\d/,
                                ]}
                                disabled={
                                  values?.extra?.empresa?.cnpj ||
                                  values?.extra?.empresa?.dap ||
                                  values?.extra?.empresa?.nirf ||
                                  values?.extra?.empresa?.inscricaoEstadual
                                }
                                type="text"
                                className={`form-control ${
                                  errors?.extra?.empresa?.rgp
                                    ? "invalid-form"
                                    : ""
                                } ${
                                  values?.extra?.empresa?.cnpj ||
                                  values?.extra?.empresa?.dap ||
                                  values?.extra?.empresa?.nirf ||
                                  values?.extra?.empresa?.inscricaoEstadual
                                    ? "blocked"
                                    : ""
                                }`}
                                onChange={handleChange}
                              />
                              <span className="invalid-form">
                                {errors?.extra?.empresa?.rgp}
                              </span>
                            </div>
                            <div className="col-lg-12 col-md-12">
                              <label htmlFor="">DAP</label>
                              <MaskedInput
                                guide={false}
                                name="extra.empresa.dap"
                                keepCharPositions={false}
                                showMask={false}
                                mask={[
                                  /[a-zA-Z]/,
                                  /[a-zA-Z]/,
                                  /[a-zA-Z]/,
                                  /\d/,
                                  /\d/,
                                  /\d/,
                                  /\d/,
                                  /\d/,
                                  /\d/,
                                  /\d/,
                                  /\d/,
                                  /\d/,
                                  /\d/,
                                  /\d/,
                                  /\d/,
                                  /\d/,
                                  /\d/,
                                  /\d/,
                                  /\d/,
                                  /\d/,
                                  /\d/,
                                  /\d/,
                                  /\d/,
                                  /\d/,
                                  /\d/,
                                ]}
                                disabled={
                                  values?.extra?.empresa?.cnpj ||
                                  values?.extra?.empresa?.rgp ||
                                  values?.extra?.empresa?.nirf ||
                                  values?.extra?.empresa?.inscricaoEstadual
                                }
                                type="text"
                                className={`form-control ${
                                  errors?.extra?.empresa?.dap
                                    ? "invalid-form"
                                    : ""
                                }${
                                  values?.extra?.empresa?.cnpj ||
                                  values?.extra?.empresa?.rgp ||
                                  values?.extra?.empresa?.nirf ||
                                  values?.extra?.empresa?.inscricaoEstadual
                                    ? "blocked"
                                    : ""
                                }`}
                                onChange={handleChange}
                              />
                              <span className="invalid-form">
                                {errors?.extra?.empresa?.dap}
                              </span>
                            </div>
                            <div className="col-lg-12 col-md-12 ">
                              <label htmlFor="">NIRF</label>
                              <input
                                onChange={handleChange}
                                name="extra.empresa.nirf"
                                disabled={
                                  values?.extra?.empresa?.cnpj ||
                                  values?.extra?.empresa?.rgp ||
                                  values?.extra?.empresa?.dap ||
                                  values?.extra?.empresa?.inscricaoEstadual
                                }
                                type="text"
                                className={`form-control ${
                                  errors.extra && errors.extra?.empresa?.nirf
                                    ? "invalid-form"
                                    : ""
                                }${
                                  values?.extra?.empresa?.cnpj ||
                                  values?.extra?.empresa?.rgp ||
                                  values?.extra?.empresa?.dap ||
                                  values?.extra?.empresa?.inscricaoEstadual
                                    ? "blocked"
                                    : ""
                                }`}
                              />
                            </div>
                            <div className="col-lg-12 col-md-12 ">
                              <label htmlFor="">Inscrição Estadual</label>
                              <input
                                onChange={handleChange}
                                name="extra.empresa.inscricaoEstadual"
                                type="text"
                                disabled={
                                  values?.extra?.empresa?.cnpj ||
                                  values?.extra?.empresa?.rgp ||
                                  values?.extra?.empresa?.dap ||
                                  values?.extra?.empresa?.nirf
                                }
                                className={`form-control ${
                                  errors.extra &&
                                  errors.extra?.empresa?.inscricaoEstadual
                                    ? "invalid-form"
                                    : ""
                                }${
                                  values?.extra?.empresa?.cnpj ||
                                  values?.extra?.empresa?.rgp ||
                                  values?.extra?.empresa?.nirf ||
                                  values?.extra?.empresa?.dap
                                    ? "blocked"
                                    : ""
                                }`}
                              />
                              <span className="invalid-form">
                                {errors?.extra?.empresa?.inscricaoEstadual}
                              </span>
                            </div>
                          </div>
                        </>
                      )}

                      {/* <div className="col-lg-12 col-md-12">
                      <label htmlFor="">Tipo Empreendimento</label>
                      <select
                        name="extra.empresa.sexo"
                        id=""
                        className={`form-control ${
                          errors.extra && errors.extra.sexo
                            ? "invalid-form"
                            : ""
                        }`}
                        onChange={handleChange}
                      >
                        <option value="">Selecione</option>
                        <option value="1">Empreendimento XPTO</option>
                        <option value="2">Empreendimento XPTU</option>
                      </select>
                      <span className="invalid-form">
                        {errors.extra && errors.extra.sexo}
                      </span>
                    </div> */}

                      <div className="col-lg-12 col-md-12">
                        <label htmlFor="">Data de abertura*</label>
                        <input
                          name="extra.empresa.dataAbertura"
                          type="date"
                          className={`form-control ${
                            errors.extra && errors?.extra?.empresa?.dataAbertura
                              ? "invalid-form"
                              : ""
                          }`}
                          onChange={handleChange}
                        />
                        <span className="invalid-form">
                          {errors.extra && errors?.extra?.empresa?.dataAbertura}
                        </span>
                      </div>

                      <div className="col-lg-12 col-md-12">
                        <label htmlFor="">Razão Social*</label>
                        <input
                          name="extra.empresa.razaoSocial"
                          type="text"
                          className={`form-control ${
                            errors.extra && errors.extra.empresa?.razaoSocial
                              ? "invalid-form"
                              : ""
                          }`}
                          onChange={handleChange}
                        />
                        <span className="invalid-form">
                          {errors?.extra?.empresa?.razaoSocial}
                        </span>
                      </div>

                      <div className="col-lg-12 col-md-12">
                        <label htmlFor="">Nome Fantasia*</label>
                        <input
                          name="extra.empresa.nomeFantasia"
                          type="text"
                          className={`form-control ${
                            errors.extra && errors.extra?.empresa?.nomeFantasia
                              ? "invalid-form"
                              : ""
                          }`}
                          onChange={handleChange}
                        />
                        <span className="invalid-form">
                          {errors?.extra?.nomeFantasia}
                        </span>
                      </div>

                      <div className="col-lg-12 col-md-12">
                        <label htmlFor="">Porte</label>
                        <select
                          name="extra.empresa.porte"
                          id=""
                          className={`form-control ${
                            errors.extra && errors.extra?.empresa?.porte
                              ? "invalid-form"
                              : ""
                          }`}
                          onChange={handleChange}
                        >
                          <option value="">Selecione</option>
                          <option value="1">
                            MEI - Microempreendedor Individual
                          </option>
                          <option value="2">Microempresa</option>
                          <option value="3">Empresa de Pequeno Porte</option>
                          <option value="4">Empresa de Médio Porte</option>
                          <option value="5">Grande Empresa</option>
                        </select>
                        <span className="invalid-form">
                          {errors.extra && errors.extra?.empresa?.porte}
                        </span>
                      </div>

                      <div className="col-lg-12 col-md-12">
                        <div className="well">
                          <label htmlFor="extra.empresa.nomeSetores">
                            Setor
                          </label>
                          <div>
                            <label>
                              <Field
                                type="checkbox"
                                name="extra.empresa.nomeSetores"
                                value="1"
                              />
                              &#160; INDUSTRIA
                            </label>
                            <label>
                              <Field
                                type="checkbox"
                                name="extra.empresa.nomeSetores"
                                value="2"
                              />
                              &#160; COMERCIO
                            </label>
                            <label>
                              <Field
                                type="checkbox"
                                name="extra.empresa.nomeSetores"
                                value="3"
                              />
                              &#160; SERVIÇOS
                            </label>
                            <label>
                              <Field
                                type="checkbox"
                                name="extra.empresa.nomeSetores"
                                value="4"
                              />
                              &#160; AGROPECUÁRIA
                            </label>
                          </div>
                        </div>
                      </div>

                      {this.state.cnae && this.state.cnae[0] && (
                        <div className="col-lg-12 col-md-12">
                          <label htmlFor="">Produtos/serviço(CNAE)*</label>
                          <select
                            name="extra.empresa.cnae"
                            id=""
                            className={`form-control ${
                              errors.extra && errors.extra?.empresa?.cnae
                                ? "invalid-form"
                                : ""
                            }`}
                            onChange={handleChange}
                          >
                            <option value="">Selecione</option>
                            {this.state.cnae.map((e) => (
                              <option key={e.IdCNAE} value={e.IdCNAE}>
                                {e.CodigoCNAE + " | " + e.Descricao}
                              </option>
                            ))}
                          </select>
                          <span className="invalid-form">
                            {errors.extra && errors.extra?.empresa?.cnae}
                          </span>
                        </div>
                      )}

                      <div className="col-lg-12 col-md-12">
                        <label htmlFor="">CEP*</label>
                        <MaskedInput
                          name="extra.empresa.cep"
                          onChange={(event) => {
                            handleChange(event);
                          }}
                          onBlur={(event) =>
                            this.searchAddress(
                              event.target.value,
                              setFieldValue
                            )
                          }
                          guide={false}
                          keepCharPositions={false}
                          showMask={false}
                          mask={[
                            /\d/,
                            /\d/,
                            /\d/,
                            /\d/,
                            /\d/,
                            "-",
                            /\d/,
                            /\d/,
                            /\d/,
                          ]}
                          type="text"
                          className={`form-control ${
                            errors.extra && errors.extra?.empresa?.cep
                              ? "invalid-form"
                              : ""
                          }`}
                        />
                        <span className="invalid-form">
                          {errors?.extra?.empresa?.cep}
                        </span>
                      </div>
                      <div className="col-lg-12 col-md-12">
                        <label htmlFor="">Tipo de logradouro</label>
                        <select
                          name="extra.empresa.tipoLogradouro"
                          id="addressType"
                          className={`form-control ${
                            errors?.extra?.empresa?.tipoLogradouro
                              ? "invalid-form"
                              : ""
                          }`}
                          onChange={handleChange}
                          value={
                            this.state?.formFields?.extra?.empresa
                              ?.tipoLogradouro
                          }
                        >
                          <option value="">Selecione</option>
                          <option value="AEROPORTO">AEROPORTO</option>
                          <option value="ALAMEDA">ALAMEDA</option>
                          <option value="AREA">ÁREA</option>
                          <option value="AVENIDA">AVENIDA</option>
                          <option value="CAMPO">CAMPO</option>
                          <option value="CHACARA">CHÁCARA</option>
                          <option value="COLONIA">COLÔNIA</option>
                          <option value="CONDOMINIO">CONDOMÍNIO</option>
                          <option value="CONJUNTO">CONJUNTO</option>
                          <option value="DISTRITO">DISTRITO</option>
                          <option value="ESPLANADA">ESPLANADA</option>
                          <option value="ESTACAO">ESTAÇÃO</option>
                          <option value="ESTRADA">ESTRADA</option>
                          <option value="FAVELA">FAVELA</option>
                          <option value="FAZENDA">FAZENDA</option>
                          <option value="FEIRA">FEIRA</option>
                          <option value="JARDIM">JARDIM</option>
                          <option value="LADEIRA">LADEIRA</option>
                          <option value="LAGO">LAGO</option>
                          <option value="LAGOA">LAGOA</option>
                          <option value="LARGO">LARGO</option>
                          <option value="LOTEAMENTO">LOTEAMENTO</option>
                          <option value="MORRO">MORRO</option>
                          <option value="NÚCLEO">NÚCLEO</option>
                          <option value="PARQUE">PARQUE</option>
                          <option value="PASSARELA">PASSARELA</option>
                          <option value="PATIO">PÁTIO</option>
                          <option value="PRACA">PRAÇA</option>
                          <option value="QUADRA">QUADRA</option>
                          <option value="RECANTO">RECANTO</option>
                          <option value="RESIDENCIAL">RESIDENCIAL</option>
                          <option value="RODOVIA">RODOVIA</option>
                          <option value="RUA">RUA</option>
                          <option value="SETOR">SETOR</option>
                          <option value="SITIO">SÍTIO</option>
                          <option value="TRAVESSA">TRAVESSA</option>
                          <option value="TRECHO">TRECHO</option>
                          <option value="TREVO">TREVO</option>
                          <option value="VALE">VALE</option>
                          <option value="VEREDA">VEREDA</option>
                          <option value="VIA">VIA</option>
                          <option value="VIADUTO">VIADUTO</option>
                          <option value="VIELA">VIELA</option>
                          <option value="VILA">VILA</option>
                        </select>

                        <span className="invalid-form">
                          {errors?.extra?.empresa?.tipoLogradouro}
                        </span>
                      </div>

                      <div className="col-lg-12 col-md-12">
                        <label name="address" htmlFor="">
                          Endereço
                        </label>
                        <input
                          type="text"
                          name="extra.empresa.logradouro"
                          className={`form-control ${
                            errors?.extra?.logradouro ? "invalid-form" : ""
                          }`}
                          onChange={handleChange}
                          value={
                            this.state?.formFields?.extra?.empresa?.logradouro
                          }
                        />
                        <span className="invalid-form">
                          {errors?.extra?.logradouro}
                        </span>
                      </div>

                      <div className="col-lg-12 col-md-12">
                        <label htmlFor="">Número*</label>
                        <input
                          onChange={handleChange}
                          name="extra.empresa.numero"
                          type="number"
                          className={`form-control ${
                            errors.extra && errors.extra?.empresa?.numero
                              ? "invalid-form"
                              : ""
                          }`}
                        />
                        <span className="invalid-form">
                          {errors.extra && errors.extra?.empresa?.numero}
                        </span>
                      </div>

                      <div className="col-lg-12 col-md-12">
                        <label htmlFor="">Complemento</label>
                        <input
                          name="extra.empresa.complement"
                          type="text"
                          onChange={handleChange}
                          className={`form-control ${
                            errors.extra && errors.extra?.empresa?.complement
                              ? "invalid-form"
                              : ""
                          }`}
                          value={
                            this.state?.formFields?.extra?.empresa?.complement
                          }
                        />
                        <span className="invalid-form">
                          {errors.extra && errors.extra?.empresa?.complement}
                        </span>
                      </div>

                      <div className="col-lg-12 col-md-12">
                        <label htmlFor="">Bairro</label>
                        <input
                          type="text"
                          className={`form-control ${
                            errors.extra && errors.extra.bairro
                              ? "invalid-form"
                              : ""
                          }`}
                          name="extra.empresa.bairro"
                          onChange={handleChange}
                          value={this.state?.formFields?.extra?.empresa?.bairro}
                        />
                        <span className="invalid-form">
                          {errors.extra && errors.extra.bairro}
                        </span>
                      </div>

                      <div className="col-lg-12 col-md-12">
                        <label htmlFor="">UF</label>
                        <select
                          id="state"
                          name="extra.empresa.uf"
                          className={`form-control ${
                            errors.extra && errors.extra?.empresa?.uf
                              ? "invalid-form"
                              : ""
                          }`}
                          onChange={handleChange}
                          value={this.state?.formFields?.extra?.empresa?.uf}
                        >
                          <option value="">Selecione</option>
                          <option value="AC">Acre</option>
                          <option value="AL">Alagoas</option>
                          <option value="AP">Amapá</option>
                          <option value="AM">Amazonas</option>
                          <option value="BA">Bahia</option>
                          <option value="CE">Ceará</option>
                          <option value="DF">Distrito Federal</option>
                          <option value="ES">Espírito Santo</option>
                          <option value="GO">Goiás</option>
                          <option value="MA">Maranhão</option>
                          <option value="MT">Mato Grosso</option>
                          <option value="MS">Mato Grosso do Sul</option>
                          <option value="MG">Minas Gerais</option>
                          <option value="PA">Pará</option>
                          <option value="PB">Paraíba</option>
                          <option value="PR">Paraná</option>
                          <option value="PE">Pernambuco</option>
                          <option value="PI">Piauí</option>
                          <option value="RJ">Rio de Janeiro</option>
                          <option value="RN">Rio Grande do Norte</option>
                          <option value="RS">Rio Grande do Sul</option>
                          <option value="RO">Rondônia</option>
                          <option value="RR">Roraima</option>
                          <option value="SC">Santa Catarina</option>
                          <option value="SP">São Paulo</option>
                          <option value="SE">Sergipe</option>
                          <option value="TO">Tocantins</option>
                          <option value="EX">Estrangeiro</option>
                        </select>
                        <span className="invalid-form">
                          {errors.extra && errors.extra?.empresa?.uf}
                        </span>
                      </div>

                      <div className="col-lg-12 col-md-12">
                        <label htmlFor="cidade">Cidade</label>
                        <input
                          type="text"
                          className={`form-control ${
                            errors.extra && errors.empresa?.cidade
                              ? "invalid-form"
                              : ""
                          }`}
                          name="extra.empresa.cidade"
                          id="cidade"
                          onChange={handleChange}
                          value={this.state?.formFields?.extra?.empresa?.cidade}
                        />
                        <span className="invalid-form">
                          {errors.extra && errors.empresa?.cidade}
                        </span>
                      </div>

                      <div className="col-lg-12 col-md-12">
                        <label htmlFor="">Telefone</label>
                        <MaskedInput
                          guide={false}
                          keepCharPositions={false}
                          showMask={false}
                          mask={[
                            "(",
                            /\d/,
                            /\d/,
                            ")",
                            /\d/,
                            /\d/,
                            /\d/,
                            /\d/,
                            "-",
                            /\d/,
                            /\d/,
                            /\d/,
                            /\d/,
                          ]}
                          type="text"
                          className={`form-control ${
                            errors.extra && errors.extra?.empresa?.phone
                              ? "invalid-form"
                              : ""
                          }`}
                          onChange={handleChange}
                          name="extra.empresa.phone"
                        />

                        <span className="invalid-form">
                          {errors.extra && errors.extra?.empresa?.phone}
                        </span>
                      </div>
                      <div className="col-lg-12 col-md-12">
                        <label htmlFor="">Celular*</label>
                        <MaskedInput
                          guide={false}
                          keepCharPositions={false}
                          showMask={false}
                          mask={[
                            "(",
                            /\d/,
                            /\d/,
                            ")",
                            /\d/,
                            /\d/,
                            /\d/,
                            /\d/,
                            /\d/,
                            "-",
                            /\d/,
                            /\d/,
                            /\d/,
                            /\d/,
                          ]}
                          type="text"
                          onChange={handleChange}
                          name="extra.empresa.mobile"
                          className={`form-control ${
                            errors.extra && errors.extra?.empresa?.mobile
                              ? "invalid-form"
                              : ""
                          }`}
                        />
                        <span className="invalid-form">
                          {errors.extra && errors.extra?.empresa?.mobile}
                        </span>
                      </div>

                      <div className="col-lg-12 col-md-12">
                        <label htmlFor="cidade">E-mail*</label>
                        <input
                          type="email"
                          className={`form-control ${
                            errors.extra && errors.extra?.empresa?.email
                              ? "invalid-form"
                              : ""
                          }`}
                          name="extra.empresa.email"
                          id="cidade"
                          onChange={handleChange}
                        />
                        <span className="invalid-form">
                          {errors.extra && errors.extra?.empresa?.email}
                        </span>
                      </div>

                      <div className="col-lg-12 col-md-12">
                        <label htmlFor="cidade">
                          Qual seu cargo na empresa?*
                        </label>

                        <select
                          id="state"
                          name="extra.empresa.cargo"
                          className={`form-control ${
                            errors.extra && errors.extra?.empresa?.cargo
                              ? "invalid-form"
                              : ""
                          }`}
                          onChange={handleChange}
                        >
                          <option value="">Selecione uma opção</option>
                          <option value="1">Proprietário ou Sócio</option>
                          <option value="3">Gestor</option>
                          <option value="4">Funcionário</option>
                        </select>

                        <span className="invalid-form">
                          {errors.extra && errors.extra?.empresa?.cargo}
                        </span>
                      </div>
                    </>

                    <div className="mt-2 col-lg-12 col-md-12 text-right">
                      <button type="submit" className="btn btn-primary">
                        Próximo
                      </button>
                    </div>
                  </div>
                </form>
              )}
            </Formik>
          )}
        </div>
      </>
    );
  }
}

export default FromEnterprise;
