import React from "react";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import { useHistory } from "react-router-dom";

export default function ModalAlert(props) {
  const [show, setShow] = React.useState(true);
  let history = useHistory();

  const handleClose = () => {
    setShow(false);

    if (props.resetTitleModal) {
      props.resetTitleModal();
    }

    if (props.navigationPath) {
      history.push(props.navigationPath);
    }
  };

  return (
    <>
      <Modal show={show} onHide={handleClose}>
        <Modal.Header>
          <Modal.Title>{props.title}</Modal.Title>
        </Modal.Header>
        {props.body && (
          <Modal.Body>
            {props.body} {props.link && <a href={props.link}>{props.link}</a>}
          </Modal.Body>
        )}

        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Avançar
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}
