import React from 'react'
import CSSTransition from 'react-transition-group/CSSTransition';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';


export default class ModalPricavy extends React.Component {
  show = false;

  constructor (props) {
    super(props)
    this.state = {
      isOpenPrivacy: false
    }
    this.closeModalPrivacy = this.closeModalPrivacy.bind(this)
    this.updateFocus = this.updateFocus.bind(this)
  }

  openModalPrivacy () {
    this.setState({isOpenPrivacy: true})
  }

  closeModalPrivacy () {
    this.setState({isOpenPrivacy: false})
    if (typeof this.props.onClose === 'function') {
      this.props.onClose();
    }
  }

  keydownHandler(e) {
    if (e.keyCode === 27) {
      this.closeModalPrivacy();
    }
  }

  componentDidMount() {
    document.addEventListener('keydown', this.keydownHandler.bind(this));
  }

  componentWillUnmount() {
    document.removeEventListener('keydown', this.keydownHandler.bind(this));
  }

  static getDerivedStateFromProps(props) {
    return { isOpenPrivacy: props.isOpenPrivacy };
  }

  componentDidUpdate () {
    if (this.state.isOpenPrivacy && this.modal) {
      this.modal.focus();
    }
  }
  
  updateFocus (e) {
    if (e.keyCode === 9) {
      e.preventDefault()
      e.stopPropagation()
      if (this.modal === document.activeElement) {
        this.modalbtn.focus()
      } else {
        this.modal.focus()
      }
    }
  }

    handleClose () {
        this.setState({ show: false });
    };
    handleShow () {
        this.setState({ show: true });
    };

  render() {

    return (
      <CSSTransition
        classNames={this.props.classNames.modalVideoEffect}
        timeout={this.props.animationSpeed}
      >
        {() => {
          if (!this.state.isOpenPrivacy) {
            return null;
          }

          return (
            <>
            <Modal show={this.show} onHide={this.handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>Discordo</Modal.Title>
                </Modal.Header>
                <Modal.Body>Para acessar o serviço você deve concordar com o termo de uso e permitir acesso aos dados pessoais. Tem certeza que discorda?</Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={this.handleClose}>
                        Sim, não aceito.
                    </Button>
                    <Button variant="primary" onClick={this.handleClose}>
                        Não, quero aceitar as condições para acesso
                    </Button>
                </Modal.Footer>
            </Modal>
            

            <div className="overlayModal"></div>
            <div className="modalSebrae" tabIndex='-1' role='dialog'
              aria-label="modalSebrae" ref={node => { this.modal = node; }} onKeyDown={this.updateFocus}>
              <div className="modal-body">
                {/* <button className="close" onClick={this.closeModalPrivacy}>x</button> */}
                <p>Para concluir seu cadastro, você deve aceitar a Política e os Termos de Uso:</p>
                <h2 className="title">POLÍTICA DE PRIVACIDADE E TRATAMENTO DE DADOS PESSOAIS SEBRAE (GERAL)</h2><br />

                <div className="modal-content">
                    <div className="termo-uso-text-body">
                        <h3>&nbsp;</h3>
                        <p>A presente <strong>Política de Privacidade e Tratamento de Dados Pessoais</strong> tem
                            por finalidade demonstrar o compromisso do <strong>SISTEMA SEBRAE</strong> com a privacidade
                            e o tratamento dos dados pessoais de seus <strong>USUÁRIOS</strong>.</p>
                        <p>Como condição de acesso aos sistemas do <strong>SEBRAE</strong>, o
                            <strong>USUÁRIO</strong> declara que fez a leitura completa e atenta, estando
                            plenamente ciente da presente <strong>Política de Privacidade e Tratamento de Dados
                                Pessoais,</strong> conferindo, expressamente, sua livre, inequívoca e informada
                            concordância com os termos aqui estipulados, autorizando a obtenção dos
                            dados pessoais e informações aqui mencionados, bem como sua
                            utilização para as finalidades abaixo especificadas.</p>
                        <p>Caso não esteja de acordo com estas diretivas, o <strong>USUÁRIO</strong> deve
                            descontinuar o seu acesso no <strong>SEBRAE</strong>.</p>
                        <ol>
                            <li><strong>COLETA E USO DE DADOS PESSOAIS E REGISTRO DE ATIVIDADES</strong>
                                <ul>
                                    <li>Os dados pessoais são coletados a partir do consentimento pelo
                                        <strong>USUÁRIO</strong>.</li>
                                    <li>A relação de dados pessoais coletados e as respectivas finalidades
                                        constam no Anexo I.</li>
                                    <li>Outros dados pessoais que não estão descritos no Anexo I podem ser
                                        coletados de acordo com a natureza específica do produto ou
                                        serviço em questão, nos termos de documento específico que
                                        deve ser considerado em conjunto com esta <strong>Política de Privacidade
                                        </strong><strong>e Tratamento de Dados Pessoais</strong><strong>.</strong></li>
                                    <li>O <strong>SEBRAE</strong> não é responsável pela
                                        precisão, veracidade ou atualidade das informações
                                        prestadas pelo <strong>USUÁRIO</strong>, sendo de responsabilidade do
                                        <strong>USUÁRIO </strong>prestá-las com exatidão ou
                                        atualizá-las sempre que for o caso.</li>
                                    <li>O <strong>SEBRAE</strong> pode realizar atualização e
                                        enriquecimento dos dados.</li>
                                    <li>O <strong>SEBRAE</strong> garante ao <strong>USUÁRIO</strong> o direito
                                        de correção de dados incompletos, inexatos ou desatualizados.</li>
                                </ul>
                            </li>
                        </ol>
                        <p>&nbsp;</p>
                        <ul>
                            <li>O <strong>SEBRAE</strong> utiliza as tecnologias compatíveis com o mercado,
                                respeitando o estado da técnica razoável, com atualizações
                                razoáveis e constantes, para registrar eventos de navegação,
                                cliques, eventos e dados não identificáveis. Todas as tecnologias
                                utilizadas devem respeitar a legislação vigente e os termos desta
                                <strong>Política de Privacidade</strong> <strong>e Tratamento de Dados
                                    Pessoais</strong><strong>.</strong></li>
                            <li>Ao utilizar os ambientes digitais do <strong>SEBRAE</strong>, o
                                <strong>USUÁRIO</strong> pode ser conduzido, via <em>link,</em> a outros portais
                                ou plataformas independentes que podem coletar as informações do
                                <strong>USUÁRIO</strong> e ter sua própria Política de Privacidade
                                <strong>Política de </strong><strong>Privacidade</strong><strong> e Tratamento de
                                    Dados Pessoais</strong><strong>.</strong></li>
                            <li>Cabe ao <strong>USUÁRIO </strong>ler a <strong>Política de
                                    Privacidade</strong><strong> e Tratamento de Dados Pessoais</strong> de tais
                                ambientes digitais fora do ambiente do <strong>SEBRAE</strong>, sendo de
                                responsabilidade do <strong>USUÁRIO </strong>aceitá-la ou
                                rejeitá-la.
                                <ul>
                                    <li>O <strong>SEBRAE</strong> não é responsável pela
                                        <strong>Política de Privacidade</strong><strong> e Tratamento de Dados
                                            Pessoais</strong> nem pelo conteúdo de quaisquer <em>websites</em>,
                                        conteúdos ou serviços fora do ambiente dos sistemas do
                                        <strong>SEBRAE</strong>, ainda que a ele vinculados por meio de <em>links</em>.
                                    </li>
                                </ul>
                            </li>
                            <li>O consentimento fornecido pelo <strong>USUÁRIO</strong> é coletado de
                                forma individual, clara, específica, legítima e informado ao titular.</li>
                            <li>O <strong>USUÁRIO</strong> pode alterar suas concessões de consentimento,
                                conceder novas permissões ou retirar seu consentimento para as emissões
                                atuais por meio do <strong>Canal de Atendimento à LGPD do</strong>
                                <strong>SEBRAE</strong>, <strong>disponível no Portal Sebrae </strong><a href="https://www.sebrae.com.br/lgpd"><strong>https://www.sebrae.com.br/lgpd</strong></a><strong>,</strong>
                                sendo avisado das consequências que a retirada de consentimento pode causar.</li>
                            <li>O <strong>USUÁRIO </strong>pode entrar em contato diretamente por meio do
                                endereço eletrônico https://sebrae.com.br/lgpd. Os dados coletados e as
                                atividades registradas também podem ser compartilhados:</li>
                        </ul>
                        <ol>
                            <li>Com autoridades judiciais, administrativas ou governamentais competentes, nos casos de
                                requerimento, requisição ou ordem judicial;</li>
                            <li>Com os órgãos de controle que fiscalizam o <strong>SEBRAE</strong>, quando
                                solicitado.
                                <ul>
                                    <li>A base de dados, formada por meio da coleta de dados pessoais, é de
                                        propriedade e responsabilidade do <strong>SEBRAE</strong>. Seu uso, acesso e
                                        compartilhamento, quando necessários, serão feitos dentro dos
                                        limites e propósitos das atividades institucionais do <strong>SEBRAE
                                        </strong>e descritos no <strong>Anexo I desta</strong> <strong>Política
                                            de Privacidade</strong> e em <strong>Termos de Uso</strong>
                                        específicos, quando existentes.
                                        <ul>
                                            <li>O <strong>USUÁRIO</strong> é corresponsável pelo
                                                sigilo e pela confidencialidade de seus dados pessoais.</li>
                                            <li>O compartilhamento de senhas ou dados de acesso viola esta
                                                <strong>Política de </strong><strong>Privacidade
                                                </strong><strong>e Tratamento de Dados Pessoais</strong> e os
                                                <strong>Termos de Uso</strong> dos sistemas do <strong>SEBRAE</strong>.
                                            </li>
                                        </ul>
                                    </li>
                                    <li>Internamente, os dados dos<strong> USUÁRIOS </strong>são acessados
                                        somente por profissionais devidamente autorizados pelo <strong>SEBRAE,
                                        </strong>sendo eles dirigentes, empregados, prestadores de serviços e
                                        parceiros.</li>
                                    <li>O <strong>SEBRAE</strong> respeita os princípios de licitude, finalidade,
                                        adequação, proporcionalidade, necessidade, livre acesso, qualidade
                                        dos dados, transparência, segurança, prevenção,
                                        não discriminação, responsabilização,
                                        prestação de contas, subsidiariedade e limitação de
                                        armazenamento, além de firmar o compromisso de confidencialidade e
                                        preservação da privacidade nos termos desta
                                        <strong>Política de </strong><strong>Privacidade e Tratamento de Dados
                                            Pessoais</strong> com os <strong>USUÁRIOS</strong>.</li>
                                </ul>
                            </li>
                            <li><strong>ARMAZENAMENTO DOS DADOS E REGISTROS</strong>
                                <ul>
                                    <li>Os dados pessoais coletados e os registros de atividades devem ser armazenados
                                        em ambiente seguro e controlado pelo prazo mínimo estipulado, conforme a
                                        tabela abaixo:</li>
                                </ul>
                            </li>
                        </ol>
                        <table>
                            <thead>
                                <tr>
                                    <td width="151">
                                        <p><strong>DADOS PESSOAIS</strong></p>
                                    </td>
                                    <td width="175">
                                        <p><strong>PRAZO DE ARMAZENAMENTO</strong></p>
                                    </td>
                                    <td>
                                        <p><strong>FUNDAMENTO LEGAL</strong></p>
                                    </td>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td width="151">
                                        <p><strong>Dados cadastrais</strong></p>
                                    </td>
                                    <td width="175">
                                        <p>10 anos após o término da relação</p>
                                    </td>
                                    <td>
                                        <p>Arts. 12 e 34 do Código de Defesa do Consumidor (Lei nº 8.078/90)
                                            e art. 70 da Constituição Federal.</p>
                                    </td>
                                </tr>
                                <tr>
                                    <td width="151">
                                        <p><strong>Dados de identificação digital</strong></p>
                                    </td>
                                    <td width="175">
                                        <p>6 meses do último acesso</p>
                                    </td>
                                    <td>
                                        <p>Art. 15 do Marco Civil da Internet (Lei nº 12.965/14)</p>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                        <ul>
                            <li>Os dados podem ser excluídos antes desse prazo no caso de
                                solicitação do <strong>USUÁRIO</strong>. Os dados podem ser
                                mantidos por período superior ao previsto neste prazo, por motivo previsto em
                                lei, por decisão judicial, para fins de prestação de contas aos
                                órgãos de controle ou por outros interesses legítimos do
                                <strong>SEBRAE</strong>, devidamente especificados e informados ao titular de dados.
                                Findo o prazo e a necessidade legal, os dados devem ser excluídos com uso de
                                métodos de descarte seguro, ou utilizados de forma anonimizada para fins
                                estatísticos.</li>
                            <li>Os dados coletados devem ser armazenados em servidores próprios ou em provedores
                                de serviços que atendam aos controles de proteção de dados pessoais
                                aplicáveis.</li>
                        </ul>
                        <ol start="3">
                            <li><strong>ATENDIMENTO AOS DIREITOS DO USUÁRIO</strong>
                                <ul>
                                    <li>O <strong>USUÁRIO</strong> pode solicitar a confirmação da
                                        existência de tratamento; o acesso aos seus dados pessoais; ou a
                                        correção de seus dados pessoais, por meio do <strong>Canal de
                                            Atendimento a LGPD do</strong> <strong>SEBRAE,
                                        </strong><strong>disponível no Portal Sebrae </strong><a href="https://www.sebrae.com.br/lgpd"><strong>https://www.sebrae.com.br/lgpd</strong></a><strong>.</strong>
                                    </li>
                                    <li>Pelo <strong>Canal de Atendimento à LGPD</strong>, o
                                        <strong>USUÁRIO</strong> pode também: (i) requerer o bloqueio do
                                        uso de seus dados pessoais; (ii) manifestar oposição ao tratamento
                                        de seus dados pessoais realizado com fundamento em uma das hipóteses de
                                        dispensa de consentimento, (iii) solicitar a eliminação de seus
                                        dados pessoais coletados e registrados pelo <strong>SEBRAE</strong> ao
                                        término de sua finalidade de uso ou (iv) solicitar a revisão de
                                        decisões automatizadas (v) solicitar portabilidade dos dados pessoais,
                                        (vi) solicitar informações sobre com quem foram compartilhados os
                                        dados pessoais e (vii) informações sobre o impacto da
                                        decisão de não consentir ou revogar o consentimento.</li>
                                    <li>Mesmo que o <strong>USUÁRIO</strong> não conceda ou solicite a
                                        revogação do consentimento para as finalidades relacionadas ao
                                        envio de informações, o <strong>SEBRAE</strong> poderá
                                        enviar informações transacionais relativas à
                                        prestação dos serviços.</li>
                                    <li>Para fins de auditoria, segurança, controle de fraudes e
                                        preservação de direitos, o <strong>SEBRAE</strong> pode permanecer
                                        com o histórico de registro e os dados dos
                                        <strong>USUÁRIOS</strong> por prazo maior nas hipóteses que a lei
                                        ou norma regulatória assim estabelecer, para realização de
                                        prestação de contas aos órgãos de controle, ou para
                                        preservação de direitos do <strong>SEBRAE</strong> ou do titular
                                        de dados.</li>
                                </ul>
                            </li>
                        </ol>
                        <p>&nbsp;</p>
                        <ol start="4">
                            <li><strong>DISPOSIÇÕES GERAIS</strong>
                                <ul>
                                    <li>O <strong>SEBRAE</strong> reserva a si o direito de alterar a
                                        <strong>Política de Privacidade e Tratamento de Dados Pessoais</strong> a
                                        qualquer momento, conforme a finalidade ou a necessidade, como para
                                        adequação e conformidade com disposição de lei ou
                                        norma que tenha força jurídica equivalente, cabendo ao
                                        <strong>USUÁRIO</strong> verificar a versão atualizada sempre que
                                        efetuar o acesso aos sistemas do <strong>SEBRAE</strong>.</li>
                                    <li>Ocorrendo atualizações neste documento e que demandem nova coleta
                                        de consentimento, o <strong>SEBRAE</strong> deve notificar o
                                        <strong>USUÁRIO</strong> pelos seus sistemas capazes de realizar nova
                                        coleta de consentimento ou pelos meios de contato por ele fornecidos.</li>
                                </ul>
                            </li>
                        </ol>
                        <p>4.3. &nbsp;&nbsp;&nbsp; Em caso de qualquer dúvida com relação às
                            disposições constantes desta <strong>Política de Privacidade e
                                Tratamento de Dados Pessoais</strong>, o <strong>USUÁRIO</strong> pode entrar em
                            contato com o <strong>SEBRAE </strong>por meio do <strong>Canal de Atendimento à LGPD
                                do</strong> <strong>SEBRAE,</strong> <strong>disponível no Portal Sebrae
                            </strong><a href="https://www.sebrae.com.br/lgpd"><strong>https://www.sebrae.com.br/lgpd</strong></a><strong>.
                            </strong></p>
                        <p>4.4. Caso empresas terceirizadas realizem o tratamento de quaisquer dados coletados pelo
                            <strong>SEBRAE</strong>, devem respeitar as condições aqui estipuladas e as
                            normas de Segurança da Informação do <strong>SEBRAE</strong>, bem como,
                            Políticas e procedimentos adicionais.</p>
                        <p>4.5. Caso alguma disposição desta <strong>Política de Privacidade e
                                Tratamento de Dados Pessoais</strong> seja considerada ilegal ou ilegítima por
                            autoridade de proteção de dados, as demais condições permanecem
                            em pleno vigor e efeito.</p>
                        <p>4.6. O <strong>USUÁRIO</strong> reconhece que toda comunicação realizada
                            por <em>e-mail</em> (aos endereços informados no seu cadastro), SMS, aplicativos de
                            comunicação instantânea ou qualquer outra forma física, virtual e
                            digital também são válidas como prova documental, sendo eficazes e
                            suficientes para a divulgação de qualquer assunto que se refira aos
                            serviços prestados pelo <strong>SEBRAE</strong>, bem como às
                            condições de sua prestação ou a qualquer outro assunto nele
                            abordado, ressalvadas as disposições expressamente diversas previstas nesta
                            <strong>Política de Privacidade e Tratamento de Dados Pessoais</strong>.</p>
                        <p>4.7 O <strong>SEBRAE</strong> utiliza <em>cookies</em>&nbsp;e tecnologias similares para
                            avaliar e compreender o perfil e o comportamento dos <strong>USUÁRIOS</strong> que
                            visitam ou acessam as páginas, com a finalidade de personalizar a experiência
                            de navegação do <strong>USUÁRIO</strong> ao promover e divulgar
                            produtos e serviços, além de realizar prevenção a fraudes.</p>
                        <p>4.8. O <strong>SEBRAE</strong> utiliza cookies classificados em três categorias:
                            Essencial, Desempenho (Análise e Personalização) e Publicidade.</p>
                        <p>4.8.1. Essencial: Necessários para o funcionamento do site. Eles permitem a
                            navegação pelo <strong>USUÁRIO</strong> em nossos sites e o uso dos
                            seus serviços e recursos (por exemplo, cookies de segurança para autenticar
                            <strong>USUÁRIOS</strong>, evitar a utilização fraudulenta de
                            credenciais de login e proteger os dados do <strong>USUÁRIO</strong> de terceiros
                            não autorizados).</p>
                        <p>4.8.2. Desempenho: Coletamos informações de forma anônima que permitem
                            determinar informações suficientes para realizar análises de
                            navegação, como por exemplo, o número de visitantes de uma
                            página, como o <strong>USUÁRIO</strong> chegou ao site e as páginas
                            acessadas.</p>
                        <p>4.8.3. Publicidade: utilizamos alguns cookies com o fim publicitário. Isto se faz para
                            entregar anúncios e fazer campanhas publicitárias de acordo com um determinado
                            público. Através desses é possível entregar anúncios de
                            acordo com o seu perfil de consumo no site.</p>
                        <p>4.9. É necessária a permissão do <strong>USUÁRIO</strong> em
                            aceitar e gerenciar as preferências no uso dos cookies, antes de iniciada a
                            seção, para que sejam coletados.</p>
                        <p>4.10. <strong>Utilização de cookies de terceiros</strong>: Prestadores de
                            serviços de tecnologia poderão utilizar seus próprios cookies nos
                            serviços, com a nossa autorização, para prestação de
                            serviços ao <strong>SEBRAE</strong>. Tais cookies coletarão os dados do
                            <strong>USUÁRIO</strong> nas nossas propriedades para as finalidades previstas nesta
                            <strong>Política</strong>.</p>
                        <ol start="5">
                            <li><strong>LEI APLICÁVEL E JURISDIÇÃO</strong></li>
                        </ol>
                        <p>A presente <strong>Política de Privacidade</strong><strong> e Tratamento de Dados
                                Pessoais</strong> deve ser regida e interpretada segundo a legislação
                            brasileira, no idioma português, sendo eleito o Foro da Circunscrição
                            Especial Judiciária de Brasília/Distrito Federal para dirimir qualquer
                            litígio ou controvérsia envolvendo o presente documento, salvo ressalva
                            específica de competência pessoal, territorial ou funcional pela
                            legislação aplicável.</p>
                        <p>&nbsp;</p>
                        <p>&nbsp;</p>
                        <p><strong>&nbsp;</strong></p>
                        <ol start="6">
                            <li><strong>GLOSSÁRIO</strong>
                                <ul>
                                    <li>Para os fins deste documento, devem se considerar as seguintes
                                        definições:</li>
                                </ul>
                            </li>
                        </ol>
                        <p><strong><em>Cookies</em></strong>: Pequenos arquivos enviados pelos sistemas do
                            <strong>SEBRAE</strong>, gravados nos dispositivos dos <strong>USUÁRIOS</strong>, que
                            armazenam as preferências e outras informações, com a finalidade de
                            personalizar a navegação dos <strong>USUÁRIOS</strong> no
                            <strong>SITE</strong>, de acordo com o seu perfil.</p>
                        <p><strong>IP</strong>: Abreviatura de <em>Internet Protocol</em>. É o conjunto
                            alfanumérico que identifica os dispositivos dos <strong>USUÁRIOS</strong> na
                            Internet.</p>
                        <p><strong>Session ID</strong>: Credencial de um <strong>USUÁRIO</strong>
                            necessária para acessar os sistemas do<strong> SEBRAE </strong>e utilizar as suas
                            funcionalidades.</p>
                        <p><strong>USUÁRIO</strong>: Pessoa natural que acessa e/ou utiliza as funcionalidades
                            dos sistemas do <strong>SEBRAE.</strong></p>
                        <p><strong>&nbsp;</strong></p>
                        <p><strong>ESTA POLÍTICA FOI APROVADA PELA RESOLUÇÃO CDN Nº
                                357/2020.</strong></p>
                        <p><strong>ANEXO I – </strong><strong>LISTA DE DADOS PESSOAIS E FINALIDADES DE
                                USO</strong></p>
                        <table>
                            <thead>
                                <tr>
                                    <td>
                                        <p><strong>TIPOS DE DADOS</strong></p>
                                    </td>
                                    <td width="151">
                                        <p><strong>DADOS PESSOAIS</strong></p>
                                    </td>
                                    <td width="321">
                                        <p><strong>FINALIDADE DE USO DOS DADOS</strong></p>
                                    </td>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>
                                        <p><strong>Cadastrais</strong></p>
                                    </td>
                                    <td width="151">
                                        <p>Nome completo<em>, e-mail</em>, nome da mãe, escolaridade,
                                            endereço, telefone, CNPJ e vínculo com a empresa, CPF, sexo,
                                        </p>
                                        <p>senha de acesso, data de nascimento e foto/imagem.</p>
                                    </td>
                                    <td width="321">
                                        <p>- Identificar o USUÁRIO;</p>
                                        <p>- Prover atendimento personalizado;</p>
                                        <p>- Cumprir as obrigações decorrentes do uso dos produtos e
                                            serviços do SEBRAE;</p>
                                        <p>- Informar sobre novidades, funcionalidades, conteúdos,
                                            notícias e demais eventos relevantes para a manutenção
                                            do relacionamento com o&nbsp;USUÁRIO;</p>
                                        <p>- Promover e divulgar produtos e serviços do SEBRAE;</p>
                                        <p>- Responder a solicitações e pedidos de
                                            informações do&nbsp;USUÁRIO;</p>
                                        <p>- Realizar pesquisas do interesse do Sebrae;</p>
                                        <p>- Permitir acesso às áreas restritas dos ambientes digitais do
                                            SEBRAE;</p>
                                        <p>- Prestar contas para Órgãos de controle na forma da lei;</p>
                                        <p>- Personalizar o ambiente dos clientes em sua navegação nos
                                            ambientes digitais do SEBRAE.</p>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <p><strong>Informações sobre transações</strong></p>
                                    </td>
                                    <td width="151">
                                        <p>Produtos e serviços adquiridos e respectivas quantidades, data e
                                            período da transação, número da nota fiscal</p>
                                    </td>
                                    <td width="321">
                                        <p>Creditar os produtos e serviços adquiridos</p>
                                    </td>
                                </tr>
                                <tr>
                                    <td width="94">
                                        <p><strong>Dados de identificação digital</strong></p>
                                    </td>
                                    <td width="151">
                                        <p>Endereço IP e Porta Lógica de Origem, Registros de
                                            interações com os ambientes digitais, Telas acessadas,
                                            dispositivo (versão do sistema operacional,
                                            Geolocalização e outros aplicativos instalados, se
                                            necessário), <em>Session ID</em> e <em>Cookies.</em></p>
                                    </td>
                                    <td width="321">
                                        <p>- Identificar o USUÁRIO</p>
                                        <p>- Administrar, creditar e enviar serviços adquiridos por meio dos
                                            ambientes digitais;</p>
                                        <p>- Cumprir as obrigações decorrentes do uso dos serviços;
                                        </p>
                                        <p>- Promover e divulgar produtos e serviços;</p>
                                        <p>- Realizar prevenção a fraudes, personalizar a
                                            experiência de navegação nos canais, por meio de
                                            georreferenciamento;</p>
                                    </td>
                                </tr>
                                <tr>
                                    <td width="94">
                                        <p><strong>Dados anonimizados</strong></p>
                                    </td>
                                    <td width="151">
                                        <p>-</p>
                                    </td>
                                    <td width="321">
                                        <p>Elaborar análises e estudos estatísticos</p>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                        <p>&nbsp;</p>
                        <p>&nbsp;</p>
                    </div>
                    <p>Baixe aqui a <a href="https://minio-cpe.sebrae.com.br/documento/politica-privacidade.pdf">Política de Privacidade</a></p>
                </div>

                <button className="accept" onClick={() => this.props.onConfirm()}>Aceito a Política de Privacidade</button>
                <button className="dismiss" onClick={() => {this.props.close()}}>Discordo com com a Política de Privacidade</button>

              </div>
            </div>
            </>)
        }}
      </CSSTransition>
    )
  }
}

ModalPricavy.defaultProps = {
    isOpenPrivacy: false,
    animationSpeed: 300,
    classNames: {
      modalVideoEffect: 'modal-video-effect',
      modalVideo: 'modal-video',
      modalVideoClose: 'modal-video-close',
      modalVideoBody: 'modal-video-body',
      modalVideoInner: 'modal-video-inner',
      modalVideoIframeWrap: 'modal-video-movie-wrap',
      modalVideoCloseBtn: 'modal-video-close-btn'
    }
}
