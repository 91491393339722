import api from "./api";

export function recoveryPassword(id) {
  return api.get(`/player/password/change?player=${id.trim()}`);
}

export function updatePassword({ id, newPass, code }) {
  const url = encodeURI(
    `/player/password?player=${id
      .toLocaleLowerCase()
      .trim()}&new_password=${newPass}&code=${code}`
  );

  return api.put(url);
}
