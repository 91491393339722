import { Formik, Field } from "formik";
import React from "react";
import * as yup from "yup";
import api from "../../services/api";
import { DateTime } from "luxon";

class FormEvent extends React.Component {
  state = {
    data: [],
    userEvent: [],
    noEvents: false,
    formFields: {},
    erroMessage: false,
    isEnrolledEvent: false,
  };
  arr = ["sebrae_experience"]; // default value
  // eslint-disable-next-line
  constructor(props) {
    super(props);
  }

  componentDidMount() {
    const getData = async () => {
      const resFunifier = await api.post(
        "database/sebraesp_event__c/aggregate",
        [
          {
            $match: {
              active: true,
              _id: {
                $ne: "sebrae_experience",
              },
            },
          },
          {
            $addFields: {
              year: { $substr: ["$end_date", 0, 4] },
              month: { $substr: ["$end_date", 5, 2] },
              day: { $substr: ["$end_date", 8, 2] },
            },
          },
          {
            $addFields: {
              end_date_final: {
                $dateFromString: {
                  dateString: {
                    $concat: [
                      "$year",
                      "-",
                      "$month",
                      "-",
                      "$day",
                      "T",
                      "20:59",
                      ":59.999",
                    ],
                  },
                  timezone: "America/Sao_Paulo",
                },
              },
            },
          },
          {
            $match: {
              end_date_final: {
                $gte: {
                  $date: "now",
                },
              },
            },
          },
          {
            $sort: {
              start_date: 1,
            },
          },
        ],
        {
          headers: {
            Authorization:
              "Basic " +
              window.btoa(
                process.env.REACT_APP_APIKEY +
                  ":" +
                  process.env.REACT_APP_PUBLIC_SECRETKEY
              ),
          },
        }
      );

      let search = window.location.search;
      let params = new URLSearchParams(search);
      let token = params.get("token");

      if (token) {
        try {
          const authToken =
            "Basic " +
            window.btoa(
              `${process.env.REACT_APP_APIKEY}:${process.env.REACT_APP_GLOBAL_SECRETKEY}`
            );

          let res = await api.get(
            `/database/registration_temp__c?q='_id':'${token}'`,
            {
              headers: {
                Authorization: authToken,
              },
            }
          );

          res = res.data[0];

          this.setState({
            formFields: {
              _id: res.cpf,
              ...this.state.formFields,
              email: res.email,
              confirmEmail: res.email,
              name: res.name,
              extra: {
                ...this.state.formFields.extra,
                cpf: res.cpf,
                sexo: res.genero === "masculino" ? "M" : "F",
                data_nascimento: new Date(res.dataNascimento),
                openId: true,
              },
            },
          });

          this.getFunifier(res.cpf);
        } catch (error) {
          console.log("error =>", error);
        }
      }

      let eventList;

      if (resFunifier.data && resFunifier.data[0]) {
        eventList = resFunifier.data.filter(
          (item) => item._id !== "sebrae_experience"
        );

        this.setState({ data: eventList });
      } else {
        this.setState({ data: [] });
      }
    };
    getData();
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps?.dataAmei && prevProps?.dataAmei?.cpf) {
      this.getFunifier(this.props.dataAmei.cpf, prevProps?.dataAmei);
    }
  }

  async getFunifier(cpf, dataAmei) {
    if (this?.state?.formFields?._id) return;

    let userFromFunifier = await api.post(
      `database/player/aggregate`,
      [
        {
          $match: {
            "extra.cpf": cpf.replace(/[^\d]+/g, ""),
            "extra.idStand": { $exists: false },
          },
        },
      ],
      {
        headers: {
          Authorization:
            "Basic " +
            window.btoa(
              `${process.env.REACT_APP_APIKEY}:${process.env.REACT_APP_GLOBAL_SECRETKEY}`
            ),
        },
      }
    );

    if (userFromFunifier?.data && userFromFunifier.data[0]) {
      userFromFunifier = userFromFunifier.data[0];
    }

    if (dataAmei) {
      if (userFromFunifier?.extra?.events) {
        let events = [...this.state.data];

        userFromFunifier.extra.events.forEach((e) => {
          events = events.filter((item) => item._id !== e.idEvent);
        });

        if (!events || !events[0]) {
          this.setState({ isEnrolledEvent: true });
        }
      }

      if (
        userFromFunifier?.extra?.events &&
        !Array.isArray(userFromFunifier.extra.events)
      ) {
      }

      this.setState({
        formFields: {
          _id: dataAmei.cpf,
          ...this.state.formFields,
          email: dataAmei.email,
          confirmEmail: dataAmei.email,
          name: dataAmei.name,
          extra: {
            ...this.state.formFields.extra,
            cpf: dataAmei.cpf,
            sexo: dataAmei.genero === "masculino" ? "M" : "F",
            data_nascimento: new Date(dataAmei.dataNascimento),
            openId: true,
          },
        },
      });
    }

    if (userFromFunifier.extra?.events) {
      this.setState({
        userEvent:
          userFromFunifier.extra.events &&
          !Array.isArray(userFromFunifier.extra.events)
            ? [userFromFunifier.extra.events]
            : userFromFunifier.extra.events,
      });
    }
  }

  schema = yup.object().shape({
    event: yup
      .array()
      .test("is-valid", `Marque uma opção em todos os eventos`, (value) => {
        const events = [...this.state.data].filter((e) =>
          this.checkEnrolled(e._id)
        );

        let data = [
          ...this.state.data.filter(
            (e) => this.checkEnrolled(e._id) && !this.checkInput(e._id)
          ),
        ];

        let index = 0;

        events.forEach((e) => {
          value
            .filter((el) => el)
            .forEach((el) => {
              data = data.filter((item) => {
                if (item._id !== el.split(",")[1]) return true;
                if (el.split(",")[0] === "false") index += 1;
                return false;
              });
            });
        });

        if (index === events.length && !this.checkIsEnrolled()) {
          this.setState({ erroMessage: true });
          return false;
        }

        this.setState({ erroMessage: false });
        if (!data || !data[0]) return true;

        return false;
      }),
  });

  checkInput(value) {
    return this.state.userEvent.find(
      (e) => e.idEvent === value && e.isEnrolled === false
    );
  }

  checkEnrolled(value) {
    if (!this.state.userEvent.find((e) => e.idEvent === value)) return true;

    return this.state.userEvent.find(
      (e) => e.idEvent === value && !e.isEnrolled
    );
  }

  checkIsEnrolled(value) {
    if (!this.state.data || !this.state.data[0]) return false;

    if (value) {
      return this.state.userEvent.find(
        (e) => e.idEvent === value && e.isEnrolled
      );
    }
    return this.state.userEvent.find(
      (e) =>
        e.isEnrolled === true &&
        this.state.data.find((el) => e.idEvent === el._id)
    );
  }

  checkEvent() {
    if (!this.state.data || !this.state.data[0]) return false;
    return true;
  }

  verifyDate(start, end) {
    return (
      DateTime.fromJSDate(new Date(start)).toFormat("MM/dd/yyyy") <
      DateTime.fromJSDate(new Date(end)).toFormat("MM/dd/yyyy")
    );
  }

  render() {
    return (
      <>
        {this.checkEvent() && (
          <>
            <>
              <label className="mb-2">
                Confira os eventos disponíveis abaixo e faça sua inscrição.
              </label>
              <label className="mb-3">
                importante: após a realização da inscrição, você receberá um
                e-mail de confirmação. Caso não localize na sua caixa de
                entrada, verifique sua caixa de SPAM.
              </label>
            </>
            {this.checkIsEnrolled() && (
              <>
                <h4>Eventos que você está inscrito:</h4>
                <form id="contactForm">
                  <div className="row">
                    {this.state.data &&
                      this.state.data[0] &&
                      this.state.data.map((e, i) => (
                        <div
                          key={e._id + i + 2}
                          className="col-lg-12 col-md-12"
                        >
                          {this.checkIsEnrolled(e._id, e.name) && (
                            <>
                              <label htmlFor={e._id}>
                                <input
                                  name="event"
                                  id={e._id}
                                  disabled={true}
                                  type="checkbox"
                                  checked={true}
                                />
                                &#160; <strong>{e.name}</strong> -{" "}
                                {DateTime.fromJSDate(
                                  new Date(e.start_date)
                                ).toFormat("dd/MM/yyyy")}{" "}
                                {this.verifyDate(e.start_date, e.end_date) && (
                                  <>
                                    {"a "}
                                    {DateTime.fromJSDate(
                                      new Date(e.end_date)
                                    ).toFormat("dd/MM/yyyy")}
                                  </>
                                )}{" "}
                                {e.end_time && e.start_time && (
                                  <>
                                    {!this.verifyDate(e.start_date, e.end_date)
                                      ? "das "
                                      : "a "}
                                    {DateTime.fromJSDate(
                                      new Date(e.start_time)
                                    ).toFormat("HH:mm")}
                                    h às{" "}
                                    {DateTime.fromJSDate(
                                      new Date(e.end_time)
                                    ).toFormat("HH:mm")}
                                    h{" "}
                                  </>
                                )}
                              </label>
                              <br />
                            </>
                          )}
                        </div>
                      ))}
                  </div>
                </form>
              </>
            )}

            {this.state.data &&
              this.state.data[0] &&
              this.state.data.filter((e) => this.checkEnrolled(e._id))[0] && (
                <>
                  <h4 className="mt-4">Confira os novos eventos!</h4>
                  <label className="mb-2">
                    Você ainda não está inscrito nos eventos abaixo.
                  </label>
                  <label className="mb-2">
                    Selecione "Sim" para se inscrever e "Não" para os eventos
                    que não possui interesse.
                  </label>
                  <Formik
                    validationSchema={this.schema}
                    onSubmit={(values, actions) => {
                      const events = values.event
                        .filter((e) => e && e.split(",")[0] === "true")
                        .map((e) => {
                          e = e.split(",")[1];
                          return e;
                        });

                      if (!events || !events[0])
                        return this.props.update(
                          [events, this.state.data],
                          this.state.formFields
                        );
                      events.push("sebrae_experience");

                      this.props.sendForm([events, this.state.data]);
                    }}
                    initialValues={{
                      event: [],
                    }}
                  >
                    {({
                      handleSubmit,
                      handleChange,
                      handleBlur,
                      setFieldTouched,
                      setFieldValue,
                      values,
                      touched,
                      isValid,
                      isValidating,
                      errors,
                    }) => (
                      <form id="contactForm" onSubmit={handleSubmit}>
                        <div className="row">
                          {this.state.data &&
                            this.state.data[0] &&
                            this.state.data
                              .filter((e) => this.checkEnrolled(e._id))
                              .map((e, i) => (
                                <div
                                  key={e._id}
                                  className="col-lg-12 col-md-12"
                                >
                                  <>
                                    <label className="mb-2" htmlFor={e._id}>
                                      &#160; <strong>{e.name}</strong> -{" "}
                                      {DateTime.fromJSDate(
                                        new Date(e.start_date)
                                      ).toFormat("dd/MM/yyyy")}{" "}
                                      {this.verifyDate(
                                        e.start_date,
                                        e.end_date
                                      ) && (
                                        <>
                                          {"a "}
                                          {DateTime.fromJSDate(
                                            new Date(e.end_date)
                                          ).toFormat("dd/MM/yyyy")}
                                        </>
                                      )}{" "}
                                      {e.end_time && e.start_time && (
                                        <>
                                          {!this.verifyDate(
                                            e.start_date,
                                            e.end_date
                                          )
                                            ? "das "
                                            : "a "}
                                          {DateTime.fromJSDate(
                                            new Date(e.start_time)
                                          ).toFormat("HH:mm")}
                                          h às{" "}
                                          {DateTime.fromJSDate(
                                            new Date(e.end_time)
                                          ).toFormat("HH:mm")}
                                          h{" "}
                                        </>
                                      )}
                                      <label className="w-auto mb-0 ml-2 mr-2">
                                        <Field
                                          className="mr-1"
                                          type="radio"
                                          name={`event[${i}]`}
                                          value={[true, e._id]}
                                          render={({ field }) => (
                                            <input
                                              {...field}
                                              name={`event[${i}]`}
                                              id={e._id}
                                              type="radio"
                                              checked={
                                                values.event && values.event[i]
                                                  ? values.event[i] ===
                                                    "true," + e._id
                                                  : false
                                              }
                                            />
                                          )}
                                        />
                                        <span className="ml-1">Sim</span>
                                      </label>
                                      <label className="w-auto mb-0">
                                        <Field
                                          className="mr-1"
                                          type="radio"
                                          name={`event[${i}]`}
                                          value={[false, e._id]}
                                          render={({ field }) => (
                                            <input
                                              {...field}
                                              name={`event[${i}]`}
                                              id={e._id}
                                              type="radio"
                                              checked={
                                                values.event && values.event[i]
                                                  ? values.event[i] ===
                                                    "false," + e._id
                                                  : !values.event[i] &&
                                                    this.checkInput(e._id)
                                                  ? true
                                                  : false
                                              }
                                            />
                                          )}
                                        />
                                        <span className="ml-1">Não</span>
                                      </label>
                                    </label>
                                    <br />
                                  </>
                                </div>
                              ))}
                          <div className="col-lg-12 col-md-12">
                            <span className="invalid-form invalid-form-custom">
                              {this.state.erroMessage
                                ? "Aceite se inscrever pelo menos em um evento"
                                : errors?.event}
                            </span>
                          </div>
                          <div className="col-lg-12 col-md-12 text-right">
                            <button type="submit" className="btn btn-primary">
                              Próximo
                            </button>
                          </div>
                        </div>
                      </form>
                    )}
                  </Formik>
                </>
              )}
          </>
        )}

        {this.state.isEnrolledEvent && this.props?.global?.url && (
          <>
            <h5 className="mt-4">
              Você já pode acessar o Sebrae Experience clicando no botão abaixo!
            </h5>

            <div className="d-flex justify-content-center">
              <a
                className="text-ends mt-1 button-link"
                href={this.props.global.url}
                title={this.props?.global?.title}
                alt={this.props?.global?.title}
                rel="noopener noreferrer"
              >
                Acessar o Sebrae Experience
              </a>
            </div>
          </>
        )}
      </>
    );
  }
}

export default FormEvent;
