import axios from "axios";

const api = axios.create({
  baseURL: `https://${process.env.REACT_APP_SERVICE_URL}.funifier.com/v3/`,
  headers: {
    "Content-type": "application/json",
    "cache-control": "no-cache",
    Authorization:
      "Basic " +
      window.btoa(
        process.env.REACT_APP_APIKEY +
          ":" +
          process.env.REACT_APP_PUBLIC_SECRETKEY
      ),
  },
});

export default api;
