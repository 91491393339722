import { Formik } from "formik";
import { validateBr } from "js-brasil";
import React from "react";
import MaskedInput from "react-text-mask";
import * as yup from "yup";

class FormUnderage extends React.Component {
  state = {
    collapsed: true,
    isOpen: false,
  };

  // eslint-disable-next-line
  constructor(props) {
    super(props);
  }

  schema = yup.object().shape({
    name: yup
      .string()
      .required(`Nome é obrigatório!`)
      .test(
        "is-valid",
        `Nome inválido, por favor digite seu nome completo`,
        (value) => {
          if (value !== null && value !== undefined) {
            const regExp = /(\w+)/g;
            const str = value.match(regExp)?.length;

            if (str && str >= 2) {
              return true;
            }
          }

          return false;
        }
      ),
    password: yup.string().required(`Senha é obrigatório!`),
    confirmPassword: yup
      .string()
      .required(`Senha é obrigatório!`)
      .oneOf([yup.ref("password"), null], `Senhas precisam ser iguais!`),
    extra: yup.object().shape({
      cpf: yup
        .string()
        .optional()
        .test("is-valid", `CPF inválido!`, (value) => {
          if (value && value?.length > 0) {
            return validateBr.cpf(value);
          } else {
            return true;
          }
        }),
      data_nascimento: yup
        .string()
        .required(`Data de nascimento é obrigatório`),
      uf: yup.string().required(`UF é obrigatório`),
      city: yup.string().required(`Cidade é obrigatório`),
      responsavel: yup.object().shape({
        cpf: yup
          .string()
          .required(`CPF é obrigatório!`)
          .test("is-valid", `CPF inválido!`, (value) => {
            if (value) {
              return validateBr.cpf(value);
            }
            return false;
          }),
        mobile: yup.string().required(`Telefone é obrigatório`),
        name: yup
          .string()
          .required(`Nome é obrigatório!`)
          .test(
            "is-valid",
            `Nome inválido, por favor digite seu nome completo`,
            (value) => {
              if (value !== null && value !== undefined) {
                const regExp = /(\w+)/g;
                const str = value.match(regExp)?.length;

                if (str && str >= 2) {
                  return true;
                }
              }

              return false;
            }
          ),
      }),
    }),
  });

  render() {
    return (
      <Formik
        validationSchema={this.schema}
        onSubmit={(values, actions) => {
          setTimeout(() => {
            this.props.sendForm(values);
          }, 1000);
        }}
        initialValues={{
          _id: "",
          name: "",
          email: "",
          confirmEmail: "",
          password: "",
          confirmPassword: "",
          extra: {
            sexo: undefined,
            infosSebraeSP: false,
            data_nascimento: undefined,
            cpf: undefined,
            phone: undefined,
            birthDay: undefined,
            agree: undefined,
            bairro: undefined,
            cep: undefined,
            cidade: undefined,
            logradouro: undefined,
            tipoLogradouro: undefined,
            uf: undefined,
            pais: undefined,
            responsavel: {
              cpf: undefined,
              name: undefined,
              phone: undefined,
              email: undefined,
              agree: undefined,
            },
          },
        }}
      >
        {({
          handleSubmit,
          handleChange,
          handleBlur,
          setFieldTouched,
          setFieldValue,
          values,
          touched,
          isValid,
          isValidating,
          errors,
        }) => (
          <form id="contactForm" onSubmit={handleSubmit}>
            <div className="row">
              <div className="col-lg-12 col-md-12">
                <label htmlFor="">CPF</label>
                <MaskedInput
                  guide={false}
                  name="extra.cpf"
                  keepCharPositions={false}
                  showMask={false}
                  mask={[
                    /\d/,
                    /\d/,
                    /\d/,
                    ".",
                    /\d/,
                    /\d/,
                    /\d/,
                    ".",
                    /\d/,
                    /\d/,
                    /\d/,
                    "-",
                    /\d/,
                    /\d/,
                  ]}
                  type="text"
                  className={`form-control ${
                    errors?.extra?.cpf ? "invalid-form" : ""
                  }`}
                  onChange={handleChange}
                />
                <span className="invalid-form">{errors?.extra?.cpf}</span>
              </div>

              <div className="col-lg-12 col-md-12">
                <label htmlFor="">Nome</label>
                <input
                  name="name"
                  type="text"
                  className={`form-control ${
                    errors.name ? "invalid-form" : ""
                  }`}
                  onChange={handleChange}
                  value={this.state?.formFields?.name}
                />
                <span className="invalid-form">{errors.name}</span>
              </div>

              <div className="col-lg-12 col-md-12">
                <label htmlFor="">E-mail</label>
                <input
                  name="email"
                  type="email"
                  className={`form-control ${
                    errors.email ? "invalid-form" : ""
                  }`}
                  onChange={handleChange}
                />
                <span className="invalid-form">{errors.email}</span>
              </div>

              <div className="col-lg-12 col-md-12">
                <label htmlFor="">Data de nascimento</label>
                <input
                  name="extra.data_nascimento"
                  type="date"
                  className={`form-control ${
                    errors.extra && errors.extra.data_nascimento
                      ? "invalid-form"
                      : ""
                  }`}
                  onChange={handleChange}
                />
                <span className="invalid-form">
                  {errors.extra && errors.extra.data_nascimento}
                </span>
              </div>

              <div className="col-lg-12 col-md-12">
                <label htmlFor="">UF</label>
                <select
                  id="state"
                  name="extra.uf"
                  className={`form-control ${
                    errors.extra && errors.extra.uf ? "invalid-form" : ""
                  }`}
                  onChange={handleChange}
                  value={this?.state?.formFields?.extra?.uf}
                >
                  <option value="">Selecione</option>
                  <option value="AC">Acre</option>
                  <option value="AL">Alagoas</option>
                  <option value="AP">Amapá</option>
                  <option value="AM">Amazonas</option>
                  <option value="BA">Bahia</option>
                  <option value="CE">Ceará</option>
                  <option value="DF">Distrito Federal</option>
                  <option value="ES">Espírito Santo</option>
                  <option value="GO">Goiás</option>
                  <option value="MA">Maranhão</option>
                  <option value="MT">Mato Grosso</option>
                  <option value="MS">Mato Grosso do Sul</option>
                  <option value="MG">Minas Gerais</option>
                  <option value="PA">Pará</option>
                  <option value="PB">Paraíba</option>
                  <option value="PR">Paraná</option>
                  <option value="PE">Pernambuco</option>
                  <option value="PI">Piauí</option>
                  <option value="RJ">Rio de Janeiro</option>
                  <option value="RN">Rio Grande do Norte</option>
                  <option value="RS">Rio Grande do Sul</option>
                  <option value="RO">Rondônia</option>
                  <option value="RR">Roraima</option>
                  <option value="SC">Santa Catarina</option>
                  <option value="SP">São Paulo</option>
                  <option value="SE">Sergipe</option>
                  <option value="TO">Tocantins</option>
                  <option value="EX">Estrangeiro</option>
                </select>
                <span className="invalid-form">
                  {errors.extra && errors.extra.uf}
                </span>
              </div>

              <div className="col-lg-12 col-md-12">
                <label htmlFor="">Cidade</label>
                <input
                  name="extra.city"
                  type="text"
                  className={`form-control ${
                    errors?.extra?.city ? "invalid-form" : ""
                  }`}
                  onChange={handleChange}
                />
                <span className="invalid-form">{errors?.extra?.city}</span>
              </div>

              <div className="col-lg-12 col-md-12">
                <label htmlFor="">CPF Responsável</label>
                <MaskedInput
                  guide={false}
                  name="extra.responsavel.cpf"
                  keepCharPositions={false}
                  showMask={false}
                  mask={[
                    /\d/,
                    /\d/,
                    /\d/,
                    ".",
                    /\d/,
                    /\d/,
                    /\d/,
                    ".",
                    /\d/,
                    /\d/,
                    /\d/,
                    "-",
                    /\d/,
                    /\d/,
                  ]}
                  type="text"
                  className={`form-control ${
                    errors?.extra?.responsavel?.cpf ? "invalid-form" : ""
                  }`}
                  onChange={handleChange}
                />
                <span className="invalid-form">
                  {errors?.extra?.responsavel?.cpf}
                </span>
              </div>

              <div className="col-lg-12 col-md-12">
                <label htmlFor="">Nome Responsavel</label>
                <input
                  name="extra.responsavel.name"
                  type="text"
                  className={`form-control ${
                    errors?.extra?.responsavel?.name ? "invalid-form" : ""
                  }`}
                  onChange={handleChange}
                />
                <span className="invalid-form">
                  {errors?.extra?.responsavel?.name}
                </span>
              </div>

              <div className="col-lg-12 col-md-12">
                <label htmlFor="">Telefone Responsavel</label>
                <MaskedInput
                  guide={false}
                  keepCharPositions={false}
                  showMask={false}
                  mask={[
                    "(",
                    /\d/,
                    /\d/,
                    ")",
                    /\d/,
                    /\d/,
                    /\d/,
                    /\d/,
                    /\d/,
                    "-",
                    /\d/,
                    /\d/,
                    /\d/,
                    /\d/,
                  ]}
                  type="text"
                  name="extra.responsavel.mobile"
                  onChange={handleChange}
                  className={`form-control ${
                    errors?.extra?.responsavel?.mobile ? "invalid-form" : ""
                  }`}
                />
                <span className="invalid-form">
                  {errors?.extra?.responsavel?.mobile}
                </span>
              </div>

              <div className="col-lg-12 col-md-12">
                <label htmlFor="">E-mail Responsavel</label>
                <input
                  name="extra.responsavel.email"
                  type="email"
                  className={`form-control ${
                    errors?.extra?.responsavel?.email ? "invalid-form" : ""
                  }`}
                  onChange={handleChange}
                />
                <span className="invalid-form">
                  {errors?.extra?.responsavel?.email}
                </span>
              </div>

              <div className="col-lg-12 col-md-12">
                <label htmlFor="">Senha</label>
                <input
                  name="password"
                  type="password"
                  className={`form-control ${
                    errors.password ? "invalid-form" : ""
                  }`}
                  onChange={handleChange}
                />
                <span className="invalid-form">{errors.password}</span>
              </div>
              <div className="col-lg-12 col-md-12">
                <label htmlFor="">Confirmação senha</label>
                <input
                  name="confirmPassword"
                  type="password"
                  onChange={handleChange}
                  className={`form-control ${
                    errors.confirmPassword ? "invalid-form" : ""
                  }`}
                />
                <span className="invalid-form">{errors.confirmPassword}</span>
              </div>
              <div className="col-lg-12 col-md-12">
                <label htmlFor="">
                  <input
                    name="extra.infosSebraeSP"
                    type="checkbox"
                    onChange={handleChange}
                  />
                  &#160; Aceito receber informações do SEBRAE
                </label>
                <br />
              </div>
              <div className="col-lg-12 col-md-12 text-right">
                {!this.props.terms && (
                  <button type="submit" className="btn btn-primary">
                    Próximo
                  </button>
                )}

                {this.props.terms && (
                  <button
                    type="button"
                    className="btn btn-primary"
                    onClick={() => this.props.send()}
                  >
                    Enviar
                  </button>
                )}
              </div>
            </div>
          </form>
        )}
      </Formik>
    );
  }
}

export default FormUnderage;
