import React from "react";
import lax from "lax.js";
import LaxDiv from "../Shared/LaxDiv";
import api from "../../services/api";
import { getByCep, getAlternativeCep } from "../../services/api-sebrae-address";
import MaskedInput from "react-text-mask";
import * as yup from "yup";
import { Field, Form, Formik } from "formik";
import Keycloak from "keycloak-js";

import ModalTerms from "../Modal/ModalTerms";
import ModalPrivacy from "../Modal/ModalPrivacy";
import { postByCpf, getByCpf } from "../../services/api-sebrae-pf";
import { getByCnpj, postByCnpj } from "../../services/api-sebrae-pj";
import Load from "../load/Load";
import FormDefault from "./FormDefault";
import FromEnterprise from "./FromEnterprise";
import FormEvent from "./FormEvent";
import { DateTime } from "luxon";
import AuthorizationList from "./AuthorizationList";
import FormGetUser from "./FormGetUser";

let userRae = undefined;
let userEmpresa = undefined;

class About extends React.Component {
  state = {
    step: 0,
    formFields: { extra: { empresa: {} } },
    hasSebrae: false,
    lgpd: false,
    submitted: false,
    submitError: false,
    selectedOptions: [],
    isForeign: false,
    isUnderage: false,
    userSebrae: {},
    hasUserInFunifier: null,
    userFromAmei: null,
    hasUserInSebrae: null,
    enrollCompleted: null,
    cnae: [],
    empresa: "",
    newEnterprise: false,
    enterprises: [],
    isLoading: false,
    terms: false,
    event: [],
    userAmei: {},
    userName: "",
    eventList: [],
    partners: [],
    isApiOn: false,
    dataUserAmei: null,
    isAmeiOff: false,
  };

  constructor(props) {
    super(props);
    lax.setup();

    document.addEventListener(
      "scroll",
      function (x) {
        lax.update(window.scrollY);
      },
      false
    );

    lax.update(window.scrollY);
  }

  async componentDidMount() {
    const getGlobal = async () => {
      const global = await api.get("global", {
        headers: {
          Authorization:
            "Basic " +
            window.btoa(
              process.env.REACT_APP_APIKEY +
                ":" +
                process.env.REACT_APP_GLOBAL_SECRETKEY
            ),
        },
      });

      this.setState({ global: global.data });

      if (global.data?.register?.openId.active) {
        const keycloak = Keycloak({
          url: global.data?.register?.openId?.authServerUrl,
          realm: global.data?.register?.openId?.realm,
          clientId: global.data?.register?.openId?.clientIdInscricao,
          redirectUri: global.data?.register?.openId?.redirectInscricaoUrl,
        });

        await keycloak.init({
          redirectUri: global.data?.register?.openId?.redirectInscricaoUrl,
          onLoad: global.data?.register?.openId?.onLoad,
        });
        
        if (keycloak && keycloak.authenticated) {
          this.setState({ isLoading: true });

          const authenticatedUser = await keycloak.loadUserInfo();
          localStorage.setItem("Auth_Token", keycloak.token)
          
          this.setState({
            dataUserAmei: authenticatedUser,
            userName: authenticatedUser.name,
            isLoading: false,
          });

          this.searchCPF(authenticatedUser.cpf);
        }
      } else {
        this.setState({ isAmeiOff: true });
      }
    };

    getGlobal();

    let search = window.location.search;
    let params = new URLSearchParams(search);
    let token = params.get("token");

    if (token) {
      try {
        const authToken =
          "Basic " +
          window.btoa(
            `${process.env.REACT_APP_APIKEY}:${process.env.REACT_APP_GLOBAL_SECRETKEY}`
          );

        const res = await api.get(
          `/database/registration_temp__c?q='_id':'${token}'`,
          {
            headers: {
              Authorization: authToken,
            },
          }
        );

        this.setState({ userName: res.data[0].name });

        this.searchCPF(res.data[0].cpf);
      } catch (error) {
        console.log("error =>", error);
      }
    }
  }

  searchCPF = async (cpf) => {
    if (cpf) {
      try {
        const data = await getByCpf(cpf.replace(/[^\d]+/g, ""));
        if (data && data.data) this.setState({ userAmei: data.data });
      } catch (error) {
        console.log("erro ao buscar cpf", error);
      }
    }
  };

  schemaPart2 = yup.object().shape({
    extra: yup.object().shape({
      mobile: yup
        .string()
        .required(`Celular é obrigatório`)
        .test("is-valid", `Número inválido.`, (value) => {
          if (value && value[4] && value[4] === "9" && value.length === 14) {
            return true;
          }

          return false;
        }),
      cep: yup.string().required(`Cep é obrigatório`),
      numero: yup.string().required(`Número é obrigatório`),
      tipoLogradouro: yup.string().required(`Tipo de logradouro é obrigatório`),
    }),
  });

  onInputChange = (selected) => {
    this.setState({ submitted: false, submitError: false });

    let arr = [];
    this.setState({ selectedOptions: selected });

    for (let i = 0; i < selected.length; i++) {
      const element = selected[i];
      arr.push(element.value);
    }

    if (selected.length === 0) {
      arr = [];
    }
  };

  handleForm = async (e, alternative) => {
    let user = { ...this.state.formFields };
    const allEvents = [...this.state.eventList];

    user.extra.terms = true;
    user.extra.privacy = true;

    user.extra.events = allEvents.map((e) => {
      e.isEnrolled = this.state.event.find((item) => item === e._id);
      return e;
    });

    if (e) {
      if (e.objetivo) user.extra.objetivo = e.objetivo;
      if (e.segmentoExpositores)
        user.extra.segmentoExpositores = e.segmentoExpositores;
      if (e.idInvestimento) user.extra.idInvestimento = e.idInvestimento;
      if (e.dadosParceiros) user.extra.dadosParceiros = e.dadosParceiros;
    }

    if (!this.state.isUnderage && !this.state.isForeign) {
      user._id = user.extra.cpf.replace(/[^\d]+/g, "");
      user.extra.type = "normal";
    } else {
      user._id = user.email;

      if (this.state.isForeign) {
        user.extra.type = "estrangeiro";
      } else {
        user.extra.type = "menor";
      }
    }

    this.setState({ isLoading: true });

    if (user.extra && user.extra.telefone)
      user.extra.telefone = user.extra.telefone.replace(/[^\d]+/g, "");

    if (user.extra && user.extra.cpf)
      user.extra.cpf = user.extra.cpf.replace(/[^\d]+/g, "");

    if (user.extra?.empresa?.cnpj)
      user.extra.empresa.cnpj = user.extra.empresa.cnpj.replace(/[^\d]+/g, "");

    let userFromAmei;

    if (this.state.isApiOn)
      userFromAmei = await getByCpf(user.extra.cpf?.replace(/[^\d]+/g, ""));

    if (
      (!this.state.isUnderage &&
        !this.state.isForeign &&
        !this.state.enrollCompleted) ||
      this.state.hasSebrae
    ) {
      if (user.extra && user.extra.empresa && user.extra.empresa.cnpj) {
        let userSebraePJ = {
          CNPJ: user.extra.empresa.cnpj?.replace(/[^\d]+/g, ""),
          Celular: user.extra.empresa.mobile?.replace(/[^\d]+/g, ""),
          Email: user.extra.empresa.email,
          IdSituacao: 1,
          InconsistenciaTelefone: false,
          Justificativa: null,
          Rastreablidade: null,
          SemEmail: false,
          SincronizarNA: false,
          Telefone: user.extra.empresa.phone?.replace(/[^\d]+/g, ""),
          Tipo: "PJ",
          CdDap: user?.extra?.empresa?.dap ? user?.extra?.empresa?.dap : null,
          CdSICAB: user?.extra?.empresa?.sicab ? user?.extra?.empresa?.sicab : null,
          CdProdutorRural: null,
          CdRgp: user?.extra?.empresa?.rgp ? user?.extra?.empresa?.rgp : null,
          Cnaes: [user.extra.empresa.cnae],
          DataAbertura: `/Date(${new Date(
            user.extra.empresa.dataAbertura
          ).getTime()}-0300)/`,
          DataInativacao: null,
          IdCnaePrincipal: user.extra.empresa.cnae,
          IdPessoaJuridicaTipo: 1,
          IdPorte: user.extra.empresa.porte,
          InconsistenciaCnae: false,
          InconsistenciaDataAbertura: false,
          InconsistenciaSetor: false,
          InformacaoSobreNegocio: " ",
          InscricaoEstadual: user?.extra?.empresa?.inscricaoEstadual ? user?.extra?.empresa?.inscricaoEstadual : null,
          NIRF: user?.extra?.empresa?.nirf ? user?.extra?.empresa?.nirf : null,
          NomeFantasia: user.extra.empresa.nomeFantasia,
          RazaoSocial: user.extra.empresa.razaoSocial,
          Setores: user.extra.empresa.nomeSetores,
          Website: null,
          Endereco: {
            Bairro: user.extra.empresa.bairro,
            CEP: user.extra.empresa.cep,
            Complemento: user.extra.empresa.complement,
            IdCidade: user.extra.empresa.idCidade,
            IdPais: "BR",
            IdTipoLogradouro: user.extra.empresa.idTipoLogradouro,
            Logradouro: user.extra.empresa.logradouro,
            Numero: user.extra.empresa.numero,
            UF: user.extra.empresa.uf,
            Cidade: user.extra.empresa.cidade,
            TipoLogradouro: user.extra.empresa.tipoLogradouro,
          },
          PessoasFisicasVinculadas: [
            {
              Departamento: "",
              InconsistenciaDepartamento: false,
              Rastreabilidade: null,
              IdCargo: user.extra.empresa.cargo,
              IdPessoaFisica: userFromAmei?.data?.IdPessoa,
            },
          ],
        };

        userEmpresa = userSebraePJ;

        if (this.state.isApiOn) await postByCnpj(userSebraePJ);
      }
    }

    let resCNPJ;

    if (user?.extra?.empresa?.cnpj && this.state.isApiOn) {
      resCNPJ = await getByCnpj(user.extra.empresa.cnpj.replace(/[^\d]+/g, ""));
    }

    let userFromFunifier;
    if (user?.extra?.cpf) {
      userFromFunifier = await api.post(
        `database/player/aggregate`,
        [
          {
            $match: {
              "extra.cpf": user.extra.cpf.replace(/[^\d]+/g, ""),
              "extra.idStand": { $exists: false },
            },
          },
        ],
        {
          headers: {
            Authorization:
              "Basic " +
              window.btoa(
                `${process.env.REACT_APP_APIKEY}:${process.env.REACT_APP_GLOBAL_SECRETKEY}`
              ),
          },
        }
      );
    }

    let events;

    if (
      userFromFunifier?.data &&
      userFromFunifier.data[0] &&
      userFromFunifier.data[0]?.extra?.events &&
      Array.isArray(userFromFunifier.data[0]?.extra?.events)
    ) {
      events = user.extra.events.filter((e) => {
        let resp = true;
        userFromFunifier.data[0].extra.events.forEach((el) => {
          if (e._id === el.idEvent && !e.isEnrolled) resp = false;
        });

        return resp;
      });
    }

    const eventsFiltered = events ? events : user.extra.events;

    const eventObject = eventsFiltered.map((e) => {
      e = {
        idEvent: e._id,
        type: "PF",
        idPessoaFisica: userFromAmei?.data?.IdPessoa,
        dtCadastro: DateTime.fromJSDate(new Date()).toFormat("dd/MM/yyyy"),
        hrCadastro: DateTime.fromJSDate(new Date()).toFormat("HH:mm"),
        isEnrolled: e.isEnrolled ? true : false,
        sendMail: e.isEnrolled ? true : false,
      };

      if (resCNPJ) {
        e.type = "PJ";
        e.idPessoaJuridica = resCNPJ.IdPessoa;
      }

      this.state.partners.forEach((el) => {
        if (el === e.idEvent) {
          e.acceptTermShare = true;
        }
      });

      return e;
    });

    let eventPlayer;
    if (
      userFromFunifier &&
      userFromFunifier.data[0] &&
      userFromFunifier.data[0]?.extra?.events &&
      Array.isArray(userFromFunifier.data[0]?.extra?.events)
    ) {
      eventPlayer = userFromFunifier.data[0].extra.events.filter((e) => {
        let resp = true;
        this.state.event.forEach((el) => {
          if (e.idEvent === el) resp = false;
        });

        return resp;
      });
    }

    if (
      userFromFunifier &&
      userFromFunifier?.data &&
      userFromFunifier.data[0]
    ) {
      user.extra.events =
        userFromFunifier &&
        userFromFunifier.data[0] &&
        userFromFunifier.data[0]?.extra?.events &&
        Array.isArray(userFromFunifier.data[0]?.extra?.events)
          ? eventPlayer.concat(eventObject)
          : eventObject;
      user.extra.emailRae = user.extra.emailRae && user.extra.emailRae;
      user.extra.empresa = userEmpresa;
      user.extra.userRae = userRae;
      user.extra.api_rae_synchronized = userFromFunifier.data[0]?.extra
        ?.idPessoa
        ? true
        : false;

      if (!userFromFunifier.data[0]?.extra?.cidade) {
        let hasCep;
        if (this.state.isApiOn) hasCep = await getByCep(userFromFunifier.data[0].extra.cep);
        else hasCep = await getAlternativeCep(userFromFunifier.data[0].extra.cep);

        if (hasCep) {
          user.extra.cidade = hasCep.Cidade;
        }
      }

      user._id = userFromFunifier.data[0]._id;

      await api.put("player/" + userFromFunifier.data[0]._id, user, {
        headers: {
          Authorization:
            "Basic " +
            window.btoa(
              process.env.REACT_APP_APIKEY +
                ":" +
                process.env.REACT_APP_PUBLIC_SECRETKEY
            ),
        },
      });
    } else {
      user.extra.events = eventObject;
      user.extra.empresa = userEmpresa;
      if (userFromAmei?.data?.IdPessoa)
        user.extra.IdPessoa = userFromAmei?.data?.IdPessoa;
      user.extra.userRae = userRae;
      user.extra.api_rae_synchronized = this.state.isApiOn ? true : false;

      await api.post("database/registration__c", user, {
        headers: {
          Authorization:
            "Basic " +
            window.btoa(
              process.env.REACT_APP_APIKEY +
                ":" +
                process.env.REACT_APP_PUBLIC_SECRETKEY
            ),
        },
      });
    }

    this.setState({ isLoading: false });

    this.setState({ submitted: alternative ? "alternative" : "normal" });

    this.setState({ step: 1 });
    document.getElementById("contactForm") &&
      document.getElementById("contactForm").reset();
  };

  handleUserRae = async (e, dontChangeStep) => {
    this.setState({ isLoading: true });
    let user = { ...this.state.formFields };

    const pessoasJuridicas = user.extra?.pessoasJuridicas
      ? user.extra.pessoasJuridicas
      : [];

    if (e) {
      pessoasJuridicas.push(e);
    }

    let userSebraePF = {
      Celular: user.extra.mobile?.replace(/[^\d]+/g, ""),
      Telefone: user.extra.phone?.replace(/[^\d]+/g, ""),
      Email: user.email,
      IdEscolaridade: 1,
      Endereco: {
        Bairro: user.extra.bairro,
        CEP: user.extra.cep,
        Complemento: user.extra.complement,
        IdCidade: user.extra.idCidade,
        IdTipoLogradouro: user.extra.idTipoLogradouro,
        Logradouro: user.extra.logradouro,
        Numero: user.extra.numero,
        UF: user.extra.uf,
        IdPais: "BR",
      },
      IdPais: "BR",
      IdSituacao: 1,
      SemEmail: false,
      Tipo: "PF",
      CPF: user.extra.cpf?.replace(/[^\d]+/g, ""),
      DataNascimento: `/Date(${new Date(
        user.extra.data_nascimento
      ).getTime()}-0300)/`,
      Nome: user.name,
      ReceberInformacoesPorEmail: false,
      ReceberInformacoesPorSMS: false,
      ReceberInformacoesPorTelefone: false,
      Sexo: user.extra.sexo,
      PessoasJuridicasVinculadas:
        pessoasJuridicas && pessoasJuridicas[0] ? pessoasJuridicas : [],
    };

    if (!this.state.isApiOn) {
      userRae = userSebraePF;
      this.setState({ isLoading: false });

      if (dontChangeStep) return;

      this.changeStep();
      return;
    }

    if (user.extra.idPessoa) {
      userSebraePF.IdPessoa = user.extra.idPessoa;
    }

    await postByCpf(userSebraePF);
    this.setState({ isLoading: false });

    if (dontChangeStep) return;

    this.changeStep();
  };

  onHideSuccess = () => {
    this.setState({ submitted: false });
  };

  successMessage = () => {
    if (this.state.submitted) {
      return (
        <div className="text-left">
          {!this.state.userName && this.state.formFields.name && (
            <h4>
              Bem-vindo(a) ao Sebrae Experience, {this.state.formFields.name}!
            </h4>
          )}
          <h4>
            Que ótimo ter você conosco! Sua inscrição foi realizada com sucesso!
          </h4>
          {this.state.submitted === "normal" ? (
            <>
              <p className="font-weight-normal text-dark">
                Em breve você receberá um e-mail de confirmação de inscrição do
                evento. Caso não localize na sua caixa de entrada, verifique sua
                caixa de SPAM.
              </p>
              <p className="font-weight-normal text-dark">
                Este evento será realizado no Sebrae Experience, plataforma
                virtual de eventos do Sebrae! Aproveite e não deixe de conhecer
                os demais espaços.
              </p>
              <p className="font-weight-normal text-dark">
                Você pode falar conosco no Atendimento Sebrae, conferir os
                conteúdos exclusivos disponíveis para você no Acervo e visitar o
                Espaço de Crédito. Não esqueça de passar no Networking e deixar
                lá seu cartão de visitas!
              </p>
              <p className="font-weight-normal text-dark">
                Ao acessar o Sebrae Experience, você participa do evento,
                clicando no ícone da <b>"Programação"</b> localizado na barra
                fixa ou navegando até a <b>"Arena do Conhecimento"</b>.
              </p>
              <p className="font-weight-normal text-dark">
                Para acessar o <b>Sebrae Experience</b> clique no link{" "}
                {this.state?.global?.url && (
                  <a
                    href={
                      this.state.global.url +
                      `${
                        this.state.isUnderage || this.state.isForeign
                          ? ""
                          : !this.state.isApiOn
                          ? ""
                          : ""
                      }`
                    }
                    title={this.state?.global?.title}
                    alt={this.state?.global?.title}
                    rel="noopener noreferrer"
                  >
                    {this.state.global.url.replace(/(^\w+:|^)\/\//, "")}
                  </a>
                )}
              </p>

              <p className="font-weight-normal text-dark">
                Caso tenha dúvidas ou dificuldade para acessar o evento e/ou a
                plataforma do Sebrae Experience, entre em contato com a Central
                de Atendimento do Sebrae, pelo telefone{" "}
                <a href="tel+0800 570 0800">
                  <b>0800 570 0800</b>
                </a>{" "}
                ou se preferir, pelos nossos canais digitais{" "}
                <a
                  href="http://www.sebrae.com.br/sites/PortalSebrae/faleconosco"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <b>clicando aqui.</b>
                </a>
              </p>
            </>
          ) : (
            <>
              <p className="text-ends text-dark mt-4">
                Acesse o Sebrae Experience e confira todos os conteúdos e os
                eventos disponíveis que você se inscreveu através do link ou no
                botão abaixo
                <br />
                {this.state?.global?.url && (
                  <a
                    className="text-ends mt-1"
                    href={
                      this.state.global.url +
                      `${
                        this.state.isUnderage || this.state.isForeign
                          ? ""
                          : !this.state.isApiOn
                          ? ""
                          : ""
                      }`
                    }
                    title={this.state?.global?.title}
                    alt={this.state?.global?.title}
                    rel="noopener noreferrer"
                  >
                    {this.state.global.url.replace(/(^\w+:|^)\/\//, "")}
                  </a>
                )}
              </p>

              {this.state?.global?.url && (
                <div className="d-flex justify-content-center">
                  <a
                    className="text-ends mt-1 button-link"
                    href={
                      this.state.global.url +
                      `${
                        this.state.isUnderage || this.state.isForeign
                          ? ""
                          : !this.state.isApiOn
                          ? ""
                          : ""
                      }`
                    }
                    title={this.state?.global?.title}
                    alt={this.state?.global?.title}
                    rel="noopener noreferrer"
                  >
                    Acessar o Sebrae Experience
                  </a>
                </div>
              )}

              <p className="text-help font-weight-normal text-dark">
                Caso tenha dúvidas ou dificuldade para acessar o evento e/ou a
                plataforma do Sebrae Experience, entre em contato com a Central
                de Atendimento do Sebrae, pelo telefone{" "}
                <a href="tel+0800 570 0800">
                  <b>0800 570 0800</b>
                </a>{" "}
                ou se preferir, pelos nossos canais digitais{" "}
                <a
                  href="http://www.sebrae.com.br/sites/PortalSebrae/faleconosco"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <b>clicando aqui.</b>
                </a>
              </p>
            </>
          )}

          {/* <b>
              Clique{" "}
              {this.state?.global?.url && (
                <a
                  href={this.state.global.url}
                  title={this.state?.global?.title}
                  alt={this.state?.global?.title}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  aqui
                </a>
              )}
            </b>{" "} */}
        </div>
      );
    }
  };

  errorMessage = () => {
    if (this.state.submitError) {
      return (
        <div
          className="alert alert-danger  alert-dismissible fade show"
          style={{ marginTop: "14px" }}
        >
          {this.state.submitError}
          <button
            type="button"
            className="close"
            onClick={() => this.setState({ submitError: false })}
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
      );
    }
  };

  changeStep = () => {
    if (this.state.step + 1 === 5) {
      this.handleForm();
    }

    if (this.state.step + 1 === 4) {
      let hasEvent = false;
      const event = this.state.eventList.filter(
        (e) => e.activeOptinSponsor === true
      );

      event.forEach((e) => {
        this.state.event.forEach((item) => {
          if (e._id === item) hasEvent = true;
        });
      });

      if (!hasEvent) this.handleForm();
    }

    if (
      this.state.step + 1 === 2 &&
      this.state.userSebrae &&
      this.state.userSebrae.CPF &&
      this.state.userAmei.Nome
    ) {
      this.setState({ step: 3 });
      if (this.state?.formFields?.extra?.idPessoa) this.handleUserRae("", true);
    } else {
      this.setState({ step: this.state.step + 1 });
    }
  };

  openModalTerms = () => {
    this.setState({ isOpenTerms: true });
  };

  openModalPrivacy = () => {
    this.setState({ isOpenPrivacy: true });
  };

  handleDataForm = (
    data,
    userSebrae = false,
    enterprises = false,
    hasSebrae
  ) => {
    if (hasSebrae) {
      this.setState({ hasSebrae: true });
    }

    if (this.state.step === 3) {
      this.setState({
        formFields: {
          ...this.state.formFields,
          extra: {
            ...this.state.formFields.extra,
            empresa: {
              ...this.state.formFields.extra.empresa,
              ...data.extra.empresa,
            },
          },
        },
      });

      this.changeStep();
    } else {
      this.setState({
        formFields: {
          ...this.state.formFields,
          ...data,
        },
      });
    }

    if (userSebrae) {
      this.setState({ userSebrae });
    }
    if (enterprises) {
      this.setState({ enterprises });
    }

    if (this.state.step === 1) {
      // RETIRADO ACEITE DE TERMOS/POLITICA DE PRIVACIDADE
      // this.openModalPrivacy();
      // this.openModalTerms();

      // DIRETO PARA O PASSO 2 SEM TERMOS
      this.setState({ isOpenTerms: false, terms: true });
      this.setState({ isOpenPrivacy: false });

      if (this.state.isUnderage || this.state.isForeign) {
        this.setState({ step: 3 });
        this.changeStep();
      } else {
        this.changeStep();
      }
    }
  };

  searchAddress = async (cep, setFieldValue) => {
    if (cep) {
      try {
        let hasCep;
        if (this.state.isApiOn) hasCep = await getByCep(cep);
        else hasCep = await getAlternativeCep(cep);

        if (hasCep) {
          this.setState({
            formFields: {
              ...this.state.formFields,
              extra: {
                ...this.state.formFields.extra,
                bairro: hasCep.Bairro,
                cep: hasCep.CEP,
                cidade: hasCep.Cidade,
                idCidade: hasCep.IdCidade,
                idTipoLogradouro: hasCep.IdTipoLogradouro,
                logradouro: hasCep.Logradouro,
                tipoLogradouro: hasCep.TipoLogradouro,
                uf: hasCep.UF,
              },
            },
          });

          setFieldValue("extra.bairro", hasCep.Bairro);
          setFieldValue("extra.cep", hasCep.CEP);
          setFieldValue("extra.cidade", hasCep.Cidade);
          setFieldValue("extra.logradouro", hasCep.Logradouro);
          setFieldValue(
            "extra.tipoLogradouro",
            hasCep.TipoLogradouro?.toUpperCase()
          );
          setFieldValue("extra.uf", hasCep.UF);
        }
      } catch (error) {
        console.log("erro ao buscar cep", error);
      }
    }
  };

  render() {
    return (
      <React.Fragment>
        <ModalTerms
          isOpenTerms={this.state.isOpenTerms}
          close={() => this.setState({ isOpenTerms: false })}
          onConfirm={() => {
            this.setState({ isOpenTerms: false, terms: true });
            if (!this.state.isForeign && !this.state.isUnderage) {
              this.changeStep();
            }
          }}
        />
        <ModalPrivacy
          isOpenPrivacy={this.state.isOpenPrivacy}
          close={() => {
            this.setState({ isOpenPrivacy: false, isOpenTerms: false });
            if (!this.state.isForeign && !this.state.isUnderage) {
              this.changeStep();
            }
          }}
          onConfirm={() => this.setState({ isOpenPrivacy: false })}
        />

        <section id="enrollment" className="pricing-area ptb-120 bg-image">
          <div className="container">
            <div className="section-title">
              <span>Garanta a sua vaga</span>
              <h2>Inscreva-se</h2>

              <LaxDiv text="Inscreva-se" dataPreset="driftLeft" />

              <div className="bar"></div>
            </div>
            <div className="mx-auto col-lg-11 col-md-12">
              {this.props.global.registerForm && (
                <>
                  {this.state.userName && (
                    <h4>Bem-vindo(a), {this.state.userName}!</h4>
                  )}
                  {!this.state.submitted &&
                    this.state.step === 1 &&
                    !this.state.userName && (
                      <label className="mb-3">
                        {this.props.global.txtInscricao}
                      </label>
                    )}
                  {!this.state.submitted &&
                    this.state.step === 1 &&
                    this.state.userName && (
                      <label className="mb-3">
                        Confirme seu endereço do email para receber as
                        informações sobre o evento. Você receberá um email após
                        a inscrição. Solicitamos que verifique também na sua
                        caixa de spam.
                      </label>
                    )}
                  {!this.state.submitted && this.state.step === 2 && (
                    <label className="mb-3">
                      {this.props.global.txtInscricao}
                    </label>
                  )}
                  {!this.state.submitted && this.state.step === 3 && (
                    <label className="mb-3">
                      Nesta etapa, selecione abaixo como prefere se inscrever.
                    </label>
                  )}
                  {!this.state.submitted && this.state.step === 4 && (
                    <label className="mb-3">
                      Falta pouco para você finalizar sua inscrição!
                    </label>
                  )}

                  <div id="msgSubmit" className="h3 text-center">
                    {this.successMessage()}
                  </div>
                  <div id="msgSubmit" className="h3 text-center">
                    {this.errorMessage()}
                  </div>

                  {this.state.isLoading && <Load />}

                  {this.state.step === 0 &&
                    this.state.dataUserAmei &&
                    !this.state.isAmeiOff && (
                      <FormEvent
                        dataAmei={this.state.dataUserAmei}
                        global={this.state.global}
                        sendForm={(e) => {
                          this.setState({
                            event: e[0],
                            eventList: e[1],
                            step: 1,
                          });
                        }}
                        update={(e, data) => {
                          this.setState({
                            event: e[0],
                            eventList: e[1],
                            formFields: data,
                            step: 7,
                          });
                          this.handleForm(false, true);
                        }}
                      />
                    )}

                  {this.state.step === 0 && this.state.isAmeiOff && (
                    <FormGetUser
                      sendForm={(e) => {
                        this.setState({
                          dataUserAmei: e?.name ? e : {},
                          isAmeiOff: false,
                          userName: e?.name,
                          formFields: {
                            ...this.state.formFields,
                            extra: {
                              ...this.state.formFields.extra,
                              cpf: e.cpf
                            },
                          },
                        });
                      }}
                    />
                  )}

                  {!this.state.submitted && !this.state.isLoading && (
                    <>
                      {this.state.step === 1 && (
                        <>
                          <FormDefault
                            cpf={this.state?.formFields?.extra?.cpf}
                            terms={this.state.terms}
                            events={this.state.event}
                            dataAmei={this.state.dataUserAmei}
                            sendForm={(
                              e,
                              userSebrae,
                              enterprises,
                              hasSebrae,
                              hasUserInFunifier,
                              userFromAmei,
                              enrollCompleted,
                              isApiOn
                            ) => {
                              this.setState({
                                hasUserInFunifier,
                                userFromAmei,
                                enrollCompleted,
                                isApiOn,
                              });
                              this.handleDataForm(
                                e,
                                userSebrae,
                                enterprises,
                                hasSebrae
                              );
                            }}
                            send={(
                              hasUserInFunifier,
                              userFromAmei,
                              hasUserInSebrae,
                              enrollCompleted
                            ) => {
                              this.handleForm();
                            }}
                            goToSep={() => {
                              this.setState({ step: 4 });
                            }}
                          />
                        </>
                      )}

                      {this.state.step === 2 && (
                        <Formik
                          validationSchema={this.schemaPart2}
                          onSubmit={(values, actions) => {
                            this.setState({
                              formFields: {
                                ...this.state.formFields,
                                extra: {
                                  ...this.state.formFields.extra,
                                  ...values.extra,
                                },
                              },
                            });
                            setTimeout(() => {
                              this.handleUserRae();
                            }, 1000);
                          }}
                          initialValues={{
                            extra: {
                              infosSebraeSP: false,
                              phone: "",
                              mobile: "",
                              bairro: undefined,
                              cep: undefined,
                              cidade: undefined,
                              logradouro: undefined,
                              tipoLogradouro: undefined,
                              numero: undefined,
                              uf: undefined,
                              pais: undefined,
                            },
                          }}
                        >
                          {({
                            handleSubmit,
                            handleChange,
                            handleBlur,
                            setFieldTouched,
                            setFieldValue,
                            values,
                            touched,
                            isValid,
                            isValidating,
                            errors,
                          }) => (
                            <form id="contactForm" onSubmit={handleSubmit}>
                              <div className="row">
                                <div className="col-lg-12 col-md-12">
                                  <label htmlFor="">Telefone</label>
                                  <MaskedInput
                                    guide={false}
                                    keepCharPositions={false}
                                    showMask={false}
                                    mask={[
                                      "(",
                                      /\d/,
                                      /\d/,
                                      ")",
                                      /\d/,
                                      /\d/,
                                      /\d/,
                                      /\d/,
                                      "-",
                                      /\d/,
                                      /\d/,
                                      /\d/,
                                      /\d/,
                                    ]}
                                    type="text"
                                    className={`form-control ${
                                      errors.extra && errors.extra.phone
                                        ? "invalid-form"
                                        : ""
                                    }`}
                                    onChange={handleChange}
                                    name="extra.phone"
                                  />

                                  <span className="invalid-form">
                                    {errors.extra && errors.extra.phone}
                                  </span>
                                </div>
                                <div className="col-lg-12 col-md-12">
                                  <label htmlFor="">Celular*</label>
                                  <MaskedInput
                                    guide={false}
                                    keepCharPositions={false}
                                    showMask={false}
                                    mask={[
                                      "(",
                                      /\d/,
                                      /\d/,
                                      ")",
                                      /\d/,
                                      /\d/,
                                      /\d/,
                                      /\d/,
                                      /\d/,
                                      "-",
                                      /\d/,
                                      /\d/,
                                      /\d/,
                                      /\d/,
                                    ]}
                                    type="text"
                                    onChange={handleChange}
                                    name="extra.mobile"
                                    className={`form-control ${
                                      errors.extra && errors.extra.mobile
                                        ? "invalid-form"
                                        : ""
                                    }`}
                                  />
                                  <span className="invalid-form">
                                    {errors.extra && errors.extra.mobile}
                                  </span>
                                </div>
                                <div className="col-lg-12 col-md-12">
                                  <label htmlFor="">CEP*</label>
                                  <MaskedInput
                                    name="extra.cep"
                                    onChange={(event) => {
                                      handleChange(event);
                                    }}
                                    onBlur={(event) =>
                                      this.searchAddress(
                                        event.target.value,
                                        setFieldValue
                                      )
                                    }
                                    guide={false}
                                    keepCharPositions={false}
                                    showMask={false}
                                    mask={[
                                      /\d/,
                                      /\d/,
                                      /\d/,
                                      /\d/,
                                      /\d/,
                                      "-",
                                      /\d/,
                                      /\d/,
                                      /\d/,
                                    ]}
                                    type="text"
                                    className={`form-control ${
                                      errors.extra && errors.extra.cep
                                        ? "invalid-form"
                                        : ""
                                    }`}
                                  />
                                </div>
                                <div className="col-lg-12 col-md-12">
                                  <label htmlFor="">Tipo de logradouro</label>
                                  <select
                                    name="extra.tipoLogradouro"
                                    id="addressType"
                                    className={`form-control ${
                                      errors?.extra?.tipoLogradouro
                                        ? "invalid-form"
                                        : ""
                                    }`}
                                    onChange={handleChange}
                                    value={
                                      this.state?.formFields?.extra
                                        ?.tipoLogradouro
                                    }
                                  >
                                    <option value="">Selecione</option>
                                    <option value="AEROPORTO">AEROPORTO</option>
                                    <option value="ALAMEDA">ALAMEDA</option>
                                    <option value="AREA">ÁREA</option>
                                    <option value="AVENIDA">AVENIDA</option>
                                    <option value="CAMPO">CAMPO</option>
                                    <option value="CHACARA">CHÁCARA</option>
                                    <option value="COLONIA">COLÔNIA</option>
                                    <option value="CONDOMINIO">
                                      CONDOMÍNIO
                                    </option>
                                    <option value="CONJUNTO">CONJUNTO</option>
                                    <option value="DISTRITO">DISTRITO</option>
                                    <option value="ESPLANADA">ESPLANADA</option>
                                    <option value="ESTACAO">ESTAÇÃO</option>
                                    <option value="ESTRADA">ESTRADA</option>
                                    <option value="FAVELA">FAVELA</option>
                                    <option value="FAZENDA">FAZENDA</option>
                                    <option value="FEIRA">FEIRA</option>
                                    <option value="JARDIM">JARDIM</option>
                                    <option value="LADEIRA">LADEIRA</option>
                                    <option value="LAGO">LAGO</option>
                                    <option value="LAGOA">LAGOA</option>
                                    <option value="LARGO">LARGO</option>
                                    <option value="LOTEAMENTO">
                                      LOTEAMENTO
                                    </option>
                                    <option value="MORRO">MORRO</option>
                                    <option value="NÚCLEO">NÚCLEO</option>
                                    <option value="PARQUE">PARQUE</option>
                                    <option value="PASSARELA">PASSARELA</option>
                                    <option value="PATIO">PÁTIO</option>
                                    <option value="PRACA">PRAÇA</option>
                                    <option value="QUADRA">QUADRA</option>
                                    <option value="RECANTO">RECANTO</option>
                                    <option value="RESIDENCIAL">
                                      RESIDENCIAL
                                    </option>
                                    <option value="RODOVIA">RODOVIA</option>
                                    <option value="RUA">RUA</option>
                                    <option value="SETOR">SETOR</option>
                                    <option value="SITIO">SÍTIO</option>
                                    <option value="TRAVESSA">TRAVESSA</option>
                                    <option value="TRECHO">TRECHO</option>
                                    <option value="TREVO">TREVO</option>
                                    <option value="VALE">VALE</option>
                                    <option value="VEREDA">VEREDA</option>
                                    <option value="VIA">VIA</option>
                                    <option value="VIADUTO">VIADUTO</option>
                                    <option value="VIELA">VIELA</option>
                                    <option value="VILA">VILA</option>
                                  </select>

                                  <span className="invalid-form">
                                    {errors?.extra?.tipoLogradouro}
                                  </span>
                                </div>
                                <div className="col-lg-12 col-md-12">
                                  <label name="address" htmlFor="">
                                    Endereço
                                  </label>
                                  <input
                                    type="text"
                                    name="extra.logradouro"
                                    className={`form-control ${
                                      errors?.extra?.logradouro
                                        ? "invalid-form"
                                        : ""
                                    }`}
                                    onChange={handleChange}
                                    value={
                                      this?.state?.formFields?.extra?.logradouro
                                    }
                                  />
                                  <span className="invalid-form">
                                    {errors?.extra?.logradouro}
                                  </span>
                                </div>
                                <div className="col-lg-12 col-md-12">
                                  <label htmlFor="">Número*</label>
                                  <input
                                    onChange={handleChange}
                                    name="extra.numero"
                                    type="number"
                                    className={`form-control ${
                                      errors.extra && errors.extra.numero
                                        ? "invalid-form"
                                        : ""
                                    }`}
                                  />
                                  <span className="invalid-form">
                                    {errors.extra && errors.extra.numero}
                                  </span>
                                </div>
                                <div className="col-lg-12 col-md-12">
                                  <label htmlFor="">Complemento</label>
                                  <input
                                    name="extra.complement"
                                    type="text"
                                    onChange={handleChange}
                                    className={`form-control ${
                                      errors.extra && errors.extra.complement
                                        ? "invalid-form"
                                        : ""
                                    }`}
                                  />
                                  <span className="invalid-form">
                                    {errors.extra && errors.extra.complement}
                                  </span>
                                </div>
                                <div className="col-lg-12 col-md-12">
                                  <label htmlFor="">Bairro</label>
                                  <input
                                    type="text"
                                    className={`form-control ${
                                      errors.extra && errors.extra.bairro
                                        ? "invalid-form"
                                        : ""
                                    }`}
                                    value={
                                      this.state?.formFields?.extra?.bairro
                                    }
                                    name="extra.bairro"
                                    onChange={handleChange}
                                  />
                                  <span className="invalid-form">
                                    {errors.extra && errors.extra.bairro}
                                  </span>
                                </div>
                                <div className="col-lg-12 col-md-12">
                                  <label htmlFor="">UF</label>
                                  <select
                                    id="state"
                                    name="extra.uf"
                                    className={`form-control ${
                                      errors.extra && errors.extra.uf
                                        ? "invalid-form"
                                        : ""
                                    }`}
                                    onChange={handleChange}
                                    value={this?.state?.formFields?.extra?.uf}
                                  >
                                    <option value="">Selecione</option>
                                    <option value="AC">Acre</option>
                                    <option value="AL">Alagoas</option>
                                    <option value="AP">Amapá</option>
                                    <option value="AM">Amazonas</option>
                                    <option value="BA">Bahia</option>
                                    <option value="CE">Ceará</option>
                                    <option value="DF">Distrito Federal</option>
                                    <option value="ES">Espírito Santo</option>
                                    <option value="GO">Goiás</option>
                                    <option value="MA">Maranhão</option>
                                    <option value="MT">Mato Grosso</option>
                                    <option value="MS">
                                      Mato Grosso do Sul
                                    </option>
                                    <option value="MG">Minas Gerais</option>
                                    <option value="PA">Pará</option>
                                    <option value="PB">Paraíba</option>
                                    <option value="PR">Paraná</option>
                                    <option value="PE">Pernambuco</option>
                                    <option value="PI">Piauí</option>
                                    <option value="RJ">Rio de Janeiro</option>
                                    <option value="RN">
                                      Rio Grande do Norte
                                    </option>
                                    <option value="RS">
                                      Rio Grande do Sul
                                    </option>
                                    <option value="RO">Rondônia</option>
                                    <option value="RR">Roraima</option>
                                    <option value="SC">Santa Catarina</option>
                                    <option value="SP">São Paulo</option>
                                    <option value="SE">Sergipe</option>
                                    <option value="TO">Tocantins</option>
                                    <option value="EX">Estrangeiro</option>
                                  </select>
                                  <span className="invalid-form">
                                    {errors.extra && errors.extra.uf}
                                  </span>
                                </div>
                                <div className="col-lg-12 col-md-12">
                                  <label htmlFor="cidade">Cidade</label>
                                  <input
                                    type="text"
                                    className={`form-control ${
                                      errors.extra && errors.extra.cidade
                                        ? "invalid-form"
                                        : ""
                                    }`}
                                    name="extra.cidade"
                                    id="cidade"
                                    onChange={handleChange}
                                    value={
                                      this.state?.formFields?.extra?.cidade
                                    }
                                  />
                                  <span className="invalid-form">
                                    {errors.extra && errors.extra.cidade}
                                  </span>
                                </div>
                                <div className="mt-2 col-lg-12 col-md-12 text-right">
                                  <button
                                    type="submit"
                                    className="btn btn-primary"
                                  >
                                    Próximo
                                  </button>
                                </div>
                              </div>
                            </form>
                          )}
                        </Formik>
                      )}

                      {this.state.step === 3 && (
                        <>
                          <FromEnterprise
                            enterprises={this.state.enterprises}
                            isApiOn={this.state.isApiOn}
                            sendForm={(e) => this.handleDataForm(e)}
                            onConfirm={(e) => {
                              this.handleUserRae(e.pessoaJuridica, false);
                              this.setState({
                                formFields: {
                                  ...this.state.formFields,
                                  extra: {
                                    ...this.state.formFields.extra,
                                    empresa: {
                                      cnpj: e.cnpj,
                                    },
                                  },
                                },
                              });
                              this.setState({ enrollCompleted: true });
                            }}
                            changeStep={(e) => {
                              if (e) {
                                this.setState({
                                  formFields: {
                                    ...this.state.formFields,
                                    extra: {
                                      ...this.state.formFields.extra,
                                      empresa: {
                                        cnpj: e.CNPJ,
                                      },
                                    },
                                  },
                                });
                                this.setState({ enrollCompleted: true });
                              }

                              setTimeout(() => {
                                this.changeStep();
                              }, 100);
                            }}
                          />
                        </>
                      )}

                      {this.state.step === 4 && (
                        <AuthorizationList
                          event={this.state.event}
                          eventList={this.state.eventList}
                          sendForm={(e) => {
                            this.setState({ partners: e });
                            this.changeStep();
                          }}
                        />
                      )}

                      {this.state.step === 6 && (
                        <Formik
                          initialValues={{
                            objetivo: [],
                            segmentoExpositores: [],
                            idInvestimento: undefined,
                            dadosParceiros: undefined,
                          }}
                          onSubmit={(values, actions) => {
                            setTimeout(() => {
                              this.handleForm(values);
                            }, 1000);
                          }}
                        >
                          {(props) => (
                            <Form id="contactForm">
                              <div className="row">
                                <div className="col-12">
                                  <p>
                                    Conteúdos personalizados exclusivamente para
                                    você!
                                  </p>
                                  <p>
                                    A feira apresentará uma série de conteúdos e
                                    oportunidades de negócio, para melhorar sua
                                    experiência, responda as perguntas abaixo
                                    para customizar de acordo com suas
                                    preferências
                                  </p>
                                  <hr />
                                  <div className="well">
                                    <label htmlFor="objetivo">
                                      Quais são seus objetivos na Feira do
                                      Empreendedor?
                                    </label>
                                    <div>
                                      <label>
                                        <Field
                                          type="checkbox"
                                          name="objetivo"
                                          value="37"
                                        />
                                        &#160; Quero abrir minha empresa
                                      </label>
                                      <label>
                                        <Field
                                          type="checkbox"
                                          name="objetivo"
                                          value="38"
                                        />
                                        &#160; Quero controlar meu dinheiro
                                      </label>
                                      <label>
                                        <Field
                                          type="checkbox"
                                          name="objetivo"
                                          value="39"
                                        />
                                        &#160; Quero vender mais
                                      </label>
                                      <label>
                                        <Field
                                          type="checkbox"
                                          name="objetivo"
                                          value="40"
                                        />
                                        &#160; Quero minha empresa na internet
                                      </label>
                                      <label>
                                        <Field
                                          type="checkbox"
                                          name="objetivo"
                                          value="41"
                                        />
                                        &#160; Quero prosperar sendo MEI
                                      </label>
                                      <label>
                                        <Field
                                          type="checkbox"
                                          name="objetivo"
                                          value="42"
                                        />
                                        &#160; Quero ser mais competitivo no meu
                                        setor
                                      </label>
                                      <label>
                                        <Field
                                          type="checkbox"
                                          name="objetivo"
                                          value="43"
                                        />
                                        &#160; Quero expandir meu negócio
                                      </label>
                                      <label>
                                        <Field
                                          type="checkbox"
                                          name="objetivo"
                                          value="44"
                                        />
                                        &#160; Quero ser um empreendedor melhor
                                      </label>
                                      <label>
                                        <Field
                                          type="checkbox"
                                          name="objetivo"
                                          value="45"
                                        />
                                        &#160; Quero formar empreendedores
                                      </label>
                                      <label>
                                        <Field
                                          type="checkbox"
                                          name="objetivo"
                                          value="49"
                                        />
                                        &#160; Quero me recuperar da crise
                                      </label>
                                      <label>
                                        <Field
                                          type="checkbox"
                                          name="objetivo"
                                          value="50"
                                        />
                                        &#160; Quero saber sobre franquias
                                      </label>
                                      <label>
                                        <Field
                                          type="checkbox"
                                          name="objetivo"
                                          value="51"
                                        />
                                        &#160; Quero internacionalizar meu
                                        negócio
                                      </label>
                                      <label>
                                        <Field
                                          type="checkbox"
                                          name="objetivo"
                                          value="52"
                                        />
                                        &#160; Quero inovar
                                      </label>
                                    </div>
                                  </div>
                                  <hr />
                                  <div className="well">
                                    <label htmlFor="segmentoExpositores">
                                      As empresas expositoras da Feira do
                                      Empreendedor são focadas em alguns
                                      segmentos do mercado. Você possui
                                      interesse em algum deles?
                                    </label>
                                    <div>
                                      <label>
                                        <Field
                                          type="checkbox"
                                          name="segmentoExpositores"
                                          value="11"
                                        />
                                        &#160; Franquias
                                      </label>
                                    </div>
                                    <div>
                                      <label>
                                        <Field
                                          type="checkbox"
                                          name="segmentoExpositores"
                                          value="12"
                                        />
                                        &#160; Máquinas e Equipamentos
                                      </label>
                                    </div>
                                    <div>
                                      <label>
                                        <Field
                                          type="checkbox"
                                          name="segmentoExpositores"
                                          value="13"
                                        />
                                        &#160; Negócios Digitais
                                      </label>
                                    </div>
                                    <div>
                                      <label>
                                        <Field
                                          type="checkbox"
                                          name="segmentoExpositores"
                                          value="14"
                                        />
                                        &#160; Representações / Porta - a porta
                                      </label>
                                    </div>
                                    <div>
                                      <label>
                                        <Field
                                          type="checkbox"
                                          name="segmentoExpositores"
                                          value="15"
                                        />
                                        &#160; Serviços
                                      </label>
                                    </div>
                                    <div>
                                      <label>
                                        <Field
                                          type="checkbox"
                                          name="segmentoExpositores"
                                          value="20"
                                        />
                                        &#160; Agro
                                      </label>
                                    </div>
                                    <div>
                                      <label>
                                        <Field
                                          type="checkbox"
                                          name="segmentoExpositores"
                                          value="47"
                                        />
                                        &#160; Outros
                                      </label>
                                    </div>
                                    <div>
                                      <label>
                                        <Field
                                          type="checkbox"
                                          name="segmentoExpositores"
                                          value="48"
                                        />
                                        &#160; Nenhum
                                      </label>
                                    </div>
                                  </div>
                                  <hr />
                                  <div className="well">
                                    <label htmlFor="idInvestimento">
                                      Tem ideia de quanto pretende investir na
                                      abertura, melhoria ou mudança de seu
                                      negócio?
                                    </label>
                                    <div role="group">
                                      <label>
                                        <Field
                                          type="radio"
                                          name="idInvestimento"
                                          value="16"
                                        />
                                        &#160; Até 50 mil
                                      </label>
                                      <label>
                                        <Field
                                          type="radio"
                                          name="idInvestimento"
                                          value="17"
                                        />
                                        &#160; De 50 a 300 mil
                                      </label>
                                      <label>
                                        <Field
                                          type="radio"
                                          name="idInvestimento"
                                          value="18"
                                        />
                                        &#160; De 300 a 600 mil
                                      </label>
                                      <label>
                                        <Field
                                          type="radio"
                                          name="idInvestimento"
                                          value="19"
                                        />
                                        &#160; Acima de 600 mil
                                      </label>
                                      <label>
                                        <Field
                                          type="radio"
                                          name="idInvestimento"
                                          value="26"
                                        />
                                        &#160; Não tenho interesse em investir
                                      </label>
                                    </div>
                                  </div>
                                  <hr />
                                  <div className="well">
                                    <label htmlFor="dadosParceiros">
                                      Receba ofertas exclusivas de parceiros
                                    </label>
                                    <div>
                                      <label>
                                        <Field
                                          type="checkbox"
                                          name="dadosParceiros"
                                        />
                                        &#160; Autorizo o compartilhamento com
                                        os patrocinadores dos meus dados
                                        pessoais cadastrais, bem como os dados
                                        de histórico da minha navegação nos
                                        espaços promovidos pelo patrocinador a
                                        fim de permitir que os patrocinadores
                                        entrem em contato para ofertar produtos
                                        e serviços personalizados
                                      </label>
                                    </div>
                                    <div>
                                      <label>
                                        <a
                                          href="https://feiradoempreendedor.sebraesp.com.br/patrocinadores/"
                                          title="Conheça os patrocinadores da Feira do Empreendedor 2021"
                                          alt="Conheça os patrocinadores da Feira do Empreendedor 2021"
                                          className="pl-3"
                                          target="_blank"
                                          rel="noopener noreferrer"
                                        >
                                          Clique aqui
                                        </a>
                                        &#160;para conhecer os patrocinadores do
                                        evento
                                      </label>
                                    </div>
                                  </div>
                                </div>
                              </div>

                              <button type="submit" className="btn btn-primary">
                                Enviar
                              </button>
                            </Form>
                          )}
                        </Formik>
                      )}
                    </>
                  )}
                </>
              )}
            </div>
            {!this.props.global.register.email &&
              this.props.global.register.button && (
                <div>
                  <a
                    type="button"
                    className="btn btn-primary"
                    href={this.props.global.register.linkRegistrationButton}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {this.props.global.register.textRegistrationButton}
                  </a>
                </div>
              )}
          </div>
        </section>
      </React.Fragment>
    );
  }
}

export default About;
