import React from "react";
import lax from "lax.js";
import LaxDiv from "../Shared/LaxDiv";
import api from "../../services/api";

import Load from "../load/Load";
import FromForeign from "./FromForeign";
import FormUnderage from "./FromUnderage";
import FormEventSecondary from "./FormEventSecondary";
import { DateTime } from "luxon";
import AuthorizationList from "./AuthorizationList";

let userRae = undefined;
let userEmpresa = undefined;

class About extends React.Component {
  state = {
    step: 0,
    formFields: { extra: { empresa: {} } },
    hasSebrae: false,
    lgpd: false,
    submitted: false,
    submitError: false,
    selectedOptions: [],
    isForeign: false,
    isUnderage: false,
    userSebrae: {},
    hasUserInFunifier: null,
    userFromAmei: null,
    hasUserInSebrae: null,
    enrollCompleted: null,
    cnae: [],
    empresa: "",
    newEnterprise: false,
    enterprises: [],
    isLoading: false,
    terms: false,
    event: [],
    userAmei: {},
    userName: "",
    eventList: [],
    partners: [],
    isApiOn: false,
    dataUserAmei: null,
    isAmeiOff: false,
  };

  constructor(props) {
    super(props);
    lax.setup();

    document.addEventListener(
      "scroll",
      function (x) {
        lax.update(window.scrollY);
      },
      false
    );

    lax.update(window.scrollY);
  }

  async componentDidMount() {
    const getGlobal = async () => {
      const global = await api.get("global", {
        headers: {
          Authorization:
            "Basic " +
            window.btoa(
              process.env.REACT_APP_APIKEY +
                ":" +
                process.env.REACT_APP_GLOBAL_SECRETKEY
            ),
        },
      });

      this.setState({ global: global.data });

      this.setState({ isAmeiOff: true });
    };

    getGlobal();
  }

  handleForm = async (e, alternative) => {
    let user = { ...this.state.formFields };
    const allEvents = [...this.state.eventList];

    user.extra.terms = true;
    user.extra.privacy = true;

    user.extra.events = allEvents.map((e) => {
      e.isEnrolled = this.state.event.find((item) => item === e._id);
      return e;
    });

    if (e) {
      if (e.objetivo) user.extra.objetivo = e.objetivo;
      if (e.segmentoExpositores)
        user.extra.segmentoExpositores = e.segmentoExpositores;
      if (e.idInvestimento) user.extra.idInvestimento = e.idInvestimento;
      if (e.dadosParceiros) user.extra.dadosParceiros = e.dadosParceiros;
    }

    if (!this.state.isUnderage && !this.state.isForeign) {
      user._id = user.extra.cpf.replace(/[^\d]+/g, "");
      user.extra.type = "normal";
    } else {
      user._id = user.email;

      if (this.state.isForeign) {
        user.extra.type = "estrangeiro";
      } else {
        user.extra.type = "menor";
      }
    }

    this.setState({ isLoading: true });

    if (user.extra && user.extra.telefone)
      user.extra.telefone = user.extra.telefone.replace(/[^\d]+/g, "");

    if (user.extra && user.extra.cpf)
      user.extra.cpf = user.extra.cpf.replace(/[^\d]+/g, "");

    if (user.extra?.empresa?.cnpj)
      user.extra.empresa.cnpj = user.extra.empresa.cnpj.replace(/[^\d]+/g, "");

    let userFromFunifier;
    if (user._id) {
      userFromFunifier = await api.post(
        `database/player/aggregate`,
        [
          {
            $match: {
              _id: user._id,
            },
          },
        ],
        {
          headers: {
            Authorization:
              "Basic " +
              window.btoa(
                `${process.env.REACT_APP_APIKEY}:${process.env.REACT_APP_GLOBAL_SECRETKEY}`
              ),
          },
        }
      );
    }

    let events;

    if (userFromFunifier?.data && userFromFunifier.data[0]) {
      return this.setState({ submitError: true, isLoading: false });
    }

    const eventsFiltered = events ? events : user.extra.events;

    const eventObject = eventsFiltered.map((e) => {
      e = {
        idEvent: e._id,
        type: "PF",
        dtCadastro: DateTime.fromJSDate(new Date()).toFormat("dd/MM/yyyy"),
        hrCadastro: DateTime.fromJSDate(new Date()).toFormat("HH:mm"),
        isEnrolled: e.isEnrolled ? true : false,
        sendMail: e.isEnrolled ? true : false,
      };

      this.state.partners.forEach((el) => {
        if (el === e.idEvent) {
          e.acceptTermShare = true;
        }
      });

      return e;
    });

    let eventPlayer;
    if (
      userFromFunifier &&
      userFromFunifier.data[0] &&
      userFromFunifier.data[0]?.extra?.events &&
      Array.isArray(userFromFunifier.data[0]?.extra?.events)
    ) {
      eventPlayer = userFromFunifier.data[0].extra.events.filter((e) => {
        let resp = true;
        this.state.event.forEach((el) => {
          if (e.idEvent === el) resp = false;
        });

        return resp;
      });
    }

    if (
      userFromFunifier &&
      userFromFunifier?.data &&
      userFromFunifier.data[0]
    ) {
      user.extra.events =
        userFromFunifier &&
        userFromFunifier.data[0] &&
        userFromFunifier.data[0]?.extra?.events &&
        Array.isArray(userFromFunifier.data[0]?.extra?.events)
          ? eventPlayer.concat(eventObject)
          : eventObject;
      user.extra.emailRae = user.extra.emailRae && user.extra.emailRae;
      user.extra.empresa = userEmpresa;
      user.extra.userRae = userRae;
      user.extra.api_rae_synchronized = userFromFunifier.data[0]?.extra
        ?.idPessoa
        ? true
        : false;

      await api.put("player/" + userFromFunifier.data[0]._id, user, {
        headers: {
          Authorization:
            "Basic " +
            window.btoa(
              process.env.REACT_APP_APIKEY +
                ":" +
                process.env.REACT_APP_PUBLIC_SECRETKEY
            ),
        },
      });
    } else {
      user.extra.events = eventObject;
      user.extra.empresa = userEmpresa;
      user.extra.userRae = userRae;

      await api.post("database/registration__c", user, {
        headers: {
          Authorization:
            "Basic " +
            window.btoa(
              process.env.REACT_APP_APIKEY +
                ":" +
                process.env.REACT_APP_PUBLIC_SECRETKEY
            ),
        },
      });
    }

    this.setState({ isLoading: false });

    this.setState({ submitted: alternative ? "alternative" : "normal" });

    this.setState({ step: 1 });
    document.getElementById("contactForm") &&
      document.getElementById("contactForm").reset();
  };

  onHideSuccess = () => {
    this.setState({ submitted: false });
  };

  successMessage = () => {
    if (this.state.submitted) {
      return (
        <div className="text-left">
          {!this.state.userName && this.state.formFields.name && (
            <h4>
              Bem-vindo(a) ao Sebrae Experience, {this.state.formFields.name}!
            </h4>
          )}
          <h4>Que ótimo ter você conosco!</h4>
          <>
            <p className="font-weight-normal text-dark">
              Em breve você receberá um e-mail de confirmação de inscrição do
              evento. Caso não localize na sua caixa de entrada, verifique sua
              caixa de SPAM.
            </p>
            <p className="font-weight-normal text-dark">
              Este evento será realizado no Sebrae Experience, plataforma
              virtual de eventos do Sebrae! Aproveite e não deixe de conhecer os
              demais espaços.
            </p>
            <p className="font-weight-normal text-dark">
              Você pode falar conosco no Atendimento Sebrae, conferir os
              conteúdos exclusivos disponíveis para você no Acervo e visitar o
              Espaço de Crédito. Não esqueça de passar no Networking e deixar lá
              seu cartão de visitas!
            </p>
            <p className="font-weight-normal text-dark">
              Ao acessar o Sebrae Experience, você participa do evento, clicando
              no ícone da <b>"Programação"</b> localizado na barra fixa ou
              navegando até a <b>"Arena do Conhecimento"</b>.
            </p>
            <p className="font-weight-normal text-dark">
              Para acessar o <b>Sebrae Experience</b> clique no link{" "}
              {this.state?.global?.url && (
                <a
                  href={this.state.global.url + `/signin`}
                  title={this.state?.global?.title}
                  alt={this.state?.global?.title}
                  rel="noopener noreferrer"
                >
                  {this.state.global.url.replace(/(^\w+:|^)\/\//, "")}
                </a>
              )}
            </p>

            <p className="font-weight-normal text-dark">
              Caso tenha dúvidas ou dificuldade para acessar o evento e/ou a
              plataforma do Sebrae Experience, entre em contato com a Central de
              Atendimento do Sebrae, pelo telefone{" "}
              <a href="tel+0800 570 0800">
                <b>0800 570 0800</b>
              </a>{" "}
              ou se preferir, pelos nossos canais digitais{" "}
              <a
                href="http://www.sebrae.com.br/sites/PortalSebrae/faleconosco"
                target="_blank"
                rel="noopener noreferrer"
              >
                <b>clicando aqui.</b>
              </a>
            </p>
          </>
        </div>
      );
    }
  };

  errorMessage = () => {
    if (this.state.submitError) {
      return (
        <div className="text-left">
          <h4>Que ótimo ter você conosco!</h4>
          <>
            <p className="font-weight-normal text-dark">
              Você já está inscrito no evento!
            </p>
            <p className="font-weight-normal text-dark">
              Para acessar o <b>Sebrae Experience</b> clique no link{" "}
              {this.state?.global?.url && (
                <a
                  href={this.state.global.url + `/signin`}
                  title={this.state?.global?.title}
                  alt={this.state?.global?.title}
                  rel="noopener noreferrer"
                >
                  {this.state.global.url.replace(/(^\w+:|^)\/\//, "")}
                </a>
              )}
            </p>

            <p className="font-weight-normal text-dark">
              Caso tenha dúvidas ou dificuldade para acessar o evento e/ou a
              plataforma do Sebrae Experience, entre em contato com a Central de
              Atendimento do Sebrae, pelo telefone{" "}
              <a href="tel+0800 570 0800">
                <b>0800 570 0800</b>
              </a>{" "}
              ou se preferir, pelos nossos canais digitais{" "}
              <a
                href="http://www.sebrae.com.br/sites/PortalSebrae/faleconosco"
                target="_blank"
                rel="noopener noreferrer"
              >
                <b>clicando aqui.</b>
              </a>
            </p>
          </>
        </div>
      );
    }
  };

  changeStep = () => {
    if (this.state.step + 1 === 5) {
      this.handleForm();
    }

    if (this.state.step + 1 === 4) {
      let hasEvent = false;
      const event = this.state.eventList.filter(
        (e) => e.activeOptinSponsor === true
      );

      event.forEach((e) => {
        this.state.event.forEach((item) => {
          if (e._id === item) hasEvent = true;
        });
      });

      if (!hasEvent) this.handleForm();
    }

    this.setState({ step: this.state.step + 1 });
  };

  handleDataForm = (
    data,
    userSebrae = false,
    enterprises = false,
    hasSebrae
  ) => {
    if (hasSebrae) {
      this.setState({ hasSebrae: true });
    }

    if (this.state.step === 3) {
      this.setState({
        formFields: {
          ...this.state.formFields,
          extra: {
            ...this.state.formFields.extra,
            empresa: {
              ...this.state.formFields.extra.empresa,
              ...data.extra.empresa,
            },
          },
        },
      });

      this.changeStep();
    } else {
      this.setState({
        formFields: {
          ...this.state.formFields,
          ...data,
        },
      });
    }

    if (userSebrae) {
      this.setState({ userSebrae });
    }
    if (enterprises) {
      this.setState({ enterprises });
    }

    if (this.state.step === 1) {
      this.setState({ isOpenTerms: false, terms: true });
      this.setState({ isOpenPrivacy: false });

      if (this.state.isUnderage || this.state.isForeign) {
        this.setState({ step: 3 });
        this.changeStep();
      } else {
        this.changeStep();
      }
    }
  };

  render() {
    return (
      <React.Fragment>
        <section id="enrollment" className="pricing-area ptb-120 bg-image">
          <div className="container">
            <div className="section-title">
              <span>Garanta a sua vaga</span>
              <h2>Inscreva-se</h2>

              <LaxDiv text="Inscreva-se" dataPreset="driftLeft" />

              <div className="bar"></div>
            </div>
            <div className="mx-auto col-lg-11 col-md-12">
              <>
                {this.state.userName && (
                  <h4>Bem-vindo(a), {this.state.userName}!</h4>
                )}
                {!this.state.submitted &&
                  this.state.step === 1 &&
                  !this.state.userName && (
                    <label className="mb-3">
                      {this.props.global.txtInscricao}
                    </label>
                  )}
                {!this.state.submitted &&
                  this.state.step === 1 &&
                  this.state.userName && (
                    <label className="mb-3">
                      Confirme seu endereço do email para receber as informações
                      sobre o evento. Você receberá um email após a inscrição.
                      Solicitamos que verifique também na sua caixa de spam.
                    </label>
                  )}
                {!this.state.submitted && this.state.step === 2 && (
                  <label className="mb-3">
                    {this.props.global.txtInscricao}
                  </label>
                )}
                {!this.state.submitted && this.state.step === 3 && (
                  <label className="mb-3">
                    Nesta etapa, selecione abaixo como prefere se inscrever.
                  </label>
                )}
                {!this.state.submitted && this.state.step === 4 && (
                  <label className="mb-3">
                    Falta pouco para você finalizar sua inscrição!
                  </label>
                )}

                <div id="msgSubmit" className="h3 text-center">
                  {this.successMessage()}
                </div>
                <div id="msgSubmit" className="h3 text-center">
                  {this.errorMessage()}
                </div>

                {this.state.isLoading && <Load />}

                {this.state.step === 0 && (
                  <FormEventSecondary
                    dataAmei={this.state.dataUserAmei}
                    global={this.state.global}
                    sendForm={(e) => {
                      this.setState({
                        event: e[0],
                        eventList: e[1],
                        step: 1,
                      });
                    }}
                    update={(e, data) => {
                      this.setState({
                        event: e[0],
                        eventList: e[1],
                        formFields: data,
                        step: 7,
                      });
                      this.handleForm(false, true);
                    }}
                  />
                )}

                {!this.state.submitted && !this.state.isLoading && (
                  <>
                    {this.state.step === 1 && (
                      <>
                        <form id="contactForm">
                          {!this.state.isUnderage && (
                            <div className="col-lg-12 col-md-12">
                              <label htmlFor="isForeign">
                                <input
                                  id="isForeign"
                                  name="extra.isForeign"
                                  type="checkbox"
                                  onChange={(event) => {
                                    event.target.checked
                                      ? this.setState({
                                          isForeign: true,
                                        })
                                      : this.setState({
                                          isForeign: false,
                                        });
                                  }}
                                />
                                &#160; Sou Estrangeiro
                              </label>
                              <br />
                            </div>
                          )}

                          {!this.state.isForeign && (
                            <div className="col-lg-12 col-md-12">
                              <label htmlFor="isUnderage">
                                <input
                                  id="isUnderage"
                                  name="extra.isUnderage"
                                  type="checkbox"
                                  onChange={(event) => {
                                    event.target.checked
                                      ? this.setState({
                                          isUnderage: true,
                                        })
                                      : this.setState({
                                          isUnderage: false,
                                        });
                                  }}
                                />
                                &#160; Tenho menos de 13 anos
                              </label>
                              <br />
                            </div>
                          )}
                        </form>

                        {this.state.isForeign && (
                          <FromForeign
                            terms={this.state.terms}
                            sendForm={(e) => this.handleDataForm(e)}
                            send={() => this.handleForm()}
                          />
                        )}

                        {this.state.isUnderage && (
                          <FormUnderage
                            terms={this.state.terms}
                            sendForm={(e) => this.handleDataForm(e)}
                            send={() => this.handleForm()}
                          />
                        )}
                      </>
                    )}

                    {this.state.step === 4 && (
                      <AuthorizationList
                        event={this.state.event}
                        eventList={this.state.eventList}
                        sendForm={(e) => {
                          this.setState({ partners: e });
                          this.changeStep();
                        }}
                      />
                    )}
                  </>
                )}
              </>
            </div>
          </div>
        </section>
      </React.Fragment>
    );
  }
}

export default About;
