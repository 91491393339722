import { Formik } from "formik";
import { validateBr } from "js-brasil";
import React from "react";
import MaskedInput from "react-text-mask";
import * as yup from "yup";
import api from "../../services/api";
import { getByCpf } from "../../services/api-sebrae-pf";
import Load from "../load/Load";
import LoadSmall from "../load/LoadSmall";
import ModalAlert from "../Modal/ModalAlert";
class FormDefault extends React.Component {
  state = {
    collapsed: true,
    isOpen: false,
    formFields: { extra: { cpf: "" } },
    userSebrae: {},
    isUnderage: null,
    isForeign: null,
    enterprises: {},
    hasUserInFunifier: false,
    userFromAmei: false,
    hasUserInSebrae: false,
    enrollCompleted: false,
    modalTitle: null,
    modalBody: null,
    modalLink: null,
    modalLinkText: null,
    isUserAmei: false,
    isLoading: false,
    isLoadingSmall: false,
    isApiRaeOn: true,
  };

  // eslint-disable-next-line
  constructor(props) {
    super(props);

    this.resetTitleModal = this.resetTitleModal.bind(this);
  }

  parseDate(date) {
    const dateSanitized = parseInt(
      date.replace("/Date(", "").replace("-0300)/", "")
    );
    const d = new Date(dateSanitized);
    return d;
  }

  async componentDidMount() {
    this.setState({ isLoading: true });
    let search = window.location.search;
    let params = new URLSearchParams(search);
    let token = params.get("token");

    if (token) {
      try {
        const authToken =
          "Basic " +
          window.btoa(
            `${process.env.REACT_APP_APIKEY}:${process.env.REACT_APP_GLOBAL_SECRETKEY}`
          );

        const res = await api.get(
          `/database/registration_temp__c?q='_id':'${token}'`,
          {
            headers: {
              Authorization: authToken,
            },
          }
        );

        this.setState({
          formFields: {
            _id: res.data[0].cpf,
            ...this.state.formFields,
            email: res.data[0].email,
            confirmEmail: res.data[0].email,
            name: res.data[0].name,
            extra: {
              ...this.state.formFields.extra,
              cpf: res.data[0].cpf,
              sexo: res.data[0].genero === "masculino" ? "M" : "F",
              data_nascimento: new Date(res.data[0].dataNascimento),
              openId: true,
            },
          },
        });

        this.setState({ isUserAmei: true });
        this.searchCPF(res.data[0].cpf);
      } catch (error) {
        console.log("error =>", error);
        this.setState({ isLoading: false });
      }
    } else if (this.props?.dataAmei?.cpf) {
      this.setState({
        formFields: {
          _id: this.props.dataAmei.cpf,
          ...this.state.formFields,
          email: this.props.dataAmei.email,
          confirmEmail: this.props.dataAmei.email,
          name: this.props.dataAmei.name,
          extra: {
            ...this.state.formFields.extra,
            cpf: this.props.dataAmei.cpf,
            sexo: this.props.dataAmei.genero === "masculino" ? "M" : "F",
            data_nascimento: new Date(this.props.dataAmei.dataNascimento),
            openId: true,
          },
        },
      });

      this.setState({ isUserAmei: true });
      this.searchCPF(this.props.dataAmei.cpf);
    } else {
      this.setState({
        isLoading: false,
        formFields: {
          extra: {
            cpf: this.props.cpf,
          },
        },
      });

      document.getElementById("inputCpf").focus();
    }
  }

  schema = yup.object().shape({
    name: yup
      .string()
      .required(`Nome é obrigatório!`)
      .test(
        "is-valid",
        `Nome inválido, por favor digite seu nome completo`,
        (value) => {
          if (value !== null && value !== undefined) {
            const regExp = /(\w+)/g;
            const str = value.match(regExp)?.length;

            if (str && str >= 2) {
              return true;
            }
          }

          return false;
        }
      ),
    email: yup
      .string()
      .email(`E-mail inválido!`)
      .required(`E-mail é obrigatório!`),
    confirmEmail: yup
      .string()
      .required(`E-mail é obrigatório!`)
      .oneOf([yup.ref("email"), null], `E-mails precisam ser iguais!`),
    // password: yup.string().required(`Senha é obrigatório!`),
    // confirmPassword: yup
    //   .string()
    //   .required(`Senha é obrigatório!`)
    //   .oneOf([yup.ref("password"), null], `Senhas precisam ser iguais!`),
    extra: yup.object().shape({
      cpf: yup.string().test("is-valid", `CPF inválido!`, (value) => {
        if (value) {
          return validateBr.cpf(value);
        }
        return false;
      }),
      sexo: yup.string().required(`Sexo é obrigatório!`),
      data_nascimento: yup
        .string()
        .required(`Data de nascimento é obrigatório`),
    }),
  });

  schema2 = yup.object().shape({
    email: yup
      .string()
      .email(`E-mail inválido!`)
      .required(`E-mail é obrigatório!`),
    confirmEmail: yup
      .string()
      .required(`E-mail é obrigatório!`)
      .oneOf([yup.ref("email"), null], `E-mails precisam ser iguais!`),
  });

  async handleUserToFunifier() {
    try {
      let user = { ...this.state.formFields };

      user.extra.terms = true;
      user.extra.privacy = true;

      if (!this.state.isUnderage && !this.state.isForeign) {
        user._id = user.extra.cpf.replace(/[^\d]+/g, "");
        user.extra.type = "normal";
      } else {
        user._id = user.email;

        if (this.state.isForeign) {
          user.extra.type = "estrangeiro";
        } else {
          user.extra.type = "menor";
        }
      }

      this.setState({ isLoading: true });

      if (user.extra && user.extra.telefone)
        user.extra.telefone = user.extra.telefone.replace(/[^\d]+/g, "");

      if (user.extra && user.extra.cpf)
        user.extra.cpf = user.extra.cpf.replace(/[^\d]+/g, "");

      if (user.extra?.empresa?.cnpj)
        user.extra.empresa.cnpj = user.extra.empresa.cnpj.replace(
          /[^\d]+/g,
          ""
        );

      const resFunifier = await api.post("database/registration__c", user, {
        headers: {
          Authorization:
            "Basic " +
            window.btoa(
              process.env.REACT_APP_APIKEY +
                ":" +
                process.env.REACT_APP_PUBLIC_SECRETKEY
            ),
        },
      });

      return resFunifier;
    } catch (error) {
      console.log("erro ao cadastrar usuário na plataforma", error);
    }
  }

  searchCPF = async (cpf, setFieldValue) => {
    if (!cpf) return;

    if (this.state.isUserAmei) {
      this.setState({ isLoading: true });
    } else {
      this.setState({ isLoadingSmall: true });
    }

    if (cpf) {
      try {
        // const userFromFunifier = await api.get(
        //   `player?q='extra.cpf':'${cpf.replace(/[^\d]+/g, "")}'`,
        //   {
        //     headers: {
        //       Authorization:
        //         "Basic " +
        //         window.btoa(
        //           `${process.env.REACT_APP_APIKEY}:${process.env.REACT_APP_GLOBAL_SECRETKEY}`
        //         ),
        //     },
        //   }
        // );

        const userFromAmei = await getByCpf(cpf.replace(/[^\d]+/g, ""));

        const [ameiUser] = await Promise.all([userFromAmei]);

        // if (hasEnrollRae?.ClienteInscrito) {
        //   this.setState({ enrollCompleted: true });
        // }

        const { data: res, enterprises } = ameiUser;

        if (enterprises) {
          this.setState({ enterprises });
        }

        if (res) {
          this.setState({ userFromAmei: true, userSebrae: res });
        }

        // if (funifierUser?.data[0] && !funifierUser?.data[0]?.extra?.openId) {
        //   this.setState({
        //     hasUserInFunifier: true,
        //     modalTitle:
        //       "Você já tem cadastro na Plataforma do Sebrae Experience!",
        //     modalBody: "Por favor faça login em: ",
        //     modalLink: "https://sebraesp.inova3d.app/login",
        //   });
        // }

        // if (
        //   funifierUser?.data[0] &&
        //   funifierUser?.data[0]?.extra?.openId &&
        //   this.state.enrollCompleted
        // ) {
        //   this.setState({
        //     hasUserInFunifier: true,
        //     modalTitle:
        //       "Você já tem cadastro na Plataforma do Sebrae Experience!",
        //     modalNavigationPath: `/recovery-password/${funifierUser.data[0]._id}/${this.props.events}`,
        //     modalBody:
        //       "Enviamos para o seu e-mail de cadastro um código para recuperar a senha e prosseguir com a sua inscrição no evento.",
        //   });
        // }

        let dateInscription;

        if (res) {
          const dateSanitized = parseInt(
            res.DataNascimento.replace("/Date(", "").replace("-0300)/", "")
          );
          const d = new Date(dateSanitized);
          const day = d.getDate();
          const month = d.getMonth() + 1;
          const year = d.getFullYear();
          const newDate = `${year}-${month < 10 ? "0" + month : month}-${day}`;
          dateInscription = this.parseDate(res.Rastreabilidade.DataCriacao);

          this.setState({
            formFields: {
              _id: res.CPF.replace(/[^\d]+/g, ""),
              ...this.state.formFields,
              email: res.Email && res.Email.toLowerCase(),
              confirmEmail: res.Email && res.Email.toLowerCase(),
              name: res.Nome,
              extra: {
                ...this.state.formFields.extra,
                bairro: res.Endereco.Bairro,
                cpf: res.CPF.replace(/[^\d]+/g, ""),
                cep: res.Endereco.CEP,
                complemento: res.Endereco.Complemento,
                cidade: res.Endereco.Cidade,
                idCidade: res.Endereco.IdCidade,
                IdPais: res.Endereco.IdPais,
                idTipoLogradouro: res.Endereco.IdTipoLogradouro,
                logradouro: res.Endereco.Logradouro,
                tipoLogradouro: res.Endereco.TipoLogradouro,
                numero: res.Endereco.Numero,
                uf: res.Endereco.UF,
                sexo: res.Sexo,
                // data_nascimento: this.parseDate(res.DataNascimento),
                data_nascimento: newDate,
                idPessoa: res.IdPessoa,
                mobile: res.Celular && res.Celular,
                phone: res.Telefone && res.Telefone,
                pessoasJuridicas: res.PessoasJuridicasVinculadas,
                emailRae: res.Email && res.Email.toLowerCase(),
              },
            },
          });

          if (setFieldValue) {
            setFieldValue("name", res.Nome);
            setFieldValue("email", res.Email && res.Email.toLowerCase());
            setFieldValue("confirmEmail", res.Email && res.Email.toLowerCase());
            setFieldValue("extra.sexo", res.Sexo);
            setFieldValue("extra.data_nascimento", newDate);
          }
        } else if (
          this.state.formFields &&
          this.state.formFields.extra &&
          !this.state.isUserAmei
        ) {
          this.setState({
            formFields: {},
          });

          setFieldValue("name", "");
          setFieldValue("email", "");
          setFieldValue("confirmEmail", "");
          setFieldValue("extra.sexo", "");
          setFieldValue("extra.data_nascimento", "");

          this.setState({
            formFields: {
              email: "",
              confirmEmail: "",
              name: "",
              extra: {
                ...this.state.formFields.extra,
                bairro: "",
                cep: "",
                complemento: "",
                cidade: "",
                idCidade: "",
                IdPais: "",
                idTipoLogradouro: "",
                logradouro: "",
                tipoLogradouro: "",
                numero: "",
                uf: "",
                sexo: "",
                data_nascimento: "",
              },
            },
          });

          this.setState({
            formFields: {},
          });
        }

        // if (
        //   !funifierUser?.data[0] &&
        //   this.state.userFromAmei &&
        //   this.state.enrollCompleted
        // ) {
        //   const user = await this.handleUserToFunifier();
        //   if (user) {
        //     window.location.href = `/recovery-password/${this.state.formFields.extra.cpf}/${this.props.events}`;
        //   }
        // }

        // if (
        //   this.userFromAmei &&
        //   !funifierUser?.data[0] &&
        //   !this.state.enrollCompleted
        // ) {
        //   const user = await this.handleUserToFunifier();

        //   if (user) {
        //     this.props.goToSep();
        //   }
        // }

        if (
          dateInscription &&
          this.state.isUserAmei &&
          new Date(dateInscription.setMonth(dateInscription.getMonth() + 1)) >
            new Date()
        ) {
          this.props.sendForm(
            this.state.formFields,
            this.state.userSebrae,
            this.state.enterprises,
            this.state.hasUserInSebrae,
            this.state.hasUserInFunifier,
            this.state.userFromAmei,
            this.state.enrollCompleted,
            this.state.isApiRaeOn
          );
        }

        if (this.state.isUserAmei && !res) {
          this.props.sendForm(
            this.state.formFields,
            this.state.userSebrae,
            this.state.enterprises,
            this.state.hasUserInSebrae,
            this.state.hasUserInFunifier,
            this.state.userFromAmei,
            this.state.enrollCompleted,
            this.state.isApiRaeOn
          );
        }

        this.setState({ isLoading: false, isLoadingSmall: false });
      } catch (error) {
        console.log("erro ao buscar dados rae", error);
        this.setState({
          isLoading: false,
          isLoadingSmall: false,
          isApiRaeOn: false,
        });
      }
    }
  };

  resetTitleModal() {
    this.setState({ modalTitle: null, hasUserInFunifier: false });
  }

  handleInput = (e) => {
    const { name, value } = e.target;

    const data = { ...this.state.formFields };
    data[name] = value;
    this.setState({ formFields: data });
  };

  render() {
    return (
      <>
        {this.state.modalTitle && (
          <ModalAlert
            title={this.state.modalTitle}
            body={this.state.modalBody}
            link={this.state.modalLink}
            navigationPath={this.state.modalNavigationPath}
            resetTitleModal={this.resetTitleModal}
          />
        )}

        <div className={`${this.state.isLoading ? "d-none" : ""}`}>
          <Formik
            validationSchema={
              this.state.isUserAmei ? this.schema2 : this.schema
            }
            enableReinitialize={this.state.isUserAmei ? true : false}
            onSubmit={(values, actions) => {
              this.props.sendForm(
                values,
                this.state.userSebrae,
                this.state.enterprises,
                this.state.hasUserInSebrae,
                this.state.hasUserInFunifier,
                this.state.userFromAmei,
                this.state.enrollCompleted,
                this.state.isApiRaeOn
              );
            }}
            initialValues={this.state.formFields}
          >
            {({
              handleSubmit,
              handleChange,
              handleBlur,
              setFieldTouched,
              setFieldValue,

              values,
              touched,
              isValid,
              isValidating,
              errors,
            }) => (
              <form id="contactForm" onSubmit={handleSubmit}>
                <div className="row">
                  {!this.state.isForeign && !this.state.isUserAmei && (
                    <div className="col-lg-12 col-md-12">
                      <label className="flex ali" htmlFor="">
                        CPF*{" "}
                      </label>
                      <div className="div-relative">
                        <MaskedInput
                          guide={false}
                          name="extra.cpf"
                          id="inputCpf"
                          keepCharPositions={false}
                          showMask={false}
                          mask={[
                            /\d/,
                            /\d/,
                            /\d/,
                            ".",
                            /\d/,
                            /\d/,
                            /\d/,
                            ".",
                            /\d/,
                            /\d/,
                            /\d/,
                            "-",
                            /\d/,
                            /\d/,
                          ]}
                          type="text"
                          className={`form-control ${
                            errors?.extra?.cpf ? "invalid-form" : ""
                          }`}
                          onChange={(e) => {
                            handleChange(e);
                          }}
                          value={this.props?.cpf ? this.props?.cpf : undefined}
                          onBlur={(e) => {
                            this.searchCPF(e.target.value, setFieldValue);
                            handleChange(e);
                          }}
                        />
                      </div>
                      <span className="invalid-form">{errors?.extra?.cpf}</span>
                    </div>
                  )}

                  {!this.state.isUserAmei && (
                    <div className="col-lg-12 col-md-12">
                      <label htmlFor="">Nome*</label>
                      <input
                        name="name"
                        type="text"
                        className={`form-control ${
                          errors.name ? "invalid-form" : ""
                        }`}
                        onChange={handleChange}
                        value={
                          this.state?.formFields?.name
                            ? this.state?.formFields?.name
                            : undefined
                        }
                      />
                      <span className="invalid-form">{errors.name}</span>
                    </div>
                  )}

                  {!this.state.isUserAmei && (
                    <div className="col-lg-12 col-md-12">
                      <label htmlFor="">Sexo*</label>
                      <select
                        name="extra.sexo"
                        id=""
                        className={`form-control ${
                          errors.extra && errors.extra.sexo
                            ? "invalid-form"
                            : ""
                        }`}
                        onChange={handleChange}
                        value={this.state?.formFields?.extra?.sexo}
                      >
                        <option value="">Selecione</option>
                        <option value="M">Masculino</option>
                        <option value="F">Feminino</option>
                      </select>
                      <span className="invalid-form">
                        {errors.extra && errors.extra.sexo}
                      </span>
                    </div>
                  )}

                  {!this.state.isForeign && !this.state.isUserAmei && (
                    <div className="col-lg-12 col-md-12">
                      <label htmlFor="">Data de nascimento*</label>
                      <input
                        name="extra.data_nascimento"
                        type="date"
                        className={`form-control ${
                          errors.extra && errors.extra.data_nascimento
                            ? "invalid-form"
                            : ""
                        }`}
                        onChange={handleChange}
                        value={
                          this.state?.formFields?.extra?.data_nascimento
                            ? this.state?.formFields?.extra?.data_nascimento
                            : values?.extra?.data_nascimento
                            ? values.extra.data_nascimento
                            : undefined
                        }
                      />

                      <span className="invalid-form">
                        {errors.extra && errors.extra.data_nascimento}
                      </span>
                    </div>
                  )}

                  <div className="col-lg-12 col-md-12">
                    <label htmlFor="">E-mail*</label>
                    <input
                      name="email"
                      type="email"
                      className={`form-control ${
                        errors.email ? "invalid-form" : ""
                      }`}
                      onChange={(e) => {
                        handleChange(e);
                        this.handleInput(e);
                      }}
                      value={
                        this.state?.formFields?.email
                          ? this.state?.formFields?.email
                          : undefined
                      }
                    />
                    <span className="invalid-form">{errors.email}</span>
                  </div>

                  <div className="col-lg-12 col-md-12">
                    <label htmlFor="">Confirmação de e-mail*</label>
                    <input
                      name="confirmEmail"
                      type="email"
                      autoComplete="false"
                      onChange={(e) => {
                        handleChange(e);
                        this.handleInput(e);
                      }}
                      value={
                        this.state?.formFields?.confirmEmail
                          ? this.state?.formFields?.confirmEmail
                          : undefined
                      }
                      className={`form-control ${
                        errors.confirmEmail ? "invalid-form" : ""
                      }`}
                    />
                    <span className="invalid-form">{errors.confirmEmail}</span>
                  </div>

                  {(this.state.isForeign || this.state.isUnderage) && (
                    <>
                      <div className="col-lg-12 col-md-12">
                        <label htmlFor="">Senha*</label>
                        <input
                          name="password"
                          autoComplete="false"
                          type="password"
                          className={`form-control ${
                            errors.password ? "invalid-form" : ""
                          }`}
                          onChange={handleChange}
                        />
                        <span className="invalid-form">{errors.password}</span>
                      </div>
                      <div className="col-lg-12 col-md-12">
                        <label htmlFor="">Confirmação senha*</label>
                        <input
                          name="confirmPassword"
                          type="password"
                          autoComplete="false"
                          onChange={handleChange}
                          className={`form-control ${
                            errors.confirmPassword ? "invalid-form" : ""
                          }`}
                        />
                        <span className="invalid-form">
                          {errors.confirmPassword}
                        </span>
                      </div>
                    </>
                  )}

                  <div className="col-lg-12 col-md-12">
                    <label htmlFor="acceptInfo">
                      <input
                        name="extra.infosSebraeSP"
                        type="checkbox"
                        onChange={handleChange}
                        id="acceptInfo"
                      />
                      &#160; Aceito receber informações do SEBRAE
                    </label>
                    <br />
                  </div>
                  <div className="col-lg-12 col-md-12 text-right">
                    {!this.props.terms && !this.state.hasUserInFunifier && (
                      <button
                        type="submit"
                        className="btn btn-primary"
                        disabled={this.state.isLoadingSmall}
                      >
                        Próximo
                      </button>
                    )}

                    {this.props.terms &&
                      this.state.userSebrae &&
                      this.state.userSebrae.CPF && (
                        <button
                          type="button"
                          className="btn btn-primary"
                          onClick={() => this.props.send()}
                        >
                          Enviar
                        </button>
                      )}
                  </div>
                </div>
              </form>
            )}
          </Formik>
        </div>

        {this.state.isLoading && <Load />}
        {this.state.isLoadingSmall && <LoadSmall />}
      </>
    );
  }
}

export default FormDefault;
