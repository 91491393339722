import { Formik } from "formik";
import React from "react";
import * as yup from "yup";
import api from "../../services/api";
import MaskedInput from "react-text-mask";

class FormGetUser extends React.Component {
  state = { formFields: { extra: { cpf: "" } } };
  arr = ["sebrae_experience"]; // default value
  // eslint-disable-next-line
  constructor(props) {
    super(props);
  }

  async getFunifier(cpf, setField) {
    if (cpf.length < 14) return;

    const userFromFunifier = await api.post(
      `database/player/aggregate`,
      [
        {
          $match: {
            "extra.cpf": cpf.replace(/[^\d]+/g, ""),
            "extra.idStand": { $exists: false },
          },
        },
      ],
      {
        headers: {
          Authorization:
            "Basic " +
            window.btoa(
              `${process.env.REACT_APP_APIKEY}:${process.env.REACT_APP_GLOBAL_SECRETKEY}`
            ),
        },
      }
    );

    if (userFromFunifier.data && userFromFunifier.data[0]) {
      const user = userFromFunifier.data[0];

      const data = {
        cpf: user.extra.cpf,
        email: user.email,
        name: user.name,
        genero: user.extra.sexo === "M" ? "masculino" : "feminino",
        dataNascimento: user.extra.data_nascimento,
      };

      this.props.sendForm(data);
    } else {
      this.props.sendForm({cpf: cpf.replace(/[^\d]+/g, "")});
    }
  }

  schema = yup.object().shape({});

  render() {
    return (
      <div className={`${this.state.isLoading ? "d-none" : ""}`}>
        <Formik
          validationSchema={this.state.isUserAmei ? this.schema2 : this.schema}
          enableReinitialize={this.state.isUserAmei ? true : false}
          onSubmit={(values, actions) => {
            this.props.sendForm(
              values,
              this.state.userSebrae,
              this.state.enterprises,
              this.state.hasUserInSebrae,
              this.state.hasUserInFunifier,
              this.state.userFromAmei,
              this.state.enrollCompleted,
              this.state.isApiRaeOn
            );
          }}
          initialValues={this.state.formFields}
        >
          {({
            handleSubmit,
            handleChange,
            handleBlur,
            setFieldTouched,
            setFieldValue,

            values,
            touched,
            isValid,
            isValidating,
            errors,
          }) => (
            <form id="contactForm" onSubmit={handleSubmit}>
              <div className="row">
                {!this.state.isForeign && !this.state.isUserAmei && (
                  <div className="col-lg-12 col-md-12">
                    <label className="flex ali" htmlFor="">
                      CPF*{" "}
                    </label>
                    <div className="div-relative">
                      <MaskedInput
                        guide={false}
                        name="extra.cpf"
                        id="inputCpf"
                        keepCharPositions={false}
                        showMask={false}
                        mask={[
                          /\d/,
                          /\d/,
                          /\d/,
                          ".",
                          /\d/,
                          /\d/,
                          /\d/,
                          ".",
                          /\d/,
                          /\d/,
                          /\d/,
                          "-",
                          /\d/,
                          /\d/,
                        ]}
                        type="text"
                        className={`form-control ${
                          errors?.extra?.cpf ? "invalid-form" : ""
                        }`}
                        onChange={(e) => {
                          handleChange(e);
                          this.getFunifier(e.target.value, setFieldValue);
                        }}
                      />
                    </div>
                    <span className="invalid-form">{errors?.extra?.cpf}</span>
                  </div>
                )}

                {/* <div className="col-lg-12 col-md-12 text-right mt-4">
                  <button type="submit" className="btn btn-primary">
                    Próximo
                  </button>
                </div> */}
              </div>
            </form>
          )}
        </Formik>
      </div>
    );
  }
}

export default FormGetUser;
