import React from "react";

class Load extends React.Component {
  render() {
    return (
      <div className="row">
        <div className="col-12 text-center pt-5">
          <div className="lds-spinner">
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
          </div>
        </div>
      </div>
    );
  }
}

export default Load;
