import React from "react";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";

export default function ModalConfirm(props) {
  const [show, setShow] = React.useState(true);

  const handleClose = () => {
    setShow(false);
  };

  return (
    <>
      <Modal show={show} backdrop="static" onHide={handleClose}>
        <Modal.Header>
          <div
            dangerouslySetInnerHTML={{
              __html: props.title,
            }}
          ></div>
        </Modal.Header>
        {props.body && (
          <Modal.Body>
            <div
              dangerouslySetInnerHTML={{
                __html: props.body,
              }}
            ></div>
          </Modal.Body>
        )}

        <Modal.Footer>
          <Button variant="secondary" onClick={() => props.onClose()}>
            Cancelar
          </Button>
          <Button
            variant="primary"
            onClick={() => {
              props.onConfirm();
            }}
          >
            Continuar
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}
