import React from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import HomeDefault from "./components/pages/HomeDefault";
import NotFound from "./components/pages/NotFound";
import RecoverPassword from "./components/pages/RecoveryPassword";
import HomeSecondary from "./components/pages/HomeSecondary";

// Conditionally render Navigation
// const renderNavigation = () => {
//     if (!(window.location.pathname === '/login' || window.location.pathname === '/signup' || window.location.pathname === '/coming-soon' || window.location.pathname === '/error-404')){
//         return <Navigation />;
//     }
// }

const AppRouter = () => {
  return (
    <Router>
      {/* {renderNavigation()} */}
      <Switch>
        <Route path="/" exact component={HomeDefault} />
        <Route path="/other" exact component={HomeSecondary} />
        <Route
          path="/recovery-password/:id/:event"
          exact
          component={RecoverPassword}
        />
        <Route component={NotFound} />
      </Switch>
    </Router>
  );
};

export default AppRouter;
