import axios from "axios";
import apiGateway from "./api-sebrae-gateway";

export const getByCep = async (cep) => {
  try {
    const cepResponse = await apiGateway.get(`/rae/endereco/logradouro/${cep}`);

    return cepResponse.data && cepResponse.data.length > 0
      ? cepResponse.data[0]
      : {};
  } catch (error) {
    console.log("Erro ao tentar consultar cep", error);
  }
};

export const getAlternativeCep = async (cep) => {
  try {
    const { data } = await axios.get(`https://viacep.com.br/ws/${cep}/json/`);
    
    const cepData = {
      Bairro: data.bairro,
      CEP: data.cep,
      Cidade: data.localidade,
      Logradouro: data.logradouro,
      UF: data.uf,
    }

    if(data.complemento) cep.complemento = data.complemento;

    return cepData;
  } catch (error) {
    console.log("Erro ao tentar consultar cep", error);
  }
};

export default getByCep;
