import apiGateway from "./api-sebrae-gateway";

export const getByCnpj = async (cnpj) => {
  try {
    const { data } = await apiGateway.get(`/rae/pj/cnpj/${cnpj}`);

    return data;
  } catch (error) {
    console.log("Erro ao tentar consultar pessoa fisica", error);
  }
};

export const postByCnpj = async (payload) => {
  try {
    const { data } = await apiGateway.post(`/rae/pj/`, {
      item: payload,
    });

    return data;
  } catch (error) {
    console.log("Erro ao tentar cadastrar pessoa fisica", error);
  }
};

export const getByIdPessoaJuridica = async (idPj) => {
  try {
    const { data } = await apiGateway.get(`/rae/pj/id/${idPj}`);

    return data;
  } catch (error) {
    console.log("Erro ao tentar cadastrar pessoa fisica", error);
  }
};

export default { getByCnpj, postByCnpj, getByIdPessoaJuridica };
