import React from "react";
import CSSTransition from "react-transition-group/CSSTransition";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";

export default class ModalTerms extends React.Component {
  show = false;

  constructor(props) {
    super(props);
    this.state = {
      isOpenTerms: false,
    };
    this.closeModalTerms = this.closeModalTerms.bind(this);
    this.updateFocus = this.updateFocus.bind(this);
  }

  openModalTerms() {
    this.setState({ isOpenTerms: true });
  }

  closeModalTerms() {
    this.props.onCloseModal();
    this.setState({ isOpenTerms: false });
    if (typeof this.props.onClose === "function") {
      this.props.onClose();
    }
  }

  keydownHandler(e) {
    if (e.keyCode === 27) {
      this.closeModalTerms();
    }
  }

  componentDidMount() {
    document.addEventListener("keydown", this.keydownHandler.bind(this));
  }

  componentWillUnmount() {
    document.removeEventListener("keydown", this.keydownHandler.bind(this));
  }

  static getDerivedStateFromProps(props) {
    return { isOpenTerms: props.isOpenTerms };
  }

  componentDidUpdate() {
    if (this.state.isOpenTerms && this.modal) {
      this.modal.focus();
    }
  }

  updateFocus(e) {
    if (e.keyCode === 9) {
      e.preventDefault();
      e.stopPropagation();
      if (this.modal === document.activeElement) {
        this.modalbtn.focus();
      } else {
        this.modal.focus();
      }
    }
  }

  handleClose() {
    this.setState({ show: false });
  }
  handleShow() {
    this.setState({ show: true });
  }

  render() {
    return (
      <CSSTransition
        classNames={this.props.classNames.modalVideoEffect}
        timeout={this.props.animationSpeed}
      >
        {() => {
          if (!this.state.isOpenTerms) {
            return null;
          }

          return (
            <>
              <Modal show={this.show} onHide={this.handleClose}>
                <Modal.Header closeButton>
                  <Modal.Title>Discordo</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                  Para acessar o serviço você deve concordar com o termo de uso
                  e permitir acesso aos dados pessoais. Tem certeza que
                  discorda?
                </Modal.Body>
                <Modal.Footer>
                  <Button variant="secondary" onClick={this.handleClose}>
                    Sim, não aceito.
                  </Button>
                  <Button variant="primary" onClick={this.handleClose}>
                    Não, quero aceitar as condições para acesso
                  </Button>
                </Modal.Footer>
              </Modal>

              <div className="overlayModal"></div>
              <div
                className="modalSebrae"
                tabIndex="-1"
                role="dialog"
                aria-label="modalSebrae"
                ref={(node) => {
                  this.modal = node;
                }}
                onKeyDown={this.updateFocus}
              >
                <div className="modal-body">
                  {/* <button onClick={this.closeModalTerms}>x</button> */}
                  <h2 className="title">Permissão de dados e Termo de uso</h2>
                  <br />
                  <div className="modal-content">
                    <ul className="consentimento-lista">
                      <div>
                        <li>
                          <span
                            id="seta-direta-0"
                            className="seta-direita"
                          ></span>{" "}
                          INTRODUÇÃO
                        </li>
                        <div id="0-item" aria-expanded="false">
                          <p>
                            No site da Feira do Empreendedor, o Sebrae oferece
                            informações e a possibilidade de inscrição para quem
                            quer participar dos eventos organizados pela
                            instituição. Abaixo, você terá acesso aos termos e
                            condições da utilização deste Site e, ao aceitar,
                            você consentirá com as seguintes afirmações.
                          </p>
                        </div>
                      </div>
                      <div>
                        <li>
                          <span
                            id="seta-direta-1"
                            className="seta-direita"
                          ></span>{" "}
                          ACESSO PÚBLICO E RESTRIÇÕES
                        </li>
                        <div id="1-item">
                          <p>
                            {" "}
                            Este Site poderá ser acessado de forma gratuita em
                            toda a sua extensão e em todo o seu conteúdo. O
                            Sebrae reserva-se o direito de manter alguns
                            conteúdos restritos mediante cadastro. Para que o
                            usuário tenha acesso a estes conteúdos, é necessário
                            cadastrar-se, fornecer as informações solicitadas e
                            realizar os aceites dos termos apresentados. Todos
                            os dados inseridos neste Site são elaborados pelo
                            Sebrae de forma direta, ou por seus prestadores de
                            serviço e parceiros. Não é permitido ao usuário o
                            acesso ao código fonte do Site, seu banco de dados
                            ou qualquer outro conjunto de informações internas.
                            Quem o fizer, estará sujeito às penas previstas na
                            legislação penal e cível regentes no País. Também
                            não é permitido ao usuário qualquer técnica para
                            alterar, modificar, falsear ou esconder o número do
                            seu IP durante seu acesso ao Site, bem como submeter
                            conteúdos ao Site que contiverem programas
                            maliciosos. Caso haja descumprimento, seu cadastro e
                            acesso poderão ser suspensos ou excluídos.{" "}
                          </p>
                        </div>
                      </div>
                      <div>
                        <li>
                          <span
                            id="seta-direta-2"
                            className="seta-direita"
                          ></span>{" "}
                          DO CADASTRO PARA ACESSO NAS ÁREAS RESTRITAS
                        </li>
                        <div id="2-item">
                          <p>
                            Para ter acesso às áreas restritas, o usuário é
                            obrigado a cadastrar-se e concordar em inserir ali
                            apenas informações absolutamente verdadeiras, exatas
                            e atuais. O cadastro em dissonância com essas
                            obrigações poderá ser excluído pelo Sebrae, nos
                            termos do Item 4. O usuário também deve criar uma
                            senha. A confidencialidade desta senha é de sua
                            inteira responsabilidade, bem como qualquer
                            alteração ou movimentação em seu cadastro. O Sebrae
                            tem um cadastro único e pode ser feito por qualquer
                            canal de atendimento: presencial, telefônico ou no
                            Portal do Sebrae. Desta forma, se o usuário já tiver
                            cadastro no Sebrae, todos seus dados serão
                            recuperados para o cadastro no Site, bastando criar
                            uma senha de acesso. Seus dados poderão ser
                            atualizados a qualquer momento. Um usuário
                            cadastrado pode realizar o cadastro de uma empresa
                            ou vincular-se a uma já cadastrada. É obrigatória a
                            indicação do vínculo que o usuário possui com a
                            empresa vinculada. Ao ser iniciado um cadastro
                            empresarial, o sistema automaticamente consulta o
                            CNPJ na base de dados do Sebrae. Se o cadastro já
                            existir, todas as informações serão recuperadas e
                            não será possível alterá-las, a não ser
                            presencialmente, em um ponto de atendimento do
                            Sebrae, via Central de Relacionamento (0800 570
                            0800) ou pelo canal LGPD{" "}
                            <a
                              href="https://www.sebrae.com.br/sites/PortalSebrae/canais_adicionais/conheca_lgpd"
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              sebrae.com.br/lgpd
                            </a>
                            . Um usuário pode vincular-se e desvincular-se de
                            quantas empresas desejar. Quando o proprietário se
                            desvincula de uma empresa, aquele cadastro não será
                            apagado, ele permanecerá armazenado. O Sebrae não se
                            responsabiliza pelas pessoas que se vinculam às
                            empresas, mas se reserva o direito de excluir
                            vínculos que sejam considerados fraudulentos.{" "}
                          </p>
                        </div>
                      </div>
                      <div>
                        <li>
                          <span
                            id="seta-direta-3"
                            className="seta-direita"
                          ></span>
                          EXCLUSÃO DO CADASTRO
                        </li>
                        <div id="3-item">
                          <p>
                            O Sebrae poderá suspender ou excluir, a qualquer
                            tempo, as contas de acesso dos usuários que
                            descumprirem o presente Termo de Uso, que
                            transgredirem a lei ou procederem de forma não
                            ética, sem a necessidade de aviso prévio ou
                            justificativa. Essa decisão não gerará direito a
                            nenhuma espécie de indenização ou ressarcimento, sob
                            qualquer pretexto.{" "}
                          </p>
                        </div>
                      </div>
                      <div>
                        <li>
                          <span
                            id="seta-direta-4"
                            className="seta-direita"
                          ></span>{" "}
                          DA PUBLICAÇÃO DE CONTEÚDOS NO SITE
                        </li>
                        <div id="4-item">
                          <p>
                            {" "}
                            Todo conteúdo a ser publicado no Site será analisado
                            e autorizado por um revisor do Sebrae. Desta forma,
                            o Sebrae tem responsabilidade pelos conteúdos
                            publicados e responde por eventuais danos causados
                            aos usuários. O Sebrae tem o direito de suspender o
                            acesso ou retirar do Site qualquer conteúdo
                            publicado que entenda inconveniente, sem prévia
                            autorização ou justificativa.{" "}
                          </p>
                        </div>
                      </div>
                      <div>
                        <li>
                          <span
                            id="seta-direta-5"
                            className="seta-direita"
                          ></span>{" "}
                          CONDIÇÕES PARA USO DOS CONTEÚDOS
                        </li>
                        <div id="5-item">
                          <p>
                            Todo o conteúdo do Site poderá servir para o uso
                            pessoal do usuário, desde que citada a fonte. Está
                            expressamente proibido alterar, plagiar, vender ou
                            distribuir, com finalidade comercial, total ou
                            parcialmente, qualquer conteúdo publicado no Site
                            sem a autorização prévia e formalizada pelo Sebrae.
                            Todos os conteúdos que tiverem a identificação do
                            autor são de propriedade deste. Havendo a
                            autorização do titular, poderá ser repassado aos
                            usuários o direito de utilização, de cópia, de
                            reprodução e de retransmissão por quaisquer meios,
                            bem como de utilização em outros sites, sempre com a
                            ressalva de que esta utilização não poderá visar
                            lucro e que deverá obrigatoriamente citar o autor e
                            a fonte. Os conteúdos sem identificação do autor são
                            de propriedade do Sebrae e podem também ser
                            utilizados das mesmas formas, desde que atendidos os
                            mesmos requisitos. Caso o conteúdo seja autorizado
                            para publicação em outros sites, estes deverão
                            remeter ao conteúdo original por meio de hiperlink,
                            citando-o como fonte da nova publicação. O usuário
                            concorda com o fato de não ser possível ao Sebrae
                            averiguar se todos os materiais estão em
                            conformidade com os termos da Lei n.9.610/98 (Lei de
                            Direitos Autorais), em especial os conteúdos
                            fornecidos a título de colaboração, isentando-o,
                            portanto, de quaisquer responsabilidades nesse
                            sentido. O simples acesso ao Site já é considerado
                            uma declaração do usuário de que irá respeitar todos
                            os direitos de propriedade intelectual que estão
                            depositados ou registrados em nome do Sebrae, bem
                            como de terceiros e que, por alguma razão, estejam
                            disponíveis no Site.
                          </p>
                        </div>
                      </div>
                      <div>
                        <li>
                          <span
                            id="seta-direta-6"
                            className="seta-direita"
                          ></span>{" "}
                          DAS RESPONSABILIDADES
                        </li>
                        <div id="6-item">
                          <p>
                            <br />• INSCRIÇÕES PARA EVENTOS As inscrições para
                            eventos realizados pelo Sebrae são inscrições para
                            acesso ao ambiente de transmissões de conteúdos em
                            temas diversos de interesse de empreendedores e dos
                            pequenos negócios. Os eventos serão acessados após o
                            cadastramento prévio do usuário e a inscrição no
                            respectivo evento. Após a sua inscrição, o
                            participante receberá um link de acesso em que se
                            inscreveu.
                            <br />• SOBRE OS LINKS INDICADOS O Sebrae não se
                            responsabiliza pelos links a sites externos
                            apontados em suas páginas. As informações neles
                            contidas são de inteira responsabilidade de quem as
                            inseriu. A visita a qualquer desses sites é mera
                            liberalidade do usuário.
                            <br />• SOBRE A ARENA DE EXPOSIÇÃO E
                            COMPARTILHAEMNTO DE DADOS É de responsabilidade do
                            visitante aceitar ou não o compartilhamento dos
                            dados com os expositores ao entrar no local da
                            plataforma virtual denominada “arena de exposição”.{" "}
                          </p>
                        </div>
                      </div>
                      <div>
                        <li>
                          <span
                            id="seta-direta-7"
                            className="seta-direita"
                          ></span>{" "}
                          MODIFICAÇÃO DO CONTEÚDO PUBLICADO
                        </li>
                        <div id="7-item">
                          <p>
                            {" "}
                            O Sebrae tem livre acesso para alterar ou atualizar
                            qualquer informação do Site, periodicamente ou
                            conforme a sua necessidade. Portanto, nenhum
                            conteúdo poderá ser considerado definitivo. As
                            informações constantes no Site serão sempre
                            associadas à conveniência do tempo em que forem
                            publicadas.
                          </p>
                        </div>
                      </div>
                      <div>
                        <li>
                          <span
                            id="seta-direta-8"
                            className="seta-direita"
                          ></span>{" "}
                          DA CITAÇÃO DE EMPRESAS EM SEUS CONTEÚDOS
                        </li>
                        <div id="8-item">
                          <p>
                            {" "}
                            Ao citar uma empresa em qualquer de seus conteúdos,
                            o Sebrae afirma que não está promovendo-a
                            comercialmente, bem como não restringe a escolha do
                            usuário em procurar e contratar empresas diferentes
                            da citada. O usuário é livre para tomar suas
                            decisões.{" "}
                          </p>
                        </div>
                      </div>
                      <div>
                        <li>
                          <span
                            id="seta-direta-10"
                            className="seta-direita"
                          ></span>{" "}
                          DA OUVIDORIA
                        </li>
                        <div id="10-item">
                          <p>
                            A Ouvidoria do Sebrae é um serviço de atendimento ao
                            cliente que recebe reclamações, denúncias, críticas,
                            elogios e sugestões para melhoria de serviços. A
                            Ouvidoria tem o objetivo de lidar com as exceções,
                            ou seja, questões que não tenham sido resolvidas
                            anteriormente pelos setores competentes para tal.
                            Ela atua estrategicamente, de forma personalizada e
                            na mediação de conflitos através do site{" "}
                            <a
                              href="https://www.sebrae.com.br/sites/PortalSebrae/ouvidoriasebrae"
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              www.sebrae.com.br/sites/PortalSebrae/ouvidoriasebrae
                            </a>{" "}
                            . É sempre solicitado ao cliente que se identifique
                            para registrar uma ocorrência na Ouvidoria, mesmo na
                            área não logada/aberta do Portal, exceto quando se
                            tratar de denúncia, que poderá ser registrada
                            anonimamente.{" "}
                          </p>
                        </div>
                      </div>
                      <div>
                        <li>
                          <span
                            id="seta-direta-11"
                            className="seta-direita"
                          ></span>{" "}
                          POLÍTICA DE USO E DE PRIVACIDADE
                        </li>
                        <div id="11-item">
                          <p>
                            A utilização da plataforma do Feira do Empreendedor
                            por qualquer usuário implica na imediata aceitação
                            de todos os termos e condições deste Termo de Uso,
                            bem como da Política de Privacidade, restando claro
                            que não coletamos outros dados de seus usuários,
                            além dos fornecidos voluntariamente. Todos os
                            usuários aderem expressamente ao presente termo de
                            uso e respectiva Política de Privacidade.
                          </p>
                        </div>
                      </div>
                      <div>
                        <li>
                          <span
                            id="seta-direta-12"
                            className="seta-direita"
                          ></span>{" "}
                          DA ALTERAÇÃO DO PRESENTE TERMO DE USO
                        </li>
                        <div id="12-item">
                          <p>
                            O Sebrae poderá alterar o Termo de Uso presente, sem
                            prévio aviso e quando julgar necessário.{" "}
                          </p>
                        </div>
                      </div>
                      <div>
                        <li>
                          <span
                            id="seta-direta-13"
                            className="seta-direita"
                          ></span>{" "}
                          DO PRAZO PARA RESPOSTA
                        </li>
                        <div id="13-item">
                          <p>
                            1O atendimento assíncrono (via e-mail) deverá ser
                            respondido em até 2 (dois) dias úteis. A Ouvidoria
                            tem o prazo de até 10 (dez) dias úteis para
                            responder ao cliente.{" "}
                          </p>
                        </div>
                      </div>
                      <div>
                        <li>
                          <span
                            id="seta-direta-12"
                            className="seta-direita"
                          ></span>{" "}
                          FORO COMPETENTE
                        </li>
                        <div id="12-item">
                          <p>
                            Os Termos e Condições de Uso aqui descritos são
                            interpretados segundo a legislação brasileira. Fica
                            eleito o Foro da Comarca de Brasília, no Distrito
                            Federal, para dirimir qualquer litígio, questão ou
                            dúvida superveniente, com expressa renúncia de
                            qualquer outro, por mais privilegiado que seja.
                          </p>
                        </div>
                      </div>
                    </ul>
                    <p>
                      Baixe aqui a{" "}
                      <a href="https://minio-cpe.sebrae.com.br/documento/termo-inscricaofeiradoempreendedor-sp.pdf">
                        Política de Privacidade
                      </a>
                    </p>
                  </div>

                  <button
                    className="accept"
                    onClick={() => {
                      this.props.onConfirm();
                    }}
                  >
                    Concordo
                  </button>
                  <button
                    className="dismiss"
                    onClick={() => {
                      this.props.close();
                    }}
                  >
                    Discordo
                  </button>
                </div>
              </div>
            </>
          );
        }}
      </CSSTransition>
    );
  }
}

ModalTerms.defaultProps = {
  isOpenTerms: false,
  animationSpeed: 300,
  classNames: {
    modalVideoEffect: "modal-video-effect",
    modalVideo: "modal-video",
    modalVideoClose: "modal-video-close",
    modalVideoBody: "modal-video-body",
    modalVideoInner: "modal-video-inner",
    modalVideoIframeWrap: "modal-video-movie-wrap",
    modalVideoCloseBtn: "modal-video-close-btn",
  },
};
