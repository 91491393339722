/* eslint-disable array-callback-return */
/* eslint-disable no-useless-constructor */
import { Formik, Field } from "formik";
import React, { Fragment } from "react";
import * as yup from "yup";

class AuthorizationList extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      data: [],
    };
  }

  schema = yup.object().shape({
    partners: yup
      .array()
      .min(1, "Selecione pelo menos uma opção.")
      .required("Selecione pelo menos uma opção.")
      .nullable(),
  });

  checkInput(value) {
    return this.props.eventList.find(
      (e) =>
        e._id === value &&
        e.activeOptinSponsor &&
        this.props.event.find((el) => value === el)
    );
  }

  render() {
    return (
      <>
        <h4>Receba ofertas exclusivas de parceiros</h4>

        <label>
          Autorizo o compartilhamento com os patrocinadores e parceiros dos meus
          dados pessoais cadastrais, bem como os dados de histórico da minha
          navegação nos espaços promovidos pelo patrocinador a fim de permitir
          que os patrocinadores entrem em contato para ofertar produtos e
          serviços personalizados.
        </label>

        {/* <label>
          Clique aqui(Link para lista de patrocinadores) para conhecer os
          patrocinadores dos eventos
        </label> */}
        <Formik
          onSubmit={(values, actions) => {
            this.props.sendForm(values.partners);
          }}
          initialValues={{
            partners: [],
          }}
        >
          {({
            handleSubmit,
            handleChange,
            handleBlur,
            setFieldTouched,
            setFieldValue,
            values,
            touched,
            isValid,
            isValidating,
            errors,
          }) => (
            <form id="authorizationForm" onSubmit={handleSubmit}>
              <div className="row">
                <div className="col-lg-12 col-md-12">
                  <div className="well">
                    <div>
                      {this?.props?.event &&
                        this?.props?.eventList
                          .filter((e) => this.checkInput(e._id))
                          .map((e) => {
                            return (
                              <Fragment key={e._id}>
                                <label>
                                  <b>{e.name}</b> &#160;
                                  <Field
                                    type="checkbox"
                                    name="partners"
                                    value={e._id}
                                  />
                                  &#160; Autorizo
                                  {e.sponsors_link && (
                                    <a
                                      className="ml-2"
                                      href={e.sponsors_link}
                                      target="_blank"
                                      rel="noopener noreferrer"
                                    >
                                      lista de patrocinadores
                                    </a>
                                  )}
                                </label>
                                <br />
                              </Fragment>
                            );
                          })}
                    </div>
                  </div>
                  <div className="col-lg-12 col-md-12">
                    <span className="invalid-form">{errors?.event}</span>
                  </div>
                </div>

                <div className="col-lg-12 col-md-12 text-right">
                  <button type="submit" className="btn btn-primary">
                    Próximo
                  </button>
                </div>
              </div>
            </form>
          )}
        </Formik>
      </>
    );
  }
}

export default AuthorizationList;
