import React from "react";
import Navigation from "../../components/Navigation/Navigation";
import MainBanner from "../HomeDefault/MainBanner";
import About from "../HomeDefault/About";
import GoTop from "../Shared/GoTop";
import Footer from "../Common/Footer";
import EventSchedules from "../HomeDefault/EventSchedules";
import lax from "lax.js";
import Partner from "../Common/Partner";
import Contact from "../Common/Contact";
import Register from "../Common/Register";
import api from "../../services/api";

class HomeDefault extends React.Component {
  state = {
    global: null,
  };

  constructor(props) {
    super(props);
    lax.setup();

    document.addEventListener(
      "scroll",
      function (x) {
        lax.update(window.scrollY);
      },
      false
    );

    lax.update(window.scrollY);
  }

  useEffect(){}

  componentDidMount() {
    const getGlobal = async () => {
      const responseGlobal = await api.get("global", {
        headers: {
          Authorization:
            "Basic " +
            window.btoa(
              process.env.REACT_APP_APIKEY + ":" + process.env.REACT_APP_PUBLIC_SECRETKEY
            ),
        },
      });     
      let link =
        document.querySelector("link[rel*='icon']") ||
        document.createElement("link");
      link.type = "image/x-icon";
      link.rel = "shortcut icon";
      link.href = responseGlobal.data.favicon;
      document.getElementsByTagName("head")[0].appendChild(link);
      document.title = responseGlobal.data.title;

      if (
        responseGlobal.data &&
        responseGlobal.data.metaDescription &&
        responseGlobal.data.metaKeywords
      ) {
        let meta1 = document.createElement("meta");
        let meta2 = document.createElement("meta");
        meta1.setAttribute("name", "description");
        meta2.setAttribute("name", "keywords");
        meta1.content = responseGlobal.data.metaDescription;
        meta2.content = responseGlobal.data.metaKeywords;
        document.getElementsByTagName("head")[0].appendChild(meta1);
        document.getElementsByTagName("head")[0].appendChild(meta2);
      }

      if(responseGlobal.data && responseGlobal.data.robots) {
        let meta3 = document.createElement("meta");
        meta3.setAttribute("name", "robots");
        meta3.content = responseGlobal.data.tagsRobots;
        document.getElementsByTagName("head")[0].appendChild(meta3);
      }

      const colors = Object.entries(responseGlobal.data.colors);

      const html = document.querySelector("html");
      const transformKey = (key) =>
        "--" + key.replace(/([A-Z])/g, "-$1").toLowerCase();

      colors.forEach((item) => {
        html.style.setProperty(transformKey(item[0]), "#" + item[1]);
      });

      this.setState({ global: responseGlobal.data });
      // console.log(responseGlobal.data.hotsiteSectionsVisibility);
      if(responseGlobal.data && responseGlobal.data.shapes) {
        const removeElements = (elms) => elms.forEach(el => el.remove());
        removeElements( document.querySelectorAll(".shape1"));
        removeElements( document.querySelectorAll(".shape2"));
        removeElements( document.querySelectorAll(".shape3"));
        removeElements( document.querySelectorAll(".shape4"));
        removeElements( document.querySelectorAll(".shape-img"));
      }
    };

    getGlobal();
  }

  render() {
    return (
      <React.Fragment>
        {this.state.global?.hotsiteSectionsVisibility.menu && <Navigation global={this.state.global.image} />}

        {this.state.global?.hotsiteSectionsVisibility.home && <MainBanner global={this.state.global} />}
        
       
        {this.state.global?.hotsiteSectionsVisibility.about && (
          <About />
        )}

        {this.state.global?.hotsiteSectionsVisibility.schedule && (
          <EventSchedules />
        )}
       

        {this.state.global?.hotsiteSectionsVisibility.registration && (
          <Register
            global={{
              register: this.state.global.register,
              registerForm: this.state.global.hotsiteSectionsVisibility.registration,
              lgpd: this.state.global.lgpd,
              lgpdText: this.state.global.lgpdText,
              lgpdFile: this.state.global.lgpdFile,
              txtInscricao: this.state.global.txtInscricao,
              interestList: this.state.global.interestList,
              nameInterestList: this.state.global.nameInterestList,
            }}
          />
        )}

        {this.state.global?.hotsiteSectionsVisibility.sponsors && (
          <Partner />
        )}

        {this.state.global?.hotsiteSectionsVisibility.contact && (
          <Contact
            global={{
              url: this.state.global.url,
              emailSupport: this.state.global.emailSupport,
            }}
            social={{
              twitter: this.state.global.twitter,
              facebook: this.state.global.facebook,
              instagram: this.state.global.instagram,
              linkedin: this.state.global.linkedin,
              youtube: this.state.global.youtube,
            }}
          />
        )}
        
        {/* {console.log(this.state.global.hotsiteSectionsVisibility)} */}
       
        {this.state.global?.hotsiteSectionsVisibility.footer && (          
          <Footer
            global={{
              url: this.state.global.url,
              dateEvent: this.state.global.dateEvent,
            }}
            social={{
              twitter: this.state.global.twitter,
              facebook: this.state.global.facebook,
              instagram: this.state.global.instagram,
              linkedin: this.state.global.linkedin,
              youtube: this.state.global.youtube,
            }}
          />
        )}

        <GoTop scrollStepInPx="50" delayInMs="16.66" />
      </React.Fragment>
    );
  }
}

export default HomeDefault;
