import axios from "axios";

const apiGateway = axios.create({
  baseURL: `${process.env.REACT_APP_API_GATEWAY}`,
});

apiGateway.interceptors.request.use(async (config) => {
  const token = localStorage.getItem("Auth_Token");
  config.headers = {
    "request-amei": true,
    Authorization: "Bearer " + token,
  };

  return config;
});
export default apiGateway;
