import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import api from "../../services/api";
import {
  recoveryPassword,
  updatePassword,
} from "../../services/recovery-password";

export default function RecoverPassword() {
  const { id, event } = useParams();
  const [code, setCode] = useState(null);
  const [newPass, setNewPass] = useState(null);
  const [confirmNewPass, setConfirmNewPass] = useState(null);
  const [success, setSuccess] = useState(false);

  useEffect(() => {
    async function firstLoad() {
      console.log(id);
      console.log(event.split(','));
      try {
        await recoveryPassword(id);
      } catch (error) {
        console.log("erro ao solicitar código para recuperar senha", error);
      }
    }

    firstLoad();
  }, [id, event]);

  const handleNewPassword = async (e) => {
    e.preventDefault();

    try {
      if (newPass !== confirmNewPass) {
        alert("As senhas devem ser iguais");
      } else {
        await updatePassword({
          id,
          code,
          newPass,
        });

        setSuccess(true);

        await api.put("player/" + id, {extra: {events: event.split(',')}}, {
          headers: {
            Authorization:
              "Basic " +
              window.btoa(
                process.env.REACT_APP_APIKEY + ":" + process.env.REACT_APP_PUBLIC_SECRETKEY
              ),
          },
        });
      }
    } catch (error) {
      console.log("erro ao cadastrar nova senha", error);
    }
  };

  return (
    <section className="signup-area">
      <div className="d-table">
        <div className="d-table-cell">
          <div className="signup-form">
            <h3>Recuperar senha</h3>

            {!success && (
              <p>
                Use o código que recebeu por email para recuperar sua senha:
              </p>
            )}

            <form>
              {!success && (
                <>
                  <div className="form-group">
                    <label>Código</label>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Código recebido no email"
                      value={code}
                      onChange={(e) => setCode(e.target.value)}
                    />
                  </div>
                  <div className="form-group">
                    <label>Nova senha</label>
                    <input
                      type="password"
                      className="form-control"
                      placeholder="Nova senha"
                      value={newPass}
                      onChange={(e) => setNewPass(e.target.value)}
                    />
                  </div>
                  <div className="form-group">
                    <label>Confirmar nova senha</label>
                    <input
                      type="password"
                      className="form-control"
                      placeholder="Nova senha"
                      value={confirmNewPass}
                      onChange={(e) => {
                        setConfirmNewPass(e.target.value);
                      }}
                    />
                  </div>
                </>
              )}

              {success ? (
                <>
                  <p><h4>Inscrição realizada!</h4></p>
                  <p>Sua senha foi alterada com sucesso e a sua inscrição no evento também está concluída.</p>
                  <p>A palestra acontecerá na Arena do Conhecimento, mas não deixe de visitar o Atendimento Sebrae e conferir os conteúdos exclusivos disponíveis para você.</p>
                  <p><a href="https://sebraeexperience.com.br"><b>Clique aqui</b> para acessar o evento.</a></p>
                </>
              ) : (
                <button onClick={handleNewPassword} className="btn btn-primary">
                  Enviar
                </button>
              )}
            </form>
          </div>
        </div>
      </div>
    </section>
  );
}
