import { getByIdPessoaJuridica } from "./api-sebrae-pj";
import apiGateway from "./api-sebrae-gateway";

export const getByCpf = async (cpf) => {
  try {
    const enterprises = [];
    const { data } = await apiGateway.get(`/rae/pf/cpf/${cpf}`);
    if (data?.PessoasJuridicasVinculadas) {
      const cnpjList = data?.PessoasJuridicasVinculadas;

      for (const cnpj of cnpjList) {
        const data = await getByIdPessoaJuridica(cnpj.IdPessoaJuridica);
        if (data) {
          enterprises.push(data);
        }
      }
    }

    return { data, enterprises };
  } catch (error) {
    console.log("Erro ao tentar consultar pessoa fisica", error);
  }
};

export const postByCpf = async (payload) => {
  try {
    const { data } = await apiGateway.post(`/rae/pf`, {
      item: payload,
    });

    return data;
  } catch (error) {
    console.log("Erro ao tentar cadastrar pessoa fisica", error);
  }
};

export default { getByCpf, postByCpf };
