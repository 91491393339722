import { Formik } from "formik";
import React from "react";
import * as yup from "yup";

class FromForeign extends React.Component {
  state = {
    collapsed: true,
    isOpen: false,
  };

  // eslint-disable-next-line
  constructor(props) {
    super(props);
  }

  schema = yup.object().shape({
    name: yup
      .string()
      .required(`Nome é obrigatório!`)
      .test(
        "is-valid",
        `Nome inválido, por favor digite seu nome completo`,
        (value) => {
          if (value !== null && value !== undefined) {
            const regExp = /(\w+)/g;
            const str = value.match(regExp)?.length;

            if (str && str >= 2) {
              return true;
            }
          }

          return false;
        }
      ),
    email: yup
      .string()
      .email(`E-mail inválido!`)
      .required(`E-mail é obrigatório!`),
    confirmEmail: yup
      .string()
      .required(`E-mail é obrigatório!`)
      .oneOf([yup.ref("email"), null], `E-mails precisam ser iguais!`),
    password: yup.string().required(`Senha é obrigatório!`),
    confirmPassword: yup
      .string()
      .required(`Senha é obrigatório!`)
      .oneOf([yup.ref("password"), null], `Senhas precisam ser iguais!`),
    extra: yup.object().shape({
      city: yup.string().required(`Cidade é obrigatório`),
      country: yup.string().required(`Pais é obrigatório`),
    }),
  });

  render() {
    return (
      <Formik
        validationSchema={this.schema}
        onSubmit={(values, actions) => {
          this.props.sendForm(values);
        }}
        initialValues={{
          _id: "",
          name: "",
          email: "",
          confirmEmail: "",
          password: "",
          confirmPassword: "",
          extra: {
            infosSebraeSP: false,
            data_nascimento: undefined,
            cpf: undefined,
            phone: undefined,
            birthDay: undefined,
            agree: undefined,
            bairro: undefined,
            cep: undefined,
            cidade: undefined,
            logradouro: undefined,
            tipoLogradouro: undefined,
            uf: undefined,
            pais: undefined,
          },
        }}
      >
        {({
          handleSubmit,
          handleChange,
          handleBlur,
          setFieldTouched,
          setFieldValue,
          values,
          touched,
          isValid,
          isValidating,
          errors,
        }) => (
          <form id="contactForm" onSubmit={handleSubmit}>
            <div className="row">
              <div className="col-lg-12 col-md-12">
                <label htmlFor="">Nome</label>
                <input
                  name="name"
                  type="text"
                  className={`form-control ${
                    errors.name ? "invalid-form" : ""
                  }`}
                  onChange={handleChange}
                  value={this.state?.formFields?.name}
                />
                <span className="invalid-form">{errors.name}</span>
              </div>

              <div className="col-lg-12 col-md-12">
                <label htmlFor="">Pais</label>
                <input
                  name="extra.country"
                  type="text"
                  className={`form-control ${
                    errors?.extra?.country ? "invalid-form" : ""
                  }`}
                  onChange={handleChange}
                />
                <span className="invalid-form">{errors?.extra?.country}</span>
              </div>
              <div className="col-lg-12 col-md-12">
                <label htmlFor="">Cidade</label>
                <input
                  name="extra.city"
                  type="text"
                  className={`form-control ${
                    errors?.extra?.city ? "invalid-form" : ""
                  }`}
                  onChange={handleChange}
                />
                <span className="invalid-form">{errors?.extra?.city}</span>
              </div>

              <div className="col-lg-12 col-md-12">
                <label htmlFor="">E-mail</label>
                <input
                  name="email"
                  type="email"
                  className={`form-control ${
                    errors.email ? "invalid-form" : ""
                  }`}
                  onChange={handleChange}
                  value={this.state?.formFields?.email}
                />
                <span className="invalid-form">{errors.email}</span>
              </div>

              <div className="col-lg-12 col-md-12">
                <label htmlFor="">Confirmação de e-mail</label>
                <input
                  name="confirmEmail"
                  type="email"
                  onChange={handleChange}
                  value={this.state?.formFields?.email}
                  className={`form-control ${
                    errors.confirmEmail ? "invalid-form" : ""
                  }`}
                />
                <span className="invalid-form">{errors.confirmEmail}</span>
              </div>
              <div className="col-lg-12 col-md-12">
                <label htmlFor="">Senha</label>
                <input
                  name="password"
                  type="password"
                  className={`form-control ${
                    errors.password ? "invalid-form" : ""
                  }`}
                  onChange={handleChange}
                />
                <span className="invalid-form">{errors.password}</span>
              </div>
              <div className="col-lg-12 col-md-12">
                <label htmlFor="">Confirmação senha</label>
                <input
                  name="confirmPassword"
                  type="password"
                  onChange={handleChange}
                  className={`form-control ${
                    errors.confirmPassword ? "invalid-form" : ""
                  }`}
                />
                <span className="invalid-form">{errors.confirmPassword}</span>
              </div>
              <div className="col-lg-12 col-md-12">
                <label htmlFor="">
                  <input
                    name="extra.infosSebraeSP"
                    type="checkbox"
                    onChange={handleChange}
                  />
                  &#160; Aceito receber informações do SEBRAE
                </label>
                <br />
              </div>
              <div className="col-lg-12 col-md-12 text-right">
                {!this.props.terms && (
                  <button type="submit" className="btn btn-primary">
                    Próximo
                  </button>
                )}

                {this.props.terms && (
                  <button
                    type="button"
                    className="btn btn-primary"
                    onClick={() => this.props.send()}
                  >
                    Enviar
                  </button>
                )}
              </div>
            </div>
          </form>
        )}
      </Formik>
    );
  }
}

export default FromForeign;
