import apiGateway from "./api-sebrae-gateway";

export const getCnae = async () => {
  try {
    const { data } = await apiGateway.get(`/rae/pj/cnae`);

    return data;
  } catch (error) {
    console.log("Erro ao tentar consultar pessoa fisica", error);
  }
};

export default getCnae;
